import { SortDirections } from "@/utils/GeneralConstants";
import jp from "jsonpath";

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Gets a filename, removes unsafe characters and sets file extension to lower .
 *
 * Unsafe characters in this case are: %
 *
 * @param string
 * @returns {string|*}
 */
export function removeUnsafeCharacters(string) {
  if (string && typeof string === "string") {
    //Check if file extension is present
    const indexOfSuffix = string.lastIndexOf(".");

    if (indexOfSuffix >= 0) {
      const fileName = string.slice(0, indexOfSuffix);
      let suffix = string.slice(indexOfSuffix + 1);

      //Set file extension to lower case
      suffix = suffix.toLowerCase();

      string = fileName + "." + suffix;
    }

    return string.replace(/%/g, "");
  } else {
    return string;
  }
}

/**
 * The function allows for sorting by one property or by two.
 * If provided, the secondSortingProperty will be analyzed after the first one
 * and order will be affected accordingly
 * @param sortingProperty
 * @param secondSortingProperty
 * @param direction
 * @returns {function(...[*]=)}
 */
export function createCustomSortingFunction(
  sortingProperty,
  secondSortingProperty = undefined,
  direction = null
) {
  if (
    sortingProperty &&
    "string" === typeof sortingProperty &&
    sortingProperty.length > 0
  ) {
    // Assign values
    let sortingProp = sortingProperty;
    let secondSortingProp = secondSortingProperty;

    // Create ascending sorting function
    if (direction === SortDirections.ASCENDING) {
      return function (a, b) {
        if (sortingProperty.includes("content")) {
          sortingProp = sortingProperty.slice(8);
          a = a.content;
          b = b.content;
        }
        if (a[sortingProp] > b[sortingProp] || !b[sortingProp]) {
          return 1;
        }
        if (a[sortingProp] < b[sortingProp] || !a[sortingProp]) {
          return -1;
        }
        // If there's a second sorting property – include it
        if (secondSortingProp && a[secondSortingProp] > b[secondSortingProp]) {
          return 1;
        }
        if (secondSortingProp && a[secondSortingProp] < b[secondSortingProp]) {
          return -1;
        }
        return 0;
      };
      // Create descending sorting function
    } else if (direction === SortDirections.DESCENDING) {
      return function (a, b) {
        if (sortingProperty.includes("content")) {
          sortingProp = sortingProperty.slice(8);
          a = a.content;
          b = b.content;
        }

        if (a[sortingProp] > b[sortingProp] || !a[sortingProp]) {
          return -1;
        }
        if (a[sortingProp] < b[sortingProp] || !b[sortingProp]) {
          return 1;
        }
        // If there's a second sorting property – include it
        if (secondSortingProp && a[secondSortingProp] > b[secondSortingProp]) {
          return -1;
        }
        if (secondSortingProp && a[secondSortingProp] < b[secondSortingProp]) {
          return 1;
        }
        return 0;
      };
    }
  } else {
    throw new Error("Cannot generate sorting function without input");
  }
}

export function sortByScoreAndLabel(a, b) {
  if (
    a.hasOwnProperty("individualScore") &&
    b.hasOwnProperty("individualScore") &&
    a.hasOwnProperty("label") &&
    b.hasOwnProperty("label")
  ) {
    return createCustomSortingFunction(
      "individualScore",
      "label",
      SortDirections.ASCENDING
    )(a, b);
  } else {
    return null;
  }
}

/**
 * Checks if certain user has a certain role
 * @param user
 * @param roleId
 * @returns {*}
 * @constructor
 */
export const checkIfUserHasThisRole = function (user, roleId) {
  const userRoles = user.relationships.roles;
  return userRoles.some((role) => role.role.identification === roleId);
};

/**
 * Returen a date with format dd-MM-yyyy
 * @param value Date
 * @returns {string}
 */
export function displayDate(value) {
  const date = new Date(value);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  day = day < 10 ? `0${day}` : day;
  month = month < 10 ? `0${month}` : month;

  return `${day}-${month}-${year}`;
}

/**
 * Returns a number formatted to a currency. Euro by default
 * @param value
 * @param locale
 * @param currency
 * @returns {string}
 */
export function displayCurrency(value, locale = "nl-NL", currency = "EUR") {
  const number = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);

  // Replace ,00 with ,-
  return number.replace(/\,00$/, ",-");
}

/**
 * Determines the names of possible routes to navigate to based on the current route name.
 * @param routeName
 * @returns {{requestRouteName: undefined, tabName: undefined, sectionRouteName: undefined, actionRouteName: undefined, candidateRouteName: undefined, actionInstanceRouteName: undefined, bulkActionRouteName: undefined}}
 */
const ACTIONS = "actions";
const CANDIDATES = "candidates";

const CandidatesTabRoutes = [
  "Aanvraag",
  "AanvraagTab",
  "LBAVacature",
  "LBAVacatureTab",
  "RecruitmentAanvraag",
  "RecruitmentAanvraagTab",
  "Kandidaat",
  "KandidaatActies",
  "Bulkactie",
  "ActiesAanvraagKandidaat",
  "ActiesAanvraagKandidaatActies",
  "ActiesAanvraagActie",
  "ActiesAanvraagBulkactie",
  "RecruitmentKandidaat",
  "RecruitmentKandidaatActies",
  "LBAVacatureKandidaat",
  "LBAVacatureKandidaatActies",
  "RecruitmentAanvraagBulkactie",
  "LBAVacatureBulkactie",
];

const ActionsTabRoutes = [
  "KandidaatActie",
  "Actie",
  "ActiesAanvraagKandidaatActie",
  "RecruitmentKandidaatActie",
  "RecruitmentAanvraagActie",
  "LBAVacatureKandidaatActie",
  "LBAVacatureActie",
  "LosseVacatureActie",
];

export function getRouteName(routeName) {
  let actionRouteName,
    candidateRouteName,
    bulkActionRouteName,
    actionInstanceRouteName,
    requestRouteName,
    sectionRouteName = null;

  switch (routeName) {
    case "Aanvraag":
    case "AanvraagTab":
      actionRouteName = "Actie";
      candidateRouteName = "Kandidaat";
      bulkActionRouteName = "Bulkactie";
      break;
    case "LBAVacature":
    case "LBAVacatureTab":
      actionRouteName = "LBAVacatureActie";
      candidateRouteName = "LBAVacatureKandidaat";
      bulkActionRouteName = "LBAVacatureBulkactie";
      break;
    case "RecruitmentAanvraag":
    case "RecruitmentAanvraagTab":
      actionRouteName = "RecruitmentAanvraagActie";
      candidateRouteName = "RecruitmentKandidaat";
      bulkActionRouteName = "RecruitmentAanvraagBulkactie";
      break;
    case "ActiesAanvraag":
    case "ActiesAanvraagTab":
      actionRouteName = "ActiesAanvraagActie";
      candidateRouteName = "ActiesAanvraagKandidaat";
      bulkActionRouteName = "ActiesAanvraagBulkactie";
      break;
    case "LosseVacature":
    case "LosseVacatureTab":
      actionRouteName = "LosseVacatureActie";
      break;
    case "Sollicitaties":
    case "SollicitatieDetails":
      candidateRouteName = "SollicitatieDetails";
      actionInstanceRouteName = "Sollicitaties";
      actionRouteName = "SollicitatieDetailsActie";
      break;
    case "SollicitatieDetailsActie":
      actionInstanceRouteName = "SollicitatieDetails";
      break;
    case "KandidaatActie":
      actionInstanceRouteName = "KandidaatActies";
      break;
    case "Kandidaat":
    case "KandidaatActies":
    case "Bulkactie":
    case "Actie":
      actionInstanceRouteName = "AanvraagTab";
      actionRouteName = "KandidaatActie";
      break;
    case "ActiesAanvraagKandidaatActie":
      actionInstanceRouteName = "ActiesAanvraagKandidaatActies";
      break;
    case "ActiesAanvraagKandidaat":
    case "ActiesAanvraagKandidaatActies":
    case "ActiesAanvraagActie":
    case "ActiesAanvraagBulkactie":
      actionInstanceRouteName = "ActiesAanvraagTab";
      actionRouteName = "ActiesAanvraagKandidaatActie";
      break;
    case "ProfielActie":
      actionInstanceRouteName = "Profiel";
      break;
    case "RecruitmentKandidaatActie":
      actionInstanceRouteName = "RecruitmentKandidaatActies";
      break;
    case "RecruitmentKandidaat":
    case "RecruitmentKandidaatActies":
    case "RecruitmentAanvraagActie":
      actionInstanceRouteName = "RecruitmentAanvraagTab";
      actionRouteName = "RecruitmentKandidaatActie";
      break;
    case "LBAVacatureKandidaatActie":
      actionInstanceRouteName = "LBAVacatureKandidaatActies";
      break;
    case "LBAVacatureKandidaat":
    case "LBAVacatureKandidaatActies":
    case "LBAVacatureActie":
      actionInstanceRouteName = "LBAVacatureTab";
      actionRouteName = "LBAVacatureKandidaatActie";
      break;
    case "LBAProfielActie":
      actionInstanceRouteName = "LBAProfiel";
      break;
    case "LosseVacatureActie":
      actionInstanceRouteName = "LosseVacatureTab";
      break;
    case "NieuweAanvraag":
      requestRouteName = "Aanvraag";
      sectionRouteName = "WervingSelectie";
      break;
    case "NieuweLosseVacature":
      requestRouteName = "LosseVacature";
      sectionRouteName = "Recruitment";
      break;
    case "RecruitmentAanvraagBulkactie":
      actionInstanceRouteName = "RecruitmentAanvraag";
      break;
    case "LBAVacatureBulkactie":
      actionInstanceRouteName = "LBAVacature";
      break;
    default:
      // Do nothing
      break;
  }

  const tabName = setTabName(routeName);

  return {
    actionRouteName: actionRouteName,
    candidateRouteName: candidateRouteName,
    bulkActionRouteName: bulkActionRouteName,
    requestRouteName: requestRouteName,
    tabName: tabName,
    actionInstanceRouteName: actionInstanceRouteName,
    sectionRouteName: sectionRouteName,
  };
}

export function setTabName(routeName) {
  if (CandidatesTabRoutes.includes(routeName)) {
    return CANDIDATES;
  } else if (ActionsTabRoutes.includes(routeName)) {
    return ACTIONS;
  }
  return null;
}

export function isFilenameForImage(filename) {
  let fileExtension = filename.slice(filename.lastIndexOf(".") + 1);
  fileExtension = fileExtension.toLowerCase();
  const extensions = [
    "apng",
    "avif",
    "gif",
    "jpg",
    "jpeg",
    "jfif",
    "pjpeg",
    "pjp",
    "png",
    "svg",
    "webp",
  ];
  return extensions.includes(fileExtension);
}

export function getGeneralColumnName(value) {
  const endIndex = value.indexOf(".") + 1;
  value = value.slice(endIndex, value.length);
  let name = value;

  switch (value) {
    case "identification":
      name = "Identificatie";
      break;
    case "id":
      name = "ID";
      break;
    case "name":
      name = "Naam";
      break;
    case "timestamp_created":
      name = "Datum aangemaakt";
      break;
    case "timestamp_any_update":
      name = "Datum laatst gewijzigd";
      break;
    case "Welk type capaciteit vraag je aan?":
      name = "Type capaciteit";
      break;
    default:
      name = name[0].toUpperCase() + name.slice(1);
  }
  return name;
}

export function getColumnWidth(label) {
  if (label && label.length) {
    const width = 150 + label.length * 6;
    return width > 270 ? "270px" : width + "px";
  }
  return null;
}

export function valueFromPath(data, path) {
  //Make sure the path is formatted as a JSONPath
  //Add $. of it's not present
  const regex = /^\$/g;
  path = path.match(regex) || path === "" ? path : `$.${path}`;

  const value = jp.query(data, path);

  return value[0]; //jsonpath will return the single value as an array, so return value[0]
}
