import Vue from "vue";

const state = {
  selectedExample: null,
};

const getters = {
  selectedExample: (state) => state.selectedExample,
};

const actions = {
  setSelectedExample: ({ commit }, exampleText) => {
    commit("SET_SELECTED_EXAMPLE", exampleText);
  },

  clearSelectedExample: ({ dispatch }) => {
    dispatch("setSelectedExample", null);
  },
};

const mutations = {
  SET_SELECTED_EXAMPLE: (state, payload) =>
    Vue.set(state, "selectedExample", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
