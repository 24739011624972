/**
 * Services to create and manipulate flows.
 *
 * @file   This files defines the FlowServices.
 * @author Omnimap
 * @since  2021.03.29
 * @version 1
 */

import axios from "axios";
import authentication from "@/authentication";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";

// Base resource
const FLOW = "/flow";
const START = "/start";
const FINISH = "/finish";
const VALIDATE = "/validate";
const INSTANCES = "/instances";
const GENERIC = "/generic";
const STAGES = "/stages";
const ACTIVITY = "/activities";
const DEFINITION = "/definition";
const MONITORING = "/monitoring";
const ACTIONS = "/actions";
const INPUT_DATA = "/inputdata";

// Mapping data resources
const MAP_INPUT = "/mapinput";

export class InstanceFlowServices {
  /**
   * Construct axios
   * @param organisationId: The unique identification of the organisation
   */
  constructor(organisationId) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisationId) {
      this.setOrganisation(organisationId);
    }
  }

  /**
   * Set the organisation identification
   * @param organisationId: The unique identification of the organisation
   */
  setOrganisation(organisationId) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisationId;
  }

  async getInstanceMonitoring(instanceId) {
    return this.OMNIMAP.get(
      GENERIC + INSTANCES + "/" + instanceId + MONITORING,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get instance flow based on the instance identification.
   * @url {base}/instances/{indentification}/flow
   * @param instanceId: The unique identification of the instance
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getInstanceFlow(instanceId) {
    return this.OMNIMAP.get(INSTANCES + "/" + instanceId + FLOW, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async startInstanceFlow(instanceId, flowId) {
    return this.OMNIMAP.post(
      INSTANCES + "/" + instanceId + FLOW + "/" + flowId + START,
      {},
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  async finishInstanceFlow(instanceId, flowId) {
    return this.OMNIMAP.post(
      INSTANCES + "/" + instanceId + FLOW + "/" + flowId + FINISH,
      {},
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  async getStage(instanceId, stageId) {
    return this.OMNIMAP.get(
      INSTANCES + "/" + instanceId + FLOW + STAGES + "/" + stageId,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get the data definition of an activity.
   * @url {base}/activities/{activity}/definition
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getActivityDefinitionData(activityId) {
    return this.OMNIMAP.get(ACTIVITY + "/" + activityId + DEFINITION, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async setInputData(activityId, data) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + INPUT_DATA, data, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async mapInput(activityId) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + MAP_INPUT, null, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async getActivity(activityId) {
    return this.OMNIMAP.get(ACTIVITY + "/" + activityId, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async startActivity(activityId) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + START, null, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async finishActivity(activityId) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + FINISH, null, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async validateActivity(activityId) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + VALIDATE, null, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async getActions(instanceId) {
    return this.OMNIMAP.get(INSTANCES + "/" + instanceId + ACTIONS, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }
}
