export const LBATabs = Object.freeze({
  ALLE_PROFIELEN: "Alle profielen",
  VOORANGSKANDIDATEN: "Voorangskandidaten",
  MIJN_KANDIDATEN: "Mijn kandidaten",
});
export const LBAGeneralActions = Object.freeze({
  ACCORDEREN_PROFIEL: "8b11ec45-34f5-4e88-b4f7-01954dec4ed7",
  WIJZIGEN_LBA: "16d53621-eb6b-43d5-b08c-48e236a35c40",
  WIJZIGEN_CATEGORIE: "e916d426-fae0-4eaf-a509-1893e775c5ce",
  ACCORDEREN_EN_INDIENEN: "3120d0c9-1253-4487-8a1c-4ceea96991d8",
});
export const LBAVoorrangskandidatenActions = Object.freeze({
  ACCORDEREN_PROFIEL: "8b11ec45-34f5-4e88-b4f7-01954dec4ed7",
  WIJZIGEN_LBA: "16d53621-eb6b-43d5-b08c-48e236a35c40",
  WIJZIGEN_CATEGORIE: "e916d426-fae0-4eaf-a509-1893e775c5ce",
  DEACTIVEREN_PROFIEL: "42b56410-c263-4d0d-97cd-14ef5b53627a",
  ACCORDEREN_EN_INDIENEN: "3120d0c9-1253-4487-8a1c-4ceea96991d8",
});
export const Voorrangskandidaten = [
  "boventallig",
  "pre-boventallig",
  "re-integratie",
  "administratief geplaatst",
];

export const TypeKandidaat = Object.freeze({
  VOORANGSKANDIDAAT: "Voorangskandidaat",
});
