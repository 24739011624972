import axios from "axios";
import authentication from "../authentication";
import { ServiceException } from "../utils/ErrorHandler";
import {
  validateApiOkResponse,
  validateApiSearchResponse,
} from "../utils/ApiResponseValidator";
import { formData } from "../utils/GeneralConstants";

const INSTANCES_RESOURCE_START = "/generic/instances/";
const MEDIA_RESOURCE_END = "/media";
const MEDIA_BINARY_RESOURCE_START = "/generic/media/";
const MEDIA_BINARY_RESOURCE_END = "/content";
const MEDIA_RESOURCE_PATH_END = "/media/path";
const MEDIUM_RESOURCE_END = "/medium";
const MEDIUM_CONTENT = "/mediumcontent";
const PDF_FORMAT = "?format=pdf";
const MEDIA_V3 = "/generic/media/v3";

export class MediaServices {
  /**
   * Construct axios
   * @param organisationId
   */
  constructor(organisationId) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisationId) {
      this.setOrganisation(organisationId);
    }
  }

  setOrganisation(organisation) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisation;
  }

  async getMedia(instanceId, pageSize, pageNumber) {
    if (!pageSize || !pageNumber) {
      throw new ServiceException("geen geldige query om bestanden op te halen");
    }
    return this.OMNIMAP.get(
      INSTANCES_RESOURCE_START +
        instanceId +
        MEDIA_RESOURCE_END +
        `?page-size=${pageSize}&page-number=${pageNumber}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Get media associated with an instance
   *
   * @param instanceId
   * @param formData
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  async retrievePathForInstance(instanceId, formData) {
    return this.OMNIMAP.post(
      INSTANCES_RESOURCE_START + instanceId + MEDIA_RESOURCE_PATH_END,
      formData,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async retrieveMediaForInstance(instanceId) {
    return this.OMNIMAP.get(
      INSTANCES_RESOURCE_START + instanceId + MEDIA_RESOURCE_END,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async retrieveMediumMetadata(fileId) {
    return this.OMNIMAP.get(MEDIA_BINARY_RESOURCE_START + fileId, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Get file (as binary blob) by id
   *
   * @param fileId
   * @param pdf
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  async retrieveFile({ fileId, pdf = false }) {
    return this.OMNIMAP.get(
      MEDIA_BINARY_RESOURCE_START +
        fileId +
        MEDIA_BINARY_RESOURCE_END +
        (pdf ? PDF_FORMAT : ""),
      {
        responseType: "blob",
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => response.data)
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Create media binary associated with a file and a media metadata
   *
   * @param mediumId
   * @param formData
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  async createMediaBinary(mediumId, formData) {
    return this.OMNIMAP.post(
      MEDIA_BINARY_RESOURCE_START + mediumId + MEDIA_BINARY_RESOURCE_END,
      formData,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error);
      });
  }

  /**
   * Create media metadata associated with an instance and file metadata
   *
   * @param instanceId
   * @param metadata
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  async createMediaMetadata(instanceId, metadata) {
    return this.OMNIMAP.post(
      INSTANCES_RESOURCE_START + instanceId + MEDIUM_RESOURCE_END,
      metadata,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Update existing media binary associated with a file and a media metadata
   *
   * @param mediumId
   * @param formData
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  async updateMediaBinary(mediumId, formData) {
    return this.OMNIMAP.put(
      MEDIA_BINARY_RESOURCE_START + mediumId + MEDIA_BINARY_RESOURCE_END,
      formData,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Create media
   *
   * @returns {Promise<AxiosResponse<T> | never>}
   * @param data
   */
  async createMediaV3(data) {
    return this.OMNIMAP.post(MEDIA_V3, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Create media
   *
   * @returns {Promise<AxiosResponse<T> | never>}
   * @param data
   */
  async linkToConcept(data) {
    return this.OMNIMAP.post(MEDIA_V3, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Upload binary and metadata
   *
   * @param instanceId
   * @param formData
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  async uploadMediumContent(instanceId, formData) {
    return this.OMNIMAP.post(
      INSTANCES_RESOURCE_START + instanceId + MEDIUM_CONTENT,
      formData,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async deleteMedia(mediumId) {
    return this.OMNIMAP.delete(MEDIA_BINARY_RESOURCE_START + mediumId, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }
}
