import ElementLanguage from "element-ui/lib/locale/lang/nl";

const messages = {
  general: {
    welcome: "Welkom",
    select: "Selecteer",
    process: "Verwerken",
    scrollToLoad: "Scroll om meer te laden",
    clickToLoad: "Klik om meer te laden",
    noMore: "Geen resultaten meer",
    loaded: "geladen",
    ownOrganisations: "Eigen organisaties",
    genericError:
      "Sorry, er is een probleem opgetreden. Probeer het later opnieuw.",
    cancel: "Annuleren",
    sitemap: "Sitemap",
    home: "Home",
    homepage: "Homepage",
    vacancies: "Vacatures",
    carrier_education: "Loopbaan en opleiding",
    newCapacityRequest: "Aanvraag indienen",
    faq: "Veel gestelde vragen",
    contact: "Contact",
    privacy: "Privacy",
    cookies: "Cookies",
    accessibility: "Toegankelijkheid",
    exact: "Exact",
    submit: "Indienen",
    losseVacature: "Losse vacature",
    aanvraag: "Aanvraag",
  },
  login: {
    selectLoginMethod: "Selecteer een login methode",
    logout: "Uitloggen",
  },
  omniselect: {
    loading: "Laden...",
    noQuery: "Geen zoekopdracht beschikbaar",
    noPath: "Geen doelpad beschikbaar",
    noJsonpath: "Geen JSONPath beschikbaar",
    noUrl: "Geen URL beschikbaar",
    invalidPath: "Ongeldig doelpad",
    initError: "Fout bij het laden van de resultaten",
    searchError: "Fout bij ophalen van de selectie",
    invalidResult: "ONGELDIG RESULTAAT",
    path: "Pad",
    result: "Resultaat",
    noResult: "GEEN RESULTAAT",
    noResults: "Geen resultaat",
    validPaths: "Bekijk geldige paden",
    missingVariables:
      "Vul andere velden in het formulier in om dit selectieveld aan te vullen.",
    neededValues: "Benodigde waardes",
    invalidValues:
      "Een variabele heeft een ongeldige waarde gekregen en de gegevens kunnen niet worden opgehaald.",
    checkValues: "Controleer de waardes van",
  },
  organisationSelection: {
    selectOrganisation: "Selecteer organisatie",
  },
  actions: {
    download: "Downloaden",
  },
  columns: {
    general: "Algemeen",
    instance: "Dossier",
    title: "Titel",
    name: "Naam",
    documentName: "Documentnaam",
    fileName: "Bestandsnaam",
    instanceName: "Dossier naam",
    identification: "Identificatie",
    id: "ID",
    identifier: "ID",
    description: "Beschrijving",
    since: "Sinds",
    until: "Tot",
    role: "Relatie",
    operations: "Acties",
    path: "Pad",
    type: "Type",
    created: "Aangemaakt door",
    createdTime: "Aangemaakt",
    updated: "Gewijzigd door",
    updatedTime: "Gewijzigd",
    organisation: "Organisatie",
    workmethod: "Werkmethode",
    actions: "Acties",
    timestamp_created: "Datum aangemaakt",
    timestamp_any_update: "Datum laatst gewijzigd",
    status: "Status",
    relevance: "Relevantie",
    jobfunction: "Huidige functie",
    category: "Categorie",
    surname: "Achternaam",
    locations: "Locatie",
    rate: "Tarief",
    details: "Details",
    jobs: "Jobs",
    candidates: "Kandidaten",
    label_from: "Relatie",
    related_instance_identification: "Gerelateerd dossieridentificatie",
    related_instance_identifier: "Gerelateerd dossier ID",
    related_instance_name: "Gerelateerde dossiernaam",
    version: "Versie",
    retentionPeriod: "Bewaren tot",
    tags: "Tags",
  },
  terms: {
    lba: "Loopbaanadviseur",
    endReview: "Eindbeoordelingen",
    applicationProgress: "Sollicitatieverloop",
    schedule: "Planning",
    history: "Historie",
    actions: "Acties",
    profile: "Profiel",
    reviews: "Beoordelingen",
    communication: "Communicatie",
    nomination: "Voordracht",
    jobFunctions: "Jobfuncties",
    exampleText: "Voorbeeldtekst",
    selectionCommission: "Selectiecommissie",
    selectionCommissionMembers: "Selectiecommissieleden",
    publicationData: "Publicatiedata",
    mantelPartijen: "Mantelpartijen",
    recruitmentArchive: "Wervingsarchief",
    beoordelingen: "Beoordelingen",
  },
};

const omnischemaMessages = {
  // OmniSchema
  schema: {
    //General
    select: "Selecteer",
    isRequired: "Veld is verplicht",
    open: "Open",
    createMapping: "Creëer mapping",
    checkAll: "Vink alle aan",
    identification: "identificatie",
    isExported: "Data wordt geëxporteerd",
    parentExported: "Geëxporteerd door parent",
    inline: "Inline",

    //JSONArray
    more: "meer",
    loadMore: "Laad meer",
    addItem: "Voeg {item} toe",

    //JSONString
    selectTime: "Selecteer tijd",
    selectDate: "Selecteer datum",
    selectDateTime: "Selecteer datum en tijd",
    navigateToInstance: "Navigeer naar dossier",
    missingDestination: "Link mist {type}",
    noTimeSet: "Geselecteerde tijd is 00:00",

    //CustomComponent
    customComponentPlaceholder:
      "Dit is een placeholder voor het custom component ",
    slotName: "Slot naam",
    returnedObject: "Teruggegeven object",

    //DateRange
    start: "Begin",
    end: "Einde",

    //Table
    addValue: "Waarde toevoegen",
    addRow: "Rij toevoegen",
    editRow: "Rij bewerken",
    deleteRow: "Verwijder rij",
    save: "Opslaan",
    showList: "Toon lijst",
    hideList: "Verberg lijst",
    newRow: "Nieuwe rij",
    showAll: "Toon alles",
    total: "Totaal",

    //WYSIWYG
    history: "Geschiedenis",
    undo: "Ongedaan maken",
    redo: "Opnieuw",
    text: "Tekst",
    bold: "Vet",
    italic: "Cursief",
    underline: "Onderstrepen",
    strike: "Doorstrepen",
    header: "Titel | Titels",
    lists: "Lists",
    orderedList: "Geordende lijst",
    bulletList: "Ongeordende lijst",
    table: "Tabel",
    tableFunctions: "Tabelfuncties",
    createTable: "Maak tabel",
    deleteTable: "Tabel verwijderen",
    addColumnBefore: "Kolom toevoegen voor",
    addColumnAfter: "Kolom toevoegen na",
    deleteColumn: "Kolom verwijderen",
    addRowAfter: "Voeg rij toe na",
    addRowBefore: "Voeg rij toe voor",
    toggleCellMerge: "Toggle cel samenvoegen",
    link: "Link - om een link toe te voegen, selecteer een woord in de editor, plak de link in het invoerveld en druk op de Enter-toets",
  },

  // Editor
  editor: {
    schema: "Schema",
    fullEditor: "Volledige editor",
    definition: "Definitie",
    definitions: "Definities",
    //Types
    reference: "Referentie",
    grouping: "Groepen",
    group: "Groep",
    object: "Object",
    list: "Lijst",
    text: "Tekst",
    textarea: "Tekstveld",
    email: "E-mailadres",
    url: "URL input",
    texteditor: "Tekstverwerker",
    options: "Opties",
    link: "Link",
    button: "Knop",
    external: "Extern",
    internal: "Intern",
    dates: "Datumvelden",
    time: "Tijd",
    date: "Datum",
    dateTime: "Datum & tijd",
    dateRange: "Periode",
    boolean: "Boolean",
    checkbox: "Checkbox",
    confirmCheckbox: "Bevestig checkbox",
    numbers: "Getallen",
    number: "Getal",
    integer: "Integer",
    rating: "Beoordeling",
    slider: "Slider",
    sliderOptions: "Slider opties",
    currency: "Valuta",
    selection: "Selectie",
    select: "Selectie veld",
    multipleSelect: "Meervoudig selectie veld",
    custom: "Custom",
    customComponent: "Custom component",
    noCustom: "Geen components gevonden. Voer naam handmatig in.",
    customWrongType:
      "Incorrect type <b>{type}</b>. Verwacht data type: <b>{exptected_type}</b>",
    omniselect: "Omniselect",
    omniMultiSelect: "Meervoudige Omniselect",
    omnilookup: "Omnilookup",
    location: "Locatie",
    media: "Media",
    other: "Overige",
    rrule: "R-Rule",
    validation: "Validatie",
    advancedValidation: "Geavanceerde validatie",
    validationErrors: "Validatie fouten",
    page: "Pagina",
    pattern: "Patroon",
    errorMessage: "Foutmelding",
    invalidRegex: "Ongeldige regex",
    delete: "Verwijderen",
    duplicate: "Dupliceer",
    compareText: "Vergelijk tekst",
    compareInfo:
      "Toont de verschillen tussen twee stukken tekst. " +
      "Via een mapping kan tekst geplaats worden in de oud en nieuw properties.",
    old: "Oud",
    new: "Nieuw",

    addExportLocation: "Exportlocatie toevoegen",
    removeExportLocation: "Verwijder deze exportlocatie",
    tableName: "Tabelnaam",
    columnName: "Kolomnaam",
    dataTypesLocked: "U moet eerst een bestemming selecteren",
    rowInserts: "Rij-inzetstukken",

    //ADD Component
    addTo: "Voeg toe aan",
    title: "Titel",
    type: "Type",
    itemType: "Item type",
    selectType: "Selecteer type",
    cancel: "Annuleren",
    add: "Toevoegen",
    notAvailable: "Key is al in gebruik",
    selectItemType: "Selecteer lijst item type",
    noTitle: "Voer een titel in",
    invalidKey: "Ongeldige key",
    noItems: "Nog geen items in",
    noDefinitions: "Nog geen definities",

    //Edit component
    edit: "Wijzig",
    path: "Pad",
    general: "Algemeen",
    settings: "Instellingen",
    advanced: "Geavanceerd",
    export: "Export",
    security: "Beveiliging",
    rules: "Regels",
    conditionalRendering: "Conditional rendering",
    noConditionalRendering: "Nog geen conditional rendering toegevoegd.",
    conditionalOptionsInfo:
      "Verberg of blokkeer opties op basis van waardes in het schema.",
    info: "Info",
    deprecated: "Verouderd",
    displaySubtitle: "Geef info weer als subtitel",
    required: "Verplicht veld",
    showAtCreation: "Toon bij aanmaken dossier",
    readOnly: "Alleen lezen",
    hidden: "Verborgen veld",
    indexElastic: "Indexeer voor selectiescherm",
    privacySensitive: "Privacy gevoelige data",
    executeMapping: "Uitvoeren van de mapping",
    mappedValue: "Gemapte waarde",
    exportSettings: "Data exporteer instellingen",
    destination: "Bestemming",
    dataType: "Datatype",
    table: "Tabel",
    tableRow: "Tabel rij",
    column: "Kolom",
    checkPresence: "Controleer aanwezigheid",
    create: "Creëer",
    createRenderRules: "Creëer render regels voor",
    revertChanges: "Wijzigingen ongedaan maken",
    requiredRules: "Verplicht regels",
    textTextarea: "Tekst/Teksveld",
    textTextareaInfo:
      "Bij een tekstveld zorgt verplicht ervoor dat de waarde moet bestaan " +
      "in de data, maar deze waarde kan ngo wel leeg zijn. " +
      "Als het werkelijk een waarde moeten hebben, zet dan de Min Lengte op 1.",
    requiredChild: "Verplicht veld Child",
    requiredChildInfo:
      "Wanneer het parent object van het verplichte veld niet op verplicht staat, " +
      "zal de validatie niet activeren, tenzij het parent object ook in de data bestaat. " +
      "Om een veld verplicht te maken zonder dat de parent bestaat, kan de parent ook op verplicht gezet worden.",
    requiredCheckbox:
      "Een Boolean welke verplicht is kan ZOWEL aangevinkt als leeg zijn, " +
      "als u alleen aangevinkte waarden wil accepteren, gebruik dan een BooleanConfirm.",
    optional: "Optioneel",
    optionalInfo:
      "Een optioneel veld is niet verplicht, maar wordt getoond wanneer alleen verplichte velden getoond worden.",
    requiredBoolean: "Een verplichte boolean heeft false als standaard waarde.",
    requiredBooleanConfirm: "Deze checkbox is verplicht",
    mappingIdentification: "Mapping identificatie",
    placeholder: "Placeholder",
    tableFooter: "Tabel voettekst key",
    tableFooterPlaceholder: "Voer een unieke key in",

    //JSONString
    format: "Format",
    formatInfo:
      "Gebruik deze karakters om de data weer te geven zoals gewenst. " +
      "Rechts is een voorbeeld van wat de karakters representeren." +
      "Andere karakters kunnen worden gebruikt om de waardes te scheiden. (-, /, [space]).",
    dateCharactersOnly:
      'Type "Datum" mag alleen datumtekens bevatten, zoals dd-MM-yyyy',
    year: "Jaar",
    month: "Maand",
    day: "Dag",
    hour: "Uur",
    minute: "Minuut",
    second: "Seconde",
    includeTime: "Inclusief tijd",
    linkText: "Link tekst",
    test: "Test",
    minLength: "Min lengte",
    maxLength: "Max lengte",
    minItems: "Min items",
    maxItems: "Max items",
    minMax: "Min en Max waarde",
    minMaxLength: "Min en Max lengte",
    minMaxItems: "Min en Max items",
    default: "Standaard",
    defaultValue: "Standaard waarde",
    tabs: "Tabs",
    wizard: "Wizard",
    next: "Volgende",
    previous: "Vorige",
    rows: "Rijen",
    selectDate: "Selecteer Datum",
    selectDateTime: "Selecteer datum & tijd",
    start: "Begin",
    end: "Einde",
    clickable: "Klikbaar",
    noPagination: "Geen paginering",

    //JSONArray
    cantAdd: "Items kunnen niet worden toegevoegd",
    disallowAdd: "Toevoegen niet toestaan",
    cantDelete: "Items kunnen niet worden verwijderd",
    disallowDelete: "Verwijderen niet toestaan",
    tableView: "Tabel weergave",
    collapse: "Inklapbaar | Ingeklapt",

    //JSONEnum
    checkboxGroup: "Checkbox groep",
    allowCreate: "Sta creëeren toe",
    radioDisplay: "Radioknoppen",
    noDefault: "Geen standaard",
    newOption: "Nieuwe optie",
    uniqueOption: "Opties moeten uniek zijn",

    //JSONNumber
    minimum: "Minimum",
    maximum: "Maximum",
    separator: "Scheidingsteken",
    steps: "Stappen",
    notDividable: "Niet deelbaar door",
    showStops: "Toon stops",

    //JSONObject
    update: "Update",

    //Location
    selectMapConfiguration: "Selecteer kaartconfiguratie",
    noMapConfigurations: "Er zijn geen kaartconfiguraties beschikbaar",

    //Media
    selectMediaDefinition: "Selecteer mediadefinitie",
    noMediaDefinition: "Geen mediadefinities beschikbaar",
    infoMediaPath:
      "In alle gevallen moet het pad eindigen op '/'. Als het pad genest is, voer dan alle mapnamen in, gescheiden door '/'. Voorbeeld: 'map/submap'. Indien leeg gelaten, wordt het bestand toegevoegd aan de hoofdmap.",

    //Omniselect
    flexible: "Flexibel",
    searchIncludes: "Zoek inhoud",
    searchOptions: "Zoek opties",
    getPathInfo:
      "Selecteer een lijst van data uit de response van de url met gebruik van een jsonpath.",
    getFilterInfo:
      "Filter de data van de Get response met gebruik van een json path.",
    display: "Weergave",
    infoDisplay:
      "Vul het jsonpath in naar de waarde die weergegeven moet worden " +
      "in de resultaten (meestal $.name).",
    queryLength: "Zoekterm lengte",
    queryLengthInfo:
      "Hoeveel karakters moeten worden ingevoerd, voordat de zoek opdracht uitgevoerd wordt?\n" +
      "Dit verminderd het aantal API calls, omdat de zoek opdracht minder vaak wordt uitgevoerd.",
    save: "Opslag",
    infoSave:
      "Vul het jsonpath in naar de waarde die opgeslagen moet worden (meestal $.identification).",
    populateFields: "Vul andere velden",
    contextDefinitionIdentification: "Context definitie identificatie",
    infoContextDefinitionIdentification:
      "Vul een context definitie identificatie in als de waarde ook in de relaties verwerkt moet worden",
    viewInstance: "Toon de knop om het dossier weer te geven",
    navigateInstance: "Toon de knop om naar het dossier te navigeren",
    field: "Veld | Velden",
    property: "Eigendom",
    variable: "Variabele | Variabelen",
    infoVariables:
      "Het {type} veld accepteert variabelen die worden vervangen met waardes uit het formulier. " +
      "Om een variabele toe te voegen aan de {type} plaats een term tussen dubbele accolades, " +
      "zoals dit: ",
    omnilookupVariable:
      "De standaard query-variabele moet aan de url worden toegevoegd",
    queryField: "Omnilookup zoekveld",
    infoVariablesJSON:
      "De quotes om de variabele zijn nodig, zonder is de query niet geldig.",
    variableName: "naam_variabele",
    referSelf: "Mag niet naar eigen pad refereren",
    omniconnectPlaceholder: "Placeholder",
    omniconnectPlaceholderInfo:
      "Voordat een resultaat geselecteerd is staat een standaard tekst " +
      "in het selectieveld, bepaal hier welke tekst. Laat dit veld leeg voor de standaard waarde: " +
      "'Selecteer'",
    omniconnectSelect: "Selectie pad",
    omniconnectSelectInfo:
      "Met de waarde verkregen via dit pad, wordt een enkel resultaat " +
      "uit de lijst met data gehaald. Wanneer de lijst met resultaten afkomstig is " +
      "van 'api-url/resultaten-lijst', wordt de waarde van het selectie pad achter de url geplaatst " +
      "om een enkel resultaat op te halen 'api-url/resultaten-lijst/selectie-waarde'. " +
      "Dit is vaak $.identification of $.index",
    omniconnectSearch: "Zoeken",
    omniconnectSearchInfo:
      "De lijst met resultaten wordt niet gelijk geladen. De gebruiker moet " +
      "een zoekterm invullen en resulaten gebaseerd op die zoekterm worden weergegeven. " +
      "Deze input definieert de property uit de resultaten waarop de gebruiker kan zoeken, " +
      "voer bijvoorbeeld 'name' in om de gebruiker op namen te laten zoeken.",

    //Conditional Rendering
    action: "Actie | Acties",
    newAction: "Nieuwe actie",
    selectAction: "Selecteer actie",
    condition: "Conditie | Condities",
    addCondition: "Conditie toevoegen",
    conditionsIncomplete: "Condities zijn onvolledig",
    show: "Tonen",
    hide: "Verbergen",
    disable: "Blokkeren",
    makeRequired: "Verplicht maken",
    description: "Beschrijving",
    source: "Bron",
    comparator: "Vergelijker",
    eq: "is gelijk aan",
    ne: "is niet gelijk aan",
    gt: "is groter dan",
    ge: "is groter dan of gelijk aan",
    lt: "is kleiner dan",
    le: "is kleiner dan of gelijk aan",
    valueType: "Waarde type",
    value: "Waarde",
    selectComparator: "Selecteer een vergelijker",
    selectPath: "Selecteer pad",
    selectPathCompare: "Selecteer pad om mee te vergelijken",
    selectedOwnPath: "Eigen pad kan niet geselecteerd worden",
    enterValue: "Voer waarde in om mee te vergelijken",
    addConditions:
      "Voeg ten minste {n} conditie toe | Voeg ten minste {n} condities toe",
    relative: "Reletief",
    specific: "Specifiek",
    difference: "Verschil",
    differenceInfo:
      "Voer een negatief nummer in, om te kijken naar een datum in het verleden.",
    leaveDateEmpty:
      "Laat leeg om de huidige datum te gebruiken bij instance weergave.",
    compareDate: "Vergelijk datum",
    businessDays: "Tel alleen werkdagen",
    dateCompareInfo:
      "Bij het vergelijken van datums, wordt het verschil " +
      "tussen 2 datums en een opgegeven verschil vergeleken.<br/>" +
      "<b>Bron 1</b> is een variable datum ingevoerd in data van het schema.<br/>" +
      "<b>Bron 2</b> is de huidige datum, of een opgegeven statische datum.<br/><br/>" +
      "<b>Voorbeelden</b><br/>" +
      "Kijk of de datum (Bron 1) vandaag is:<br/>" +
      "Bron 1 == Verschil: 0<br/><br/>" +
      "Kijk of de datum (Bron 1) in de toekomst is:<br/>" +
      "Bron 1 > Verschil: 0<br/><br/>" +
      "Kijk of de datum 2 of meer dagen geleden is:<br/>" +
      "Bron 1 <= Verschil: -2",

    noFutureDates: "Na",
    noPastDates: "Voordat",
    dateLimits: "Datum limitaties",
    limitDateInfo:
      "Beperk het datumbereik dat een gebruiker kan selecteren. U zult een oriëntatie (datums in het verleden of in de toekomst) en een referentiedatum moeten specificeren die vandaag (dynamisch) kan zijn, een ander datumitem in het schema of een aangepaste datum.",
    limitDate: "Stel beperkingen voor de datumkiezer in",
    today: "Vandaag",
    customDate: "Aangepaste datum",
    orientation: "Tijd oriëntatie",
    limitOrientation: "Oriëntatie",
    dateReference: "Datum referentie",
    pickDate: "Kies een datum",

    checkPresenceHelp1:
      "Als u dit selecteert, wordt de geëxporteerde waarde een booleaanse waarde die true is als de gegevens aanwezig zijn en anders false.",
    checkPresenceHelp2:
      "Het kan worden gebruikt wanneer bepaalde gegevenseigenschappen verborgen willen worden voor de exportbestemming en nog steeds aangeven of ze aanwezig zijn of niet.",

    deselectCheckPresence:
      "Schakel het selectievakje Controleer aanwezigheid uit om het gegevenstype te kunnen bewerken",

    updateRate: "Update frequentie",

    updateRates: {
      everyMinute: "Elke minuut",
      fiveMinutes: "Elke 5 minuten",
      fifteenMinutes: "Elke 15 minuten",
      thirtyMinutes: "Elke 30 minuten",
      hourly: "Elk uur",
      fourHours: "Elke 4 uur",
      eightHours: "Elke 8 uur",
      daily: "Dagelijks",
    },

    exportDestinations: "Exportbestemmingen",
    exportLocations: "Exportlocaties",
    exportFullContent: "Exporteer volledige content",
  },

  //Security
  security: {
    path: "pad",
    copy: "Kopieer leesregels | Kopieer eigen organisatiecontext | Kopieer mandaat organisatiecontext",
    write: "Schrijf",
    select: "Selecteren",
    read: "Lezen",
    context: "Context",
    application: "Applicatie",
    own: "Eigen",
    mandate: "Mandaat organisaties",
    roleSet: "Rollen set",
    inherited: "Beveiligingsinstellingen overgenomen van de parent",
    selectRoles: "Selecteer rollen",
    allowAll: "Sta alles toe",
    name: "Naam",
    rules: "Regels",
    rule: "Regel",
    allow: "STA TOE",
    deny: "WEIGER",
    actions: "Acties",
    view: "Bekijken",
    start: "Starten",
    cancel: "Annuleren",
    finish: "Afronden",
    create: "Creëren",
    all: "Alle",
    personalContext: "Persoonlijke context",
    organisationContext: "Organisatie context",
    applicationContext: "Applicatie context",

    securityRule: "Beveiligingsregel",
    copyRule: "Kopieer de regel en plak deze als laatste",
    copyType: "Kopieer alle regels van {action1} naar {action2}",
    orderMatters:
      "Begin met de meest gedetailleerde regel en eindig met de meest algemene",
    noInstanceSecurity: "Geen beveiligingsregels voor het dossier toegevoegd",
    noInstanceSecurityTooltip:
      "Als er geen dossierbeveiligingsregels zijn opgegeven, kunnen alle gebruikers het dossier {action}. Content- en procesbeveiligingsregels blijven onafhankelijk",
    saveAlert:
      "Zorg ervoor dat u alle soorten beveiligingscontext instelt om een geldige regel te maken",
    allAllowed: "Alles toegestaan",

    noContentSecurity: "Geen beveiligingsregels voor content toegevoegd",
    noContentSecurityTooltip:
      "Als er geen contentbeveiligingsregels zijn opgegeven, kunnen alle gebruikers het {action}. Dossier- en procesbeveiligingsregels blijven onafhankelijk",
  },

  //RRules
  rrule: {
    frequency: "Frequentie",
    count: "Aantal keer",
    yearly: "Elk jaar",
    monthly: "Elke maand",
    weekly: "Elke week",
    daily: "Elke dag",
    hourly: "Elk uur",
    minutely: "Elke minuut",
    secondly: "Elke seconde",
    interval: "Interval",
    delay: "Vertraging",
    inSeconds: "in secondes",
    restriction: "Beperking",
    onlyWeekDays: "Alleen op weekdagen",
    onlyWeekends: "Alleen in weekenden",
    after: "na",
    every: "Elke",
    for: "Voor",
    times: "Keer",
    and: "En",
    execute: "Voer uit",
    seconds: "seconden",
    minutes: "minuten",
    hours: "uren",
    second: "seconde | {n} seconden",
    minute: "minuut | {n} minuten",
    hour: "uur | {n} uren",
    day: "dag | {n} dagen",
    week: "week | {n} weken",
    month: "maand | {n} maanden",
    year: "jaar | {n} jaren",
  },

  //securityToText Util
  securityToText: {
    all: "Alle",

    view: "Bekijken",
    start: "Starten",
    cancel: "Annuleren",
    finish: "Afronden",
    create: "Creëren",
    select: "Selecteren",
    read: "Lezen",
    get: "Krijgen",

    actions: "Acties",

    own: "eigen",
    mandate: "mandaat",

    personalContext: "Persoonlijke context",
    organisationContext: "Organisatie context",
    applicationContext: "Applicatie context",

    denySingular: "wordt geweigerd voor ",
    denyPlural: "worden geweigerd voor ",
    allowSingular: "is toegestaan voor ",
    allowPlural: "worden toegestaan voor ",

    theFollowing: "de volgende ",
    in: "in ",
    and: "en ",
    or: " of ",

    role: "Rol | Rollen",

    nobody: "Niemand",
    everybody: "Allemaal",

    unknown: "onbekende rol",
    users: "Gebruikers ",
    applicationRole: "met applicatierol ",

    whichAreRelated: "die verband houden ",
    withTheir: "met hun ",
    as: "als ",
    ownOrganisation: "eigen organisatie ",
    mandate_context_roles: "mandaatorganisatie ",
    user_context_roles: "gebruikerscontext ",

    toTheInstance: "naar het dossier in kwestie",
  },
  newSecurityToText: {
    allAllowed: "Elke gebruiker mag een dossier van dit type {action}.",
    noneAllowed: "Niemand mag een dossier van dit type {action}.",
    someAllowed:
      "Gebruiker kan een dossier {action} als ze aan alle volgende criteria voldoen: ",
    specify: "Geef alle overige criteria op om een geldige regel te maken.",
    application_roles: "ze de applicatierol hebben van",
    mandate_context_roles:
      "hun mandaatorganisatie gerelateerd is aan het dossier als",
    own_context_roles:
      "hun eigen organisatie gerelateerd is aan het dossier als",
    user_context_roles: "ze gerelateerd zijn aan het dossier als",
  },
};

export default Object.assign(
  Object.assign(messages, ElementLanguage),
  omnischemaMessages
);
