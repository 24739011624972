import axios from "axios";
import authentication from "@/authentication";
import { ServiceException } from "@/utils/ErrorHandler";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";

/**
 * Declare all resources
 */
const CONCEPT_DEFINITION_RESOURCE = "/definitions/concept-definitions";

const PAGE_SIZE = 30;

export class ConceptDefinitionServices {
  /**
   * Construct axios
   * @param tenantId
   * @param organisationId
   */
  constructor(tenantId, organisationId) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: tenantId,
      },
    });

    if (organisationId) {
      this.setOrganisation(organisationId);
    }
  }

  setOrganisation(organisation) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisation;
  }

  async getConceptDefinition(conceptDefinitionIdentification) {
    return this.OMNIMAP.get(
      CONCEPT_DEFINITION_RESOURCE + "/" + conceptDefinitionIdentification,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }
}
