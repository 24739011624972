import Vue from "vue";
import { StoreException } from "@/utils/ErrorHandler";
import { ActivityServices } from "@/services/ActivityServices";

const state = {
  activityServices: null,
};

const getters = {
  activityServices: (state) => state.activityServices,
};

const actions = {
  /**
   * Internal action for initializing and returning the ActivityServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.tenantServices|(function(*): (null|getters.tenantServices|(function(*))))|null>}
   */
  getActivityServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.activityServices) {
        commit(
          "SET_ACTIVITY_SERVICES",
          new ActivityServices(rootGetters.organisationId)
        );
      }

      return getters.activityServices;
    } catch (error) {
      throw new StoreException("retrieveTenantServices: " + error.message);
    }
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise the organisation id will be set on creation.
   *
   * @param dispatch
   * @param rootGetters
   * @return {Promise<void>}
   */
  updateActivityServices: ({ getters, rootGetters }) => {
    try {
      const activityServices = getters.activityServices;
      if (activityServices) {
        activityServices.setOrganisation(rootGetters.organisationId);
      }
    } catch (error) {
      console.error(error);
    }
  },

  getInputDataDefinition: async ({ dispatch }, activityId) => {
    try {
      const activityServices = await dispatch("getActivityServices");

      return await activityServices.getInputDataDefinition(activityId);
    } catch (error) {
      console.error(error);
    }
  },

  getInputData: async ({ dispatch }, activityId) => {
    try {
      const activityServices = await dispatch("getActivityServices");

      return await activityServices.getInputData(activityId);
    } catch (error) {
      console.error(error);
    }
  },

  setInputData: async ({ dispatch }, payload) => {
    try {
      const activityServices = await dispatch("getActivityServices");

      return await activityServices.setInputData(
        payload.activityId,
        payload.input
      );
    } catch (error) {
      console.error(error);
    }
  },

  getActivityDefinition: async ({ dispatch }, activityId) => {
    try {
      const activityServices = await dispatch("getActivityServices");

      return await activityServices.getActivityDefinition(activityId);
    } catch (error) {
      console.error(error);
    }
  },

  validateActivity: async ({ dispatch }, activityId) => {
    try {
      const activityServices = await dispatch("getActivityServices");

      return await activityServices.validateActivity(activityId);
    } catch (error) {
      console.error(error);
    }
  },

  getActivityDetails: async ({ dispatch }, activityId) => {
    try {
      const activityServices = await dispatch("getActivityServices");

      return await activityServices.getActivityDetails(activityId);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  SET_ACTIVITY_SERVICES: (state, payload) =>
    Vue.set(state, "activityServices", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
