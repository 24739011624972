/**
 * Services to manipulate existing instance definitions.
 *
 * @file   This files defines the InstanceDefinitionServices.
 * @author Omnimap
 * @since  2020.12.30
 * @version 1
 */

import axios from "axios";
import authentication from "../authentication";
import { ServiceException } from "@/utils/ErrorHandler";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";
import { Identifications, ActionType } from "../utils/GeneralConstants";

const INSTANCE_RESOURCE = "/definitions/instance-definitions/";
const PERMISSIONS_PARAM = "?permissions=";
const PAGE_NUMBER_PARAM = "&page-number=";
const PAGE_SIZE_PARAM = "&page-size=";

export class InstanceDefinitionServices {
  constructor(organisation) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisation) {
      this.setOrganisation(organisation);
    }
  }

  /**
   * Sets the organisation (more information welcome)
   * @param organisation
   */
  setOrganisation(organisation) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisation;
  }

  /**
   * Fetches full instance definition based on the instance definition ID
   * @param instanceDefinitionId
   * @returns {Promise<*>}
   */
  async getInstanceDefinition(instanceDefinitionId) {
    // Input validation – is provided instance definition ID correct?
    if (!Object.values(Identifications).includes(instanceDefinitionId)) {
      throw new ServiceException("Invalid instance identification");
    }
    return this.OMNIMAP.get(INSTANCE_RESOURCE + instanceDefinitionId, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error);
      });
  }

  /**
   * Gets a list of actions of a given type that a user is able to perform
   * @param action
   * @param pageNumber
   * @param pageSize
   * @returns {Promise<*>}
   */
  async checkPermissionsForActionType({ action, pageNumber, pageSize }) {
    // Input validation – is provided permissions type correct?
    if (!Object.values(ActionType).includes(action)) {
      throw new ServiceException("Invalid permissions type");
    }
    // Input validation – are provided page number and page size correct?
    if (typeof pageNumber !== "number" || typeof pageSize !== "number") {
      throw new ServiceException("Invalid page number or page size");
    }
    return this.OMNIMAP.get(
      INSTANCE_RESOURCE +
        PERMISSIONS_PARAM +
        action +
        PAGE_NUMBER_PARAM +
        pageNumber +
        PAGE_SIZE_PARAM +
        pageSize,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error);
      });
  }
}
