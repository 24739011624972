export const CandidateTabs = Object.freeze({
  PROFIEL: "Profiel",
  BEOORDELINGEN: "Beoordelingen",
  JOUWSOLLICITATIE: "Jouw sollicitaties",
  ACTIES: "Acties",
  SOLLICITATIEVERLOOP: "Sollicitatieverloop",
  COMMUNICATIE: "Communicatie",
});

export const CandidateRoles = Object.freeze({
  INTERNE_KANDIDAAT: "Interne Kandidaat",
  EXTERNE_KANDIDAAT: "Externe Kandidaat",
  VOORRANGS_KANDIDAAT: "Voorrangskandidaat",
});

export const AddReviewActionIds = Object.freeze([
  "b7875f13-0578-41dc-8dad-ff7336405644",
  "dfbe9e48-dfe2-46c8-be9a-3e6a4664f797",
]);

export const EditReviewActionId = Object.freeze([
  "be56d72e-f0bc-43fb-84ec-44c7494afd18",
  "a33baf6d-f5f5-4215-8780-d26e106b83ae",
]);

export const ReviewedCandidatesStates = Object.freeze([
  "AFGEWEZEN",
  "AVW OVEREENGEKOMEN",
  "INGETROKKEN",
]);

export const CandidatieActivities = Object.freeze({
  AANPASSEN_SOLLICITATIE: "d608ade0-575f-46bd-a31d-0c3e38278d27",
});

export const CandidateProfileRoles = Object.freeze(["Talentprofiel"]);

export const CandidateView = Object.freeze({
  STANDARD: [
    "Kandidaat",
    "RecruitmentKandidaat",
    "LBAVacatureKandidaat",
    "ActiesAanvraagKandidaat",
    "AanvraagActieKandidaat",
  ],
  APPLICANT: ["SollicitatieDetails"],
});

export const CandidateStatuses = Object.freeze({
  ALL: {
    value: null,
    label: "Alle",
    filterable: true,
  },
  NIEUW: {
    value: "NIEUW",
    label: "Nieuw",
    filterable: false,
  },
  TER_BEOORDELING: {
    value: "TER BEOORDELING",
    label: "In behandeling",
    filterable: true,
  },
  UITGENODIGD_VOOR_SELECTIERONDE: {
    value: "UITGENODIGD VOOR SELECTIERONDE",
    label: "Uitgenodigd",
    filterable: false,
  },
  ARBEIDSVOORWAARDEGESPREK: {
    value: "ARBEIDSVOORWAARDEGESPREK",
    label: "Arbeidsvoorwaardegesprek",
    filterable: false,
  },
  INGETROKKEN: {
    value: "INGETROKKEN",
    label: "Ingetrokken",
    filterable: false,
  },
  AFGEWEZEN: { value: "AFGEWEZEN", label: "Afgewezen", filterable: true },
  RESERVE: { value: "IN RESERVE", label: "Reserve", filterable: true },
  AANGENOMEN: { value: "AANGENOMEN", label: "Aangenomen", filterable: true },
});

export const ApplicationsTypes = Object.freeze({
  ALLE: {
    statuses: null,
    label: "Alle",
    filterable: true,
  },
  LOPEND: {
    statuses: [
      "afwijzen",
      "arbeidsvoorwaardengesprek",
      "avw overeengekome",
      "in reserve",
      "inplannen avw gesprek",
      "nieuw",
      "ter beoordeling",
      "uitgenodigd voor gesprek",
      "uitgenodigd voor selectieronde",
      "uitnodigen voor gesprek",
    ],
    label: "Lopend",
    filterable: true,
  },
  AFGEROND: {
    statuses: ["aangenomen", "afgewezen", "ingetrokken"],
    label: "Afgerond",
    filterable: true,
  },
});

export const CandidateSorting = Object.freeze({
  DEFAULT: {
    value: {
      type: "timestamp_any_update",
      direction: "DESCENDING",
    },
    label: "Update, laatste eerst",
  },
  timestamp_any_update: {
    value: {
      type: "timestamp_any_update",
      direction: "ASCENDING",
    },
    label: "Update, oudste eerst",
  },
});

export const BulkActionInputMapping = Object.freeze({
  IN_REVERSE: `<p>Bedankt voor jouw sollicitatie op de functie van %Publicatietitel% - %CAnummer%. Inmiddels hebben we alle kandidaten beoordeeld en gaan we met een aantal geselecteerden verder in gesprek. Hoewel jouw profiel onze interesse heeft gewekt, is onze eerste keuze niet op jou gevallen. Wel zouden we je graag in reserve houden. </p><p>Het is mogelijk dat de volgende selectieronde geen match oplevert. Op dat moment nemen wij contact met jou op om alsnog deel te nemen aan het vervolg van het selectieproces. Natuurlijk alleen wanneer jij hiermee instemt. Ook wanneer wij wel een geschikte kandidaat vinden, ontvang je een bericht. Zo blijf je op de hoogte van het sollicitatieproces.</p><p>Het is goed om te weten dat jouw sollicitatie zichtbaar blijft in jouw profiel. Ga naar <a href="https://werkenbij.amsterdam.nl">amsterdam.nl/werkenbij</a> en log in met jouw e-mailadres en wachtwoord. Vervolgens vind je jouw sollicitatie onder \`Mijn sollicitaties\`. Je kunt daar ook de wervingstekst raadplegen.</p><p>Met vriendelijke groet, <br>[naam contactpersoon]<br>[functie contactpersoon] <br>Gemeente Amsterdam <br>[e-mailadres contactpersoon]</p><p>De gemeente Amsterdam volgt bij werving en selectie de <a href="https://www.nvp-hrnetwerk.nl/sollicitatiecode/">NVP Sollicitatiecode</a>. Vier weken na het afronden van de sollicitatieprocedure worden gegevens over jouw sollicitatie geanonimiseerd. In jouw profiel hou jij inzicht in jouw eigen gegevens. Medewerkers van de gemeente Amsterdam kunnen deze gegevens niet inzien en beschikken enkel over statistische data.</p>`,
  AFWIJZEN: `<p>Bedankt voor jouw sollicitatie op de functie van %Publicatietitel% - %CAnummer%. Wij hebben deze met aandacht bekeken en waarderen je interesse. Helaas is onze keuze niet op jou gevallen. [vul afwijsreden in] </p><p>Hoewel er nu geen match is, kan dit de volgende keer wel het geval zijn. Ben je op zoek naar een volgende loopbaanstap, bekijk dan geregeld het actuele <a href="https://werkenbij.amsterdam.nl">aanbod van vacatures</a>. Ook kun je een job alert instellen via jouw profiel. Dan ontvang je de vacatures van jouw voorkeur direct in jouw mailbox. Wij wensen je veel succes bij het vinden van een passende functie. </p><p> Het is goed om te weten dat jouw sollicitatie zichtbaar blijft in jouw profiel. Ga naar <a href="https://werkenbij.amsterdam.nl">amsterdam.nl/werkenbij</a> en log in met jouw e-mailadres en wachtwoord. Vervolgens vind je jouw sollicitatie onder \`Mijn sollicitaties\`. </p><p>Met vriendelijke groet, </br> [naam contactpersoon] </br> [functie contactpersoon] </br> Gemeente Amsterdam</p><p>[e-mailadres contactpersoon] </br> [telefoonnummer contactpersoon] </p><p> De gemeente Amsterdam volgt bij werving en selectie de <a href="https://www.nvp-hrnetwerk.nl/sollicitatiecode/">NVP Sollicitatiecode</a>. Vier weken na het afronden van de sollicitatieprocedure worden gegevens over jouw sollicitatie geanonimiseerd. In jouw profiel hou jij inzicht in jouw eigen gegevens. Medewerkers van de gemeente Amsterdam kunnen deze gegevens niet inzien en beschikken enkel over statistische data.</p>`,
});

export const ErrorMessage =
  "Sorry. Er is een probleem opgetreden bij het ophalen van de acties. Probeer het later opnieuw.";
