import Vue from "vue";
import { StoreException } from "@/utils/ErrorHandler";
import { InstanceDefinitionServices } from "@/services/InstanceDefinitionServices";
import { Identifications } from "../utils/GeneralConstants";

const state = {
  instanceDefinitionServices: null,
};

const getters = {
  instanceDefinitionServices: (state) => state.instanceDefinitionServices,
};

const actions = {
  /**
   * Internal action for initializing and returning the InstanceDefinitionServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.tenantServices|(function(*): (null|getters.tenantServices|(function(*))))|null>}
   */
  getInstanceDefinitionServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.instanceDefinitionServices) {
        commit(
          "SET_INSTANCE_DEFINITION_SERVICES",
          new InstanceDefinitionServices(rootGetters.organisationId)
        );
      }

      return getters.instanceDefinitionServices;
    } catch (error) {
      throw new StoreException("retrieveTenantServices: " + error.message);
    }
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise the organisation id will be set on creation.
   *
   * @param dispatch
   * @param rootGetters
   * @return {Promise<void>}
   */
  updateInstanceDefinitionServices: ({ getters, rootGetters }) => {
    try {
      const instanceDefinitionServices = getters.instanceDefinitionServices;
      if (instanceDefinitionServices) {
        instanceDefinitionServices.setOrganisation(rootGetters.organisationId);
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Fetches full instance definition based on the instance definition ID
   * @param dispatch
   * @param instanceDefinitionId
   * @returns {Promise<*>}
   */
  getInstanceDefinition: async ({ dispatch }, instanceDefinitionId) => {
    // Input validation – is provided instance definition ID correct?
    if (!Object.values(Identifications).includes(instanceDefinitionId)) {
      throw new StoreException("Invalid instance identification");
    }
    try {
      const instanceDefinitionServices = await dispatch(
        "getInstanceDefinitionServices"
      );
      return await instanceDefinitionServices.getInstanceDefinition(
        instanceDefinitionId
      );
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  SET_INSTANCE_DEFINITION_SERVICES: (state, payload) =>
    Vue.set(state, "instanceDefinitionServices", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
