/**
 * Services to retrieve tenant related data.
 *
 * @file   This files defines the TenantServices.
 * @author Omnimap
 * @since  2020.12.30
 * @version 1
 */

import axios from "axios";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";
import authentication from "@/authentication";

const TENANT = "/security/tenants";
const LOGO = "/logos";
const TYPE = "?type=";

const TENANT_ID = "/" + process.env.VUE_APP_TENANT_IDENTIFICATION;

export class TenantServices {
  /**
   * Construct axios
   * @param organisationId: The unique identification of the organisation
   */
  constructor(organisationId) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisationId) {
      this.setOrganisation(organisationId);
    }
  }

  /**
   * Set the organisation identification
   * @param organisationId: The unique identification of the organisation
   */
  setOrganisation(organisationId) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisationId;
  }

  /**
   * Retrieve the information of the tenant
   * @url {base}/security/tenants/{tenantId}
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get() {
    return this.OMNIMAP.get(TENANT + TENANT_ID, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Retrieve the logo of the tenant
   * @url {base}/security/tenants/logos/{tenantId}
   * @param type
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getLogo(type) {
    return this.OMNIMAP.get(TENANT + LOGO + TENANT_ID + TYPE + type, {
      responseType: "blob",
      headers: {},
    }).then((response) => validateApiOkResponse(response));
  }
}
