/**
 * Services to create and manipulate OmniConnectors.
 *
 * @file   This files defines the OmniConnectorServices.
 * @author Omnimap
 * @since  2020.12.30
 * @version 1
 */

import axios from "axios";
import authentication from "@/authentication";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";

// Base resource
const OMNICONNECT = "/omniconnectors";
const DEFINITIONS = "/definitions";

// Action resources
const CONNECT = "/connect";

export class OmniConnectorServices {
  /**
   * Construct axios
   * @param organisationId: The unique identification of the organisation
   */
  constructor(organisationId) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisationId) {
      this.setOrganisation(organisationId);
    }
  }

  /**
   * Set the organisation identification
   * @param organisationId: The unique identification of the organisation
   */
  setOrganisation(organisationId) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisationId;
  }

  /**
   * Connect to an external endpoint using the specified OmniConnector
   * @url {base}/omniconnectors/{identification}/connect
   * @param omniConnectorId: The unique identification of the OmniConnector
   * @param input
   * @returns {Promise<AxiosResponse<any>>}
   */
  async connect(omniConnectorId, input) {
    return this.OMNIMAP.post(
      OMNICONNECT + "/" + omniConnectorId + CONNECT,
      input,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get all OmniConnectors
   * @url {base}/omniconnectors/definitions
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getAll() {
    return this.OMNIMAP.get(OMNICONNECT + DEFINITIONS, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Create a new OmniConnector definition
   * @url {base}/omniconnectors/definitions
   * @param definition
   * @returns {Promise<AxiosResponse<any>>}
   */
  async create(definition) {
    return this.OMNIMAP.post(OMNICONNECT + DEFINITIONS, definition, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get an OmniConnector with the specified identification.
   * @url {base}/omniconnectors/definitions/{identification}
   * @param omniConnectorId: The unique identification of the OmniConnector
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get(omniConnectorId) {
    return this.OMNIMAP.get(OMNICONNECT + DEFINITIONS + "/" + omniConnectorId, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Update an omniconnector definition.
   * @url {base}/omniconnectors/definitions/{identification}
   * @param omniConnectorId: The unique identification of the OmniConnector
   * @param definition
   * @returns {Promise<AxiosResponse<any>>}
   */
  async update(omniConnectorId, definition) {
    return this.OMNIMAP.put(
      OMNICONNECT + DEFINITIONS + "/" + omniConnectorId,
      definition,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get all OmniConnectors
   * @url {base}/omniconnectors/definitions/{identification}
   * @param omniConnectorId: The unique identification of the OmniConnector
   * @returns {Promise<AxiosResponse<any>>}
   */
  async delete(omniConnectorId) {
    return this.OMNIMAP.delete(
      OMNICONNECT + DEFINITIONS + "/" + omniConnectorId,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }
}
