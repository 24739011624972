import Vue from "vue";
import { ChatServices } from "@/services/ChatServices";
import { StoreException } from "@/utils/ErrorHandler";

const state = {
  ChatServices: null,
};

const getters = {
  ChatServices: (state) => state.ChatServices,
};

const actions = {
  /**
   * Internal action for initializing and returning the ChatServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.ChatServices|(function(*): (null|getters.ChatServices|(function(*))))|null>}
   */
  getChatServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.ChatServices) {
        commit(
          "SET_CHAT_SERVICES",
          new ChatServices(rootGetters.organisationId)
        );
      }

      return getters.ChatServices;
    } catch (error) {
      throw new StoreException("getChatServices: " + error.message);
    }
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise the organisation id will be set on creation.
   *
   * @param dispatch
   * @param rootGetters
   * @return {Promise<void>}
   */
  updateChatServices: ({ getters, rootGetters }) => {
    try {
      const ChatServices = getters.ChatServices;
      if (ChatServices) {
        ChatServices.setOrganisation(rootGetters.organisationId);
      }
    } catch (error) {
      console.error(error);
    }
  },

  retrieveChat: async ({ dispatch }, instanceId) => {
    try {
      const ChatServices = await dispatch("getChatServices");

      return await ChatServices.retrieveChat(instanceId);
    } catch (error) {
      console.error(error);
    }
  },

  retrieveChatByPage: async ({ dispatch }, payload) => {
    try {
      const ChatServices = await dispatch("getChatServices");

      return await ChatServices.retrieveChatByPage(payload);
    } catch (error) {
      console.error(error);
    }
  },

  createChat: async ({ dispatch }, payload) => {
    try {
      const ChatServices = await dispatch("getChatServices");

      return await ChatServices.createChat(payload.instanceId, payload.message);
    } catch (error) {
      console.error(error);
    }
  },

  updateChat: async ({ dispatch }, payload) => {
    try {
      const ChatServices = await dispatch("getChatServices");

      return await ChatServices.updateChat(payload);
    } catch (error) {
      console.error(error);
    }
  },

  removeChat: async ({ dispatch }, payload) => {
    try {
      const ChatServices = await dispatch("getChatServices");

      return await ChatServices.removeChat(
        payload.instanceId,
        payload.messageId
      );
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  SET_CHAT_SERVICES: (state, payload) =>
    Vue.set(state, "ChatServices", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
