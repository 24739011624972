import axios from "axios";
import authentication from "../authentication";
import {
  validateApiOkResponse,
  validateApiSearchResponse,
} from "@/utils/ApiResponseValidator";
import { ServiceException } from "@/utils/ErrorHandler";

const GENERIC = "/generic/";
const INSTANCE_RESOURCE = "/generic/instances/";
const SUBSCRIPTION_RESOURCE = "/subscription";
const READ = "/read";
const NOTIFICATION_RESOURCE = "notifications";

export class NotificationServices {
  constructor(organisation) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
        organisation_identification: organisation,
      },
    });
  }

  /**
   * Retrieve subscription
   * @param instanceId
   * @returns {Promise<AxiosResponse<T>>}
   */
  async getSubscription(instanceId) {
    return this.OMNIMAP.get(
      INSTANCE_RESOURCE + instanceId + SUBSCRIPTION_RESOURCE,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Subscribe to an instance
   * @param instanceId
   * @returns {Promise<AxiosResponse<T>>}
   */
  async postSubscription(instanceId) {
    const body = {};
    return this.OMNIMAP.post(
      INSTANCE_RESOURCE + instanceId + SUBSCRIPTION_RESOURCE,
      body,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Unsubscribe from an instance
   * @param instanceId
   * @returns {Promise<AxiosResponse<T>>}
   */
  async deleteSubscription(instanceId) {
    return this.OMNIMAP.delete(
      INSTANCE_RESOURCE + instanceId + SUBSCRIPTION_RESOURCE,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Edit subscription
   * @param instanceId
   * @returns {Promise<AxiosResponse<T>>}
   */
  async editSubscription(instanceId) {
    const body = {};
    return this.OMNIMAP.put(
      INSTANCE_RESOURCE + instanceId + SUBSCRIPTION_RESOURCE,
      body,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async retrieveUserNotifications(read, pageNumber, pageSize) {
    return this.OMNIMAP.get(
      GENERIC +
        NOTIFICATION_RESOURCE +
        "?include_read=" +
        read +
        "&page-number=" +
        pageNumber +
        "&page-size=" +
        pageSize,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async putUserNotifications(body) {
    return this.OMNIMAP.put(GENERIC + NOTIFICATION_RESOURCE + READ, body, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async retrieveInstanceNotifications(read) {
    let result = [];
    let meta = { current_page: 0 };

    do {
      meta.current_page += 1;
      const response = await this.retrieveInstanceNotificationsPage(
        read,
        meta.current_page
      );
      meta = response.meta;
      if (response.hasOwnProperty("data") && response.data !== undefined) {
        result = result.concat(response.data);
      }
    } while (meta.total_pages && meta.total_pages > meta.current_page);

    return result;
  }

  async retrieveInstanceNotificationsPage(read, pageNumber) {
    return this.OMNIMAP.get(
      INSTANCE_RESOURCE +
        NOTIFICATION_RESOURCE +
        "?include_read=" +
        read +
        "&page-number=" +
        pageNumber +
        "&page-size=30",
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async putInstanceNotifications(body) {
    return this.OMNIMAP.put(
      INSTANCE_RESOURCE + NOTIFICATION_RESOURCE + READ,
      body,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }
}
