import Vue from "vue";
import { StoreException } from "@/utils/ErrorHandler";
import { TenantServices } from "@/services/security/TenantServices";
import { DomainServices } from "@/services/DomainServices";

const state = {
  tenantServices: null,
  domainServices: null,

  tenant: null,
  redirectURL: null,
};

const getters = {
  tenantServices: (state) => state.tenantServices,
  domainServices: (state) => state.domainServices,

  tenant: (state) => state.tenant,
  redirectURL: (state) => state.redirectURL,
};

const actions = {
  /**
   * Internal action for initializing and returning the TenantServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.tenantServices|(function(*): (null|getters.tenantServices|(function(*))))|null>}
   */
  getTenantServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.tenantServices) {
        commit(
          "SET_TENANT_SERVICES",
          new TenantServices(rootGetters.organisationId)
        );
      }

      return getters.tenantServices;
    } catch (error) {
      throw new StoreException(error);
    }
  },

  getDomainServices: async ({ getters, commit }) => {
    try {
      if (null === getters.domainServices) {
        commit("SET_DOMAIN_SERVICES", new DomainServices());
      }

      return getters.domainServices;
    } catch (error) {
      throw new StoreException("retrieveDomainServices: " + error.message);
    }
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise the organisation id will be set on creation.
   *
   * @param dispatch
   * @param rootGetters
   * @return {Promise<void>}
   */
  updateTenantServices: ({ getters, rootGetters }) => {
    try {
      const tenantServices = getters.tenantServices;
      if (tenantServices) {
        tenantServices.setOrganisation(rootGetters.organisationId);
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Function which fetches the tenant, and commits it to the store.
   *
   * @param getters
   * @param commit
   * @param dispatch
   * @return {Promise<void>}
   */
  getTenant: async ({ commit, dispatch }) => {
    try {
      const tenantServices = await dispatch("getTenantServices");

      commit("SET_TENANT", await tenantServices.get());
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Fetches tenant data based on domain
   * @param commit
   * @param dispatch
   * @param domain
   * @returns {Promise<void>}
   */
  fetchTenantDataByDomain: async ({ commit, dispatch }, domain) => {
    if (!domain) {
      throw new StoreException("Domain name is missing");
    }
    try {
      const payload = {
        domain: domain,
      };

      const domainServices = await dispatch("getDomainServices");

      const tenantData = await domainServices.retrieveTenantByDomain(payload);
      // set tenant id and url in storage and store
      if (tenantData) {
        if (tenantData.identification) {
          sessionStorage.setItem("tenantId", tenantData.identification);
          dispatch("setTenantId", tenantData.identification, { root: true });
        }
        if (
          tenantData.vanity_domains &&
          Array.isArray(tenantData.vanity_domains)
        ) {
          tenantData.vanity_domains.forEach((vanityDomain) => {
            if (vanityDomain.domain_name === domain) {
              commit("SET_REDIRECT_URL", vanityDomain.domain_name);
              sessionStorage.setItem("vanityDomain", vanityDomain.domain_name);
            }
          });
        }
      }
      return tenantData;
    } catch (error) {
      throw new StoreException(
        "Error retrieving tenant data based on domain: " + error.message
      );
    }
  },
};

const mutations = {
  SET_TENANT_SERVICES: (state, payload) =>
    Vue.set(state, "tenantServices", payload),
  SET_DOMAIN_SERVICES: (state, payload) =>
    Vue.set(state, "domainServices", payload),

  SET_TENANT: (state, payload) => Vue.set(state, "tenant", payload),
  SET_REDIRECT_URL: (state, payload) => Vue.set(state, "redirectURL", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
