import Vue from "vue";
import { SearchServices } from "@/services/SearchServices";
import { StoreException } from "@/utils/ErrorHandler";
import IdUtils from "@/utils/IdUtils";
import { InstanceQuery } from "@/classes/Search";
import {
  ConfigurationCodes,
  DynamicVariables,
  ColumnTypes,
} from "@/utils/GeneralConstants";

const state = {
  searchServices: null,
};

const getters = {
  searchServices: (state) => state.searchServices,
};

const actions = {
  /**
   * Internal action for initializing and returning the SearchServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.searchServices|(function(*): (null|getters.searchServices|(function(*))))|null>}
   */
  getSearchServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.searchServices) {
        commit(
          "SET_SEARCH_SERVICES",
          new SearchServices(rootGetters.organisationId)
        );
      }

      return getters.searchServices;
    } catch (error) {
      throw new StoreException("getSearchServices: " + error.message);
    }
  },

  retrieveInstances: async ({ dispatch }, { pageSize, pageNumber, query }) => {
    try {
      const searchServices = await dispatch("getSearchServices");

      return await searchServices.postInstanceQuery(
        pageSize,
        pageNumber,
        query,
        false
      );
    } catch (error) {
      console.error(error);
    }
  },

  retrieveSearchResult: async (
    { dispatch },
    { pageSize, pageNumber, query }
  ) => {
    try {
      const searchServices = await dispatch("getSearchServices");
      return await searchServices.getSearchResult(pageSize, pageNumber, query);
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Retrieves all statuses associated with a certain instance definition
   * @param dispatch
   * @param definitionIds
   * @returns {Promise<{data: *, meta: *}>}
   */
  retrieveAllStatuses: async ({ dispatch }, definitionIds) => {
    const searchServices = await dispatch("getSearchServices");

    return searchServices.getAllStatuses(definitionIds);
  },

  /**
   * Retrieves statuses associated with search results of supplied query
   * @param dispatch
   * @param getters
   * @param definitionIds
   * @param query
   * @returns {Promise<*[]>}
   */
  retrieveRelevantStatuses: async ({ dispatch }, { definitionId, query }) => {
    const searchServices = await dispatch("getSearchServices");

    // If supplied query has specified statuses, replace the value with empty array
    if (query.hasOwnProperty("statuses") && query.statuses.length) {
      query.statuses = [];
    }
    // If there's no response (or data in response) – return empty array
    const response = await searchServices.getRelevantStatuses(
      definitionId,
      query
    );
    if (!response || !response.hasOwnProperty("data")) {
      return [];
    }
    // Add all statuses (only it's values) to result array
    const result = [];
    if (response && response.data && response.data.length) {
      for (const status of response.data) {
        result.push(status.status);
      }
    }

    // Return sorted result
    return result.sort();
  },

  retrieveContentValues: async (
    { dispatch },
    { contentKey, definitionIds, textSearch }
  ) => {
    const searchServices = await dispatch("getSearchServices");

    return searchServices.getContentValues(
      definitionIds,
      contentKey,
      textSearch
    );
  },

  retrieveContextValues: async (
    { dispatch },
    { roleName, definitionIds, direction, textSearch }
  ) => {
    const searchServices = await dispatch("getSearchServices");

    if (direction && direction === "from") {
      return searchServices.getFromInstancesByRole(
        definitionIds,
        roleName,
        textSearch ? textSearch : ""
      );
    } else {
      return searchServices.getToInstancesByRole(
        definitionIds,
        roleName,
        textSearch ? textSearch : ""
      );
    }
  },

  /**
   * Action to get a single instance from the search API.
   * @param dispatch
   * @param instanceId
   * @return {Promise<*>}
   */
  searchSingleInstance: async ({ dispatch }, instanceId) => {
    if (!IdUtils.isUUID(instanceId)) {
      throw new StoreException("Ongeldige identificatie ontvangen.");
    }

    try {
      const searchServices = await dispatch("getSearchServices");
      const searchResult = await searchServices.getSearchResult(
        1,
        1,
        new InstanceQuery(instanceId)
      );

      if (searchResult?.meta?.total_found === 1) {
        return searchResult.data[0];
      }
    } catch (e) {
      console.error(e);
    }
  },

  /**
   * Export an Excel file including results of single or multiple queries
   * @param rootGetters
   * @param dispatch
   * @param params
   * @returns {Promise<*|AxiosResponse<*>>}
   */
  export: async ({ rootGetters, dispatch }, params) => {
    const searchServices = await dispatch("getSearchServices");

    // Step 1: Setting the payload
    let payload = {};
    // Case multiple queries
    if (Array.isArray(params.query) && params.query.length) {
      const queries = [...params.query];

      queries.map(async (item) => {
        item = await dispatch("convertQueryContent", item);
        item = await dispatch("setStatusTimestampsInRequest", item);
        item = await dispatch("removeAndOrInQuery", item);
        return item;
      });
      payload.queries = queries;
      // Case single query
    } else {
      payload = { ...params.query };
      payload = await dispatch("convertQueryContent", payload);
      payload = await dispatch("setStatusTimestampsInRequest", payload);
      payload = await dispatch("removeAndOrInQuery", payload);
    }

    // Step 2: Setting the columns
    const columns = params.columns.map((column) => column.value);
    const identifier = columns.includes("identifier");
    const identification = columns.includes("identification");

    payload.columns = columns.map((column) => {
      let newColumn = column.split(".");
      newColumn = { path: newColumn.join("/") };
      return newColumn;
    });

    if (identifier && !identification) {
      payload.columns.unshift({
        path: "identification",
      });
    }

    if (payload.queries) {
      // Further steps for multiple queries export
      return searchServices.exportMultiple(payload);
    } else {
      // Further steps for single query export
      const pageSize =
        params.size && typeof params.size === "number" ? params.size : 10000;

      const config = await dispatch(
        "configurations/retrieveConfigurationByReference",
        {
          code: ConfigurationCodes.EXCEL_EXPORT,
          reference: rootGetters.tenantId,
        },
        { root: true }
      );
      const settings = config && config.data ? config.data : {};
      return searchServices.exportWithSettings(payload, pageSize, settings);
    }
  },

  /**
   * Converts a query so it's valid for the API
   * @param dispatch
   * @param query
   * @returns {Promise<any>}
   */
  async convertQueryContent({ dispatch }, query) {
    const newQuery = JSON.parse(JSON.stringify(query));

    /* If a query exist with a certain wildcard, like $CURRENT_USER_ID,
      Change this to the logged in user credentials */
    const extraContents = [];

    if (newQuery.hasOwnProperty("content")) {
      newQuery.content = await Promise.all(
        newQuery.content.map(async (item) => {
          if (!item.hasOwnProperty("values")) {
            return item;
          }

          //Replace any wildcard like user schema path or current user/organization with the actual content
          item = await dispatch("replaceWildcardsWithValueInContent", item);

          const code = item.values.find(
            (value) => typeof value === "string" && value.startsWith("$DATE_")
          );

          // If there is a dynamic datecode saved, get the period belonging.
          if (code) {
            if (item.datatype === "DATERANGE") {
              item["period"] = actions.dateMapper(code);

              if (
                item.hasOwnProperty("period") &&
                item.hasOwnProperty("values")
              ) {
                delete item.values;
              }
            } else {
              // content that has a date as type.
              item["comparator"] = "GTE";
              item["timestamp_value"] = actions.dateMapper(code)[0];
              // Requires a second content entry to work.
              const extraContent = {
                and_or: item.and_or,
                datatype: item.datatype,
                key: item.key,
                comparator: "LT",
                timestamp_value: actions.dateMapper(code)[1],
              };
              extraContents.push(extraContent);
            }
            delete item.values;
          } else if (
            item.datatype === "DATE" ||
            item.datatype === "DATE-TIME"
          ) {
            // When there is a date typed content selected by user, but the period is not yet selected, the content only contains an
            // empty values array. However, the API needs a comparator and date to not break the layout.
            // TLDR; When the user has not yet selected a period, filter on the dates that came after 1 January 1970.
            item["comparator"] = "GTE";
            item["timestamp_value"] = new Date(1970, 1, 1);
          }

          // If a daterange without dates is passed. create a period with empty values
          if (
            item.datatype === "DATERANGE" &&
            item.hasOwnProperty("values") &&
            !item.hasOwnProperty("period")
          ) {
            delete item.values;
            item.period = ["", ""];
            return item;
          }

          return item;
        })
      );

      newQuery.content = newQuery.content.concat(extraContents);
    }
    return newQuery;
  },

  setStatusTimestampsInRequest(_context, query) {
    if (query.hasOwnProperty("statuses") && query.statuses.length) {
      if (query.statuses[0].hasOwnProperty("value")) {
        query.statuses.forEach((status) => {
          const dates = actions.dateMapper(status.value[0]);
          status["start_date"] = dates[0];
          status["end_date"] = dates[1];
          delete status.value;
        });
      }
      if (query.hasOwnProperty("content") && query.content.length) {
        if (query.content.some((content) => content.key === "status")) {
          query.content = query.content.filter(
            (content) => content.key !== "status"
          );
        }
      }
    }
    return query;
  },

  /**
   * Checks if query has the and_or property, which is not being accepted by the API anymore.
   * When the query comes from an 'old' filter, the and_or property needs to be deleted first.
   * @param query where to search for the and_or property and delete that
   * @return Corrected query
   */
  removeAndOrInQuery(_context, query) {
    if (query.and_or) {
      delete query.and_or;
    }
    return query;
  },

  /**
   * Gets for a certain dynamic date code the period belonging to it.
   * @param code (a dynamic date code)
   * @returns {Date[]}
   */
  dateMapper(code) {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    const start = new Date(date);
    const end = new Date(date);

    if (code === "$DATE_DAY") {
      end.setDate(end.getDate() + 1);
    } else if (code === "$DATE_WEEK") {
      end.setDate(end.getDate() + 7);
    } else if (code === "$DATE_MONTH") {
      end.setMonth(end.getMonth() + 1);
    } else if (code === "$DATE_3MONTHS") {
      end.setMonth(end.getMonth() + 3);
    } else if (code === "$DATE_YEAR") {
      end.setFullYear(end.getFullYear() + 1);
    } else if (code === "$DATE_LAST_DAY") {
      start.setDate(start.getDate() - 1);
    } else if (code === "$DATE_LAST_WEEK") {
      start.setDate(start.getDate() - 7);
    } else if (code === "$DATE_LAST_MONTH") {
      start.setMonth(start.getMonth() - 1);
    } else if (code === "$DATE_LAST_3MONTHS") {
      start.setMonth(start.getMonth() - 3);
    } else if (code === "$DATE_LAST_YEAR") {
      start.setFullYear(start.getFullYear() - 1);
    }
    return [start, end];
  },

  /**
   * Replaces any user schema path within the 'content' property of a query with the actual value it points to
   * @param rootGetters
   * @param queryItem
   * @returns {*}
   */
  replaceWildcardsWithValueInContent: async (
    { rootGetters, dispatch },
    queryItem
  ) => {
    if (queryItem && queryItem.values) {
      const arrayOfItems = queryItem.values;

      //First look for and in case replace wild cards like 'Current user ID' & 'Current user Org'
      const idIndex = arrayOfItems.indexOf(DynamicVariables.CURRENT_USER_ID);
      const nameIndex = arrayOfItems.indexOf(
        DynamicVariables.CURRENT_USER_NAME
      );
      const organisationIndex = arrayOfItems.indexOf(
        DynamicVariables.CURRENT_ORGANISATION
      );
      if (idIndex !== -1) {
        arrayOfItems[idIndex] = rootGetters.userId;
      }
      if (nameIndex !== -1) {
        arrayOfItems[nameIndex] = rootGetters.userName;
      }
      if (organisationIndex !== -1) {
        arrayOfItems[organisationIndex] = rootGetters.organisationId;
      }

      // Then check if the query contains any user schema paths,
      const userSchemaPaths = arrayOfItems.filter(
        (value) => typeof value === "string" && value.startsWith("$.user.")
      );

      const pathValuePairs = await dispatch(
        "getContentOfUserSchemaPath",
        userSchemaPaths
      );

      if (pathValuePairs && userSchemaPaths) {
        for (const pathItem of userSchemaPaths) {
          //first remove path
          const indexOfPath = arrayOfItems.indexOf(pathItem);
          arrayOfItems.splice(indexOfPath, 1);

          //then add corresponding value if existing
          if (
            pathValuePairs[pathItem] !== null &&
            pathValuePairs[pathItem] !== undefined &&
            pathValuePairs[pathItem] !== ""
          ) {
            arrayOfItems.push(pathValuePairs[pathItem]);
          }
        }
      }
    }
    return queryItem;
  },

  /**
   * Based on a list of user schema paths this will return an object with path & corresponding value.
   * @param rootGetters
   * @param userSchemaPaths
   * @returns {{}}
   */
  getContentOfUserSchemaPath({ rootGetters }, userSchemaPaths) {
    if (
      userSchemaPaths &&
      Array.isArray(userSchemaPaths) &&
      userSchemaPaths.length
    ) {
      const userSchemaData = rootGetters.user;
      if (userSchemaData && userSchemaData.profile) {
        return this.formatUserSchemaPathContent(
          userSchemaPaths,
          userSchemaData
        );
      }
    }
    return {};
  },

  formatUserSchemaPathContent(userSchemaPaths, userSchemaData) {
    // Loop through the user schema paths present in the query
    const output = {};
    let value;

    for (const pathItem of userSchemaPaths) {
      output[pathItem] = "";

      // Get the actual path by removing the symbol "$" and keyword "user"
      const path = pathItem.split(".").splice(2);

      // If length is 1 then value and type can be found at root level
      if (path.length === 1) {
        value = userSchemaData.profile[path[0]];

        // Otherwise it means that data is nested so loop through the user data till the right path is reached
      } else if (path.length > 1) {
        for (const item of path) {
          if (
            value === undefined &&
            userSchemaData.profile.hasOwnProperty(item)
          ) {
            value = userSchemaData.profile[item];
          } else if (value && value[item]) {
            value = value[item];
          } else {
            value = null;
          }
        }
      }
      output[pathItem] = value;
    }
    return output;
  },

  retrieveColumns: async ({ dispatch }, payload) => {
    const searchServices = await dispatch("getSearchServices");
    const columns = [
      {
        label: "General",
        options: [
          {
            label: "columns.identification",
            value: "identification",
            type: ColumnTypes.STRING,
          },
          {
            label: "columns.id",
            value: "identifier",
            type: ColumnTypes.STRING,
          },
          {
            label: "columns.type",
            value: "relationships.definition.name",
            type: ColumnTypes.STRING,
            width: "120px",
          },
          {
            label: "columns.name",
            value: "name",
            type: ColumnTypes.STRING,
          },
          {
            label: "columns.timestamp_created",
            value: "timestamp_created",
            type: ColumnTypes.TIMESTAMP,
          },
          {
            label: "columns.timestamp_any_update",
            value: "timestamp_any_update",
            type: ColumnTypes.TIMESTAMP,
          },
          {
            label: "columns.status",
            value: "status",
            type: ColumnTypes.STRING,
          },
        ],
      },
    ];

    let response = await searchServices.getPropertyCodes(
      payload.definitionIds,
      payload.query
    );
    const properties = {
      label: "Properties",
      options: [],
    };
    if (response.data && Array.isArray(response.data)) {
      response.data.forEach(function (property) {
        if (
          property.hasOwnProperty("code") &&
          property.hasOwnProperty("datatype")
        ) {
          properties.options.push({
            value: "propertiesList." + property.code + ".value",
            label: property.code,
            type: property.datatype,
          });
        }
      });
    }
    columns.push(properties);

    response = await searchServices.getContentKeys(
      payload.definitionIds,
      payload.query
    );
    const content = {
      label: "Content",
      options: [],
    };
    if (response.data && Array.isArray(response.data)) {
      response.data.forEach(function (contentItem) {
        if (
          contentItem.hasOwnProperty("key") &&
          contentItem.hasOwnProperty("interface_key") &&
          contentItem.hasOwnProperty("datatype")
        ) {
          content.options.push({
            value: "content." + contentItem["key"],
            label: contentItem["interface_key"],
            datatype: contentItem.datatype,
          });
        }
      });
    }
    columns.push(content);
    return columns;
  },
};

const mutations = {
  SET_SEARCH_SERVICES: (state, payload) =>
    Vue.set(state, "searchServices", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
