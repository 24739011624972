import Vue from "vue";
import { StoreException } from "@/utils/ErrorHandler";
import { MatchboxServices } from "@/services/MatchboxServices";
import { OmniConnectorServices } from "@/services/OmniConnectorServices";
import IdUtils from "../utils/IdUtils";

const state = {
  matchboxServices: null,
  omniConnectorServices: null,
  bearerToken: null,
  bearerTokenExpiration: null,

  matches: [],
  totalFound: 0,
  pageSize: 30,
  pageNumber: 1,
};

const getters = {
  matchboxServices: (state) => state.matchboxServices,
  omniConnectorServices: (state) => state.omniConnectorServices,
  bearerToken: (state) => state.bearerToken,
  bearerTokenExpiration: (state) => state.bearerTokenExpiration,
  matches: (state) => state.matches,
  totalFound: (state) => state.totalFound,
  pageNumber: (state) => state.pageNumber,
  pageSize: (state) => state.pageSize,
};

const actions = {
  getMatchboxServices: async ({ getters, commit, dispatch }) => {
    if (null === getters.matchboxServices) {
      if (
        null === getters.bearerToken ||
        new Date() >= getters.bearerTokenExpiration
      ) {
        await dispatch("getMatchboxToken");
      }

      commit(
        "SET_MATCHBOX_SERVICES",
        new MatchboxServices(getters.bearerToken)
      );
    }

    return getters.matchboxServices;
  },

  getOmniConnectorServices: async ({ getters, rootGetters, commit }) => {
    if (null === getters.omniConnectorServices) {
      if ("" === rootGetters.tenantId) {
        throw new StoreException("No tenant id known.");
      } else {
        commit(
          "SET_OMNICONNECTOR_SERVICES",
          new OmniConnectorServices(rootGetters.organisationId)
        );
      }
    }

    return getters.omniConnectorServices;
  },

  getMatchboxToken: async ({ commit, dispatch }) => {
    try {
      const omniConnectorServices = await dispatch("getOmniConnectorServices");
      const currentDate = new Date();
      const expirationDate = new Date();

      const result = await omniConnectorServices.connect(
        process.env.VUE_APP_MATCHBOX_OMNICONNECT_ID,
        {}
      );

      if (result && "data" in result) {
        commit("SET_BEARER_TOKEN", result.data.access_token);
        expirationDate.setTime(
          currentDate.getTime() + result.data.expires_in * 1000
        );

        commit("SET_BEARER_TOKEN_EXPIRATION", expirationDate);
      }
    } catch (e) {
      throw new StoreException(
        "Something went wrong while acquiring token: \n" + e
      );
    }
  },

  formatPayload: async (_context, payload) => {
    const payloadCopy = JSON.parse(JSON.stringify(payload));
    if (!payload?.locations?.value[0]?.label) {
      delete payloadCopy.locations;
    }

    for (const property in payloadCopy) {
      // format the content of the property
      if (
        payloadCopy[property] &&
        payloadCopy[property].value &&
        payloadCopy[property].value.length
      ) {
        payloadCopy[property].value = payloadCopy[property].value.map(
          (block) => {
            return {
              labels: block.labels,
              required: block.required,
              weight: block.weight,
            };
          }
        );
      }

      if (payload[property].hasOwnProperty("custom")) {
        delete payloadCopy[property].custom;
      }
      if (payload[property].hasOwnProperty("label")) {
        delete payloadCopy[property].label;
      }

      // Remove the following properties since matchbox doesn't recognize them
      const propertiesToDelete = [
        "workExperiences",
        "educations",
        "cvLanguage",
      ];
      if (propertiesToDelete.includes(property)) {
        delete payloadCopy[property];
      }

      // delete empty properties
      if (payload[property].value && !payload[property].value.length) {
        delete payloadCopy[property];
      }
    }

    // Set the "special" properties in canse they have a value
    if (Object.keys(payloadCopy?.extraProperties?.content).length) {
      const content = JSON.parse(
        JSON.stringify(payloadCopy?.extraProperties?.content)
      );
      if (content?.workingAndThinking?.profileValue.length) {
        // Set werk- en denkniveau
        content["werkDenkNiveau"] = content.workingAndThinking;
        delete content.workingAndThinking;
      } else {
        delete content.workingAndThinking;
      }
      if (content?.salaryScale?.profileValue.length) {
        // Set salaris schaal
        content["salarisSchaal"] = content.salaryScale;
        delete content.salaryScale;
      } else {
        delete content.salaryScale;
      }
      payloadCopy.extraProperties.content = content;
    }
    return payloadCopy;
  },

  getCandidates: async (
    { commit, dispatch, getters },
    { options, filters }
  ) => {
    try {
      const matchboxServices = await dispatch("getMatchboxServices");

      const payloadCopy = await dispatch("formatPayload", options);

      const response = await matchboxServices.getCandidates(
        payloadCopy,
        filters,
        getters.pageSize,
        getters.pageNumber
      );

      if (response && response.hasOwnProperty("count")) {
        commit("SET_TOTAL_FOUND", response.count);
      }

      let matches;
      if (response && response.hasOwnProperty("matches")) {
        matches = response.matches.map((match) => {
          const formattedMatch = match;
          formattedMatch.score = match.score * 100;
          formattedMatch.identification = match.id;
          if (
            match.document &&
            match.document.hasOwnProperty("jobFunctionLabels") &&
            match.document.jobFunctionLabels.length
          ) {
            // label property needed for the columns of InstanceList
            formattedMatch.document.jobFunctionLabel =
              match.document.jobFunctionLabels[0];
          }
          return formattedMatch;
        });
      } else if (response === 404) {
        matches = [];
      }
      commit("SET_MATCHES", matches);
    } catch (error) {
      throw new StoreException(
        "Something went wrong while acquiring candidates: \n" + error
      );
    }
  },

  getJobs: async (
    { commit, dispatch },
    { payload, pageSize, pageNumber = 1 }
  ) => {
    try {
      const matchboxServices = await dispatch("getMatchboxServices");
      const payloadCopy = await dispatch("formatPayload", payload);

      // Add filter
      const filter = {
        bool: {
          must: [
            { term: { "extraProperties.status": "GEPUBLICEERD" } },
            {
              term: {
                "extraProperties.content.soortaanvraag.typeaanvraag":
                  "Nieuwe medewerker",
              },
            },
            {
              bool: {
                should: [
                  {
                    bool: {
                      must: [
                        {
                          range: {
                            "extraProperties.content.wervingstekst.plaatsingsdata.plaatsingsperiodeVan":
                              {
                                lte: "now/d",
                              },
                          },
                        },
                        {
                          range: {
                            "extraProperties.content.wervingstekst.plaatsingsdata.plaatsingsperiodeTot":
                              {
                                gte: "now/d",
                              },
                          },
                        },
                      ],
                    },
                  },
                  {
                    bool: {
                      must: [
                        {
                          range: {
                            "extraProperties.content.externewervingstekst.plaatsingsdata.plaatsingsperiodeVan":
                              {
                                lte: "now/d",
                              },
                          },
                        },
                        {
                          range: {
                            "extraProperties.content.externewervingstekst.plaatsingsdata.plaatsingsperiodeTot":
                              {
                                gte: "now/d",
                              },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      };

      const minScore = 0.6;

      const response = await matchboxServices.getJobs(
        payloadCopy,
        filter,
        minScore,
        pageSize,
        pageNumber
      );

      if (response && response.hasOwnProperty("count")) {
        commit("SET_TOTAL_FOUND", response.count);
      }
      let matches;
      if (response && response.hasOwnProperty("matches")) {
        matches = response.matches.map((match) => {
          const formattedMatch = match;
          formattedMatch.score = match.score * 100;
          return formattedMatch;
        });
      } else if (response === 404) {
        matches = [];
      }
      commit("SET_MATCHES", matches);
    } catch (error) {
      throw new StoreException(
        "Something went wrong while acquiring candidates: \n" + error
      );
    }
  },

  getSuggestions: async ({ dispatch }, payload) => {
    const matchboxServices = await dispatch("getMatchboxServices");
    if (!payload) {
      throw new StoreException("Please provide a request body");
    }
    try {
      return await matchboxServices.findPrefix(payload);
    } catch (error) {
      throw new StoreException(
        "Something went wrong while retrieving suggestions: \n" + error
      );
    }
  },

  getMatchProfileFromJob: async ({ dispatch }, jobIdentification) => {
    const matchboxServices = await dispatch("getMatchboxServices");
    if (!IdUtils.isUUID(jobIdentification)) {
      throw new StoreException("Please provide a valid job identification");
    }
    try {
      return await matchboxServices.getMatchProfileFromJob(jobIdentification);
    } catch (error) {
      throw new StoreException(
        "Something went wrong while retrieving match profile from job: \n" +
          error
      );
    }
  },

  getJobFromCandidate: async ({ dispatch }, candidateIdentification) => {
    const matchboxServices = await dispatch("getMatchboxServices");
    if (!IdUtils.isUUID(candidateIdentification)) {
      throw new StoreException(
        "Please provide a valid candidate identification"
      );
    }
    try {
      return await matchboxServices.getJobFromCandidate(
        candidateIdentification
      );
    } catch (error) {
      throw new StoreException(
        "Something went wrong while jobs based on candidate profile: \n" + error
      );
    }
  },

  setPageNumber: ({ commit }, payload) => {
    if (payload) {
      commit("SET_PAGE_NUMBER", payload);
    }
  },

  setPageSize: ({ commit }, payload) => {
    if (payload) {
      commit("SET_PAGE_SIZE", payload);
    }
  },

  setMatches: ({ commit }, payload) => {
    commit("SET_MATCHES", payload);
  },

  setTotalFound: ({ commit }, payload) => {
    commit("SET_TOTAL_FOUND", payload);
  },
};

const mutations = {
  SET_MATCHBOX_SERVICES: (state, payload) =>
    Vue.set(state, "matchboxServices", payload),
  SET_OMNICONNECTOR_SERVICES: (state, payload) =>
    Vue.set(state, "omniConnectorServices", payload),
  SET_BEARER_TOKEN: (state, payload) => Vue.set(state, "bearerToken", payload),
  SET_BEARER_TOKEN_EXPIRATION: (state, payload) =>
    Vue.set(state, "bearerTokenExpiration", payload),
  SET_MATCHES: (state, payload) => Vue.set(state, "matches", payload),
  SET_TOTAL_FOUND: (state, payload) => Vue.set(state, "totalFound", payload),
  SET_PAGE_NUMBER: (state, payload) => Vue.set(state, "pageNumber", payload),
  SET_PAGE_SIZE: (state, payload) => Vue.set(state, "pageSize", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
