import axios from "axios";
import authentication from "@/authentication";
import { ServiceException } from "@/utils/ErrorHandler";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";

const MAPPING_EXECUTION_RESOURCE = "/mapping/execute/";

export class MappingExecutionServices {
  /**
   * Construct axios
   * @param tenantId
   * @param organisationId
   */
  constructor(tenantId, organisationId) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: tenantId,
      },
    });

    if (organisationId) {
      this.setOrganisation(organisationId);
    }
  }

  setOrganisation(organisation) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisation;
  }

  async getOutputDataUsingPost(mappingId, mappingData) {
    return this.OMNIMAP.post(
      MAPPING_EXECUTION_RESOURCE + mappingId,
      mappingData,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }
}
