/**
 * Services to create and manipulate the user.
 *
 * @file   This files defines the UserServices.
 * @author Omnimap
 * @since  2020.12.30
 * @version 1
 */

import axios from "axios";
import authentication from "../../authentication";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";
import { ServiceException } from "@/utils/ErrorHandler";

const USER_PROFILE_RESOURCE = "/security/users/profile";
const USER_ORGANISATIONS_RESOURCE = "/security/users/organisations";
const REGISTRATION_RESOURCE = "/security/users/registration";
const USER_ACTIVATION_RESOURCE = "/security/users/activate";
const INVITATION_RESOURCE = "/security/users/status?invitation-key=";

const IDENTITY_PROVIDER = "/security/authentication/identity_providers";

export class UserServices {
  constructor(organisation) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisation) {
      this.setOrganisation(organisation);
    }
  }

  setOrganisation(organisation) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisation;
  }

  async getUserProfile() {
    return this.OMNIMAP.get(USER_PROFILE_RESOURCE, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async getUserOrganisations() {
    return this.OMNIMAP.get(USER_ORGANISATIONS_RESOURCE, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async checkUserRegistration() {
    return this.OMNIMAP.get(REGISTRATION_RESOURCE, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async registerUser() {
    return this.OMNIMAP.post(
      REGISTRATION_RESOURCE,
      {},
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  async checkInvitationKey(invitationKey) {
    return this.OMNIMAP.get(INVITATION_RESOURCE + invitationKey, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async getIdentityProviders() {
    return this.OMNIMAP.get(IDENTITY_PROVIDER, {})
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error);
      });
  }

  async getIdentityProvider(identification) {
    return this.OMNIMAP.get(IDENTITY_PROVIDER + "/" + identification)
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async postActivateUser(invitationKey) {
    return this.OMNIMAP.post(
      USER_ACTIVATION_RESOURCE,
      {
        invitation_key: invitationKey,
      },
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error);
      });
  }
}
