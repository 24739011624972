import Vue from "vue";
import { ConceptDefinitionServices } from "@/services/ConceptDefinitionServices";
import { StoreException } from "@/utils/ErrorHandler";
import IdUtils from "@/utils/IdUtils";

const state = {
  conceptDefinitionServices: null,
};

/*
 * Create getters for all items in state
 */
const getters = {
  conceptDefinitionServices: (state) => state.conceptDefinitionServices,
};

const actions = {
  /**
   * Getting the conceptDefinitionServices. When conceptDefinitionServices is not set, set the conceptDefinitionServices.
   *
   * @param rootGetters
   * @param getters
   * @param commit
   * @returns {Promise<getters.conceptDefinitionServices|(function(*): (null|getters.conceptDefinitionServices|(function(*))))>}
   */
  retrieveConceptDefinitionServices: async ({
    getters,
    rootGetters,
    commit,
  }) => {
    if (getters.conceptDefinitionServices == null) {
      if (rootGetters.tenantId === "") {
        throw new StoreException("No tenant id known.");
      }
      commit(
        "SET_CONCEPT_DEFINITION_SERVICES",
        new ConceptDefinitionServices(
          rootGetters.tenantId,
          rootGetters.organisationId
        )
      );
    }
    return getters.conceptDefinitionServices;
  },

  getConceptDefinition: async ({ dispatch }, conceptDefinitionId) => {
    if (!IdUtils.isUUID(conceptDefinitionId)) {
      throw new StoreException(
        "Unable to retrieve concept definition. Required ID is missing or not valid"
      );
    }

    try {
      const conceptDefinitionServices = await dispatch(
        "retrieveConceptDefinitionServices"
      );
      return await conceptDefinitionServices.getConceptDefinition(
        conceptDefinitionId
      );
    } catch (e) {
      throw new StoreException(
        "Unable to retrieve concept definition. " + e.message
      );
    }
  },
};

const mutations = {
  SET_CONCEPT_DEFINITION_SERVICES: (state, payload) =>
    Vue.set(state, "conceptDefinitionServices", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
