import Vue from "vue";
import Router from "vue-router";
import {
  validTenantGuard,
  userGuard,
  landingPageGuard,
  organisationGuard,
} from "@/guards";
import { RoutesTitles, Breadcrumbs } from "@/utils/GeneralConstants";

Vue.use(Router);

export default new Router({
  mode: "history",
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "exact-active", // active class for *exact* links.
  base: process.env.BASE_URL,
  scrollBehavior() {
    const el = document.getElementById("app");
    el.scrollTo(0, 0);
  },
  routes: [
    /* [START]: General */
    {
      path: "/",
      name: "GoTo",
      component: () =>
        import(/* webpackChunkName: "login" */ "@/views/GoTo.vue"),
      beforeEnter: validTenantGuard,
    },
    {
      path: "/landingPage",
      name: "LandingPage",
      component: () =>
        import(/* webpackChunkName: "landingpage" */ "@/views/LandingPage.vue"),
      beforeEnter: landingPageGuard,
    },
    {
      path: "/organisationSelect",
      name: "OrganisationSelect",
      component: () =>
        import(
          /* webpackChunkName: "organisations" */ "@/views/OrganisationSelect.vue"
        ),
      beforeEnter: organisationGuard,
      meta: { title: RoutesTitles.ORGANISATIES },
    },
    {
      path: "/auth/silentRenew",
      name: "SilentRenew",
      component: () =>
        import(/* webpackChunkName: "silentrenew" */ "@/views/SilentRenew.vue"),
    },
    {
      path: "/invite/:invitationKey",
      name: "NewUserLogin",
      component: () =>
        import(/* webpackChunkName: "newuser" */ "@/views/GoTo.vue"),
      beforeEnter: validTenantGuard,
    },
    /* [END]: General */

    /* [START]: Dashboard */
    {
      path: "/dashboard",
      name: "Dashboard",
      component: () =>
        import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.DASHBOARD,
        breadcrumb: Breadcrumbs.DASHBOARD,
      },
    },
    /* [END]: Dashboard */

    /* [START]: Mijn sollicitaties */
    {
      path: "/dashboard/sollicitaties/:instanceId/details/:candidateId/actie/:actionId",
      name: "SollicitatieDetailsActie",
      component: () =>
        import(/* webpackChunkName: "Sollicitaties" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.SOLLICITATIE_ACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/sollicitaties/:instanceId/details/:candidateId",
      name: "SollicitatieDetails",
      component: () =>
        import(/* webpackChunkName: "Sollicitaties" */ "@/views/Kandidaat.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.SOLLICITATIE_DETAILS,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/sollicitaties",
      name: "Sollicitaties",
      component: () =>
        import(/* webpackChunkName: "Sollicitaties" */ "@/views/Sollicitaties"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.MIJN_SOLLICITATIES,
        breadcrumb: Breadcrumbs.MIJN_SOLLICITATIES,
      },
    },
    /* [END]: Mijn sollicitaties */

    /* [START]: Mijn Werving & Selectie */
    /* [START]: Mijn Werving & Selectie  -> Mijn Aanvragen */
    {
      path: "/dashboard/wervingselectie/aanvragen/:instanceId/kandidaat/:candidateId/actie/:actionId",
      name: "KandidaatActie",
      component: () =>
        import(/* webpackChunkName: "aanvragen" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.ACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/aanvragen/:instanceId/kandidaat/:candidateId/:tab",
      name: "KandidaatActies",
      component: () =>
        import(/* webpackChunkName: "aanvragen" */ "@/views/Kandidaat.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.KANDIDAAT,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/aanvragen/:instanceId/kandidaat/:candidateId",
      name: "Kandidaat",
      component: () =>
        import(/* webpackChunkName: "aanvragen" */ "@/views/Kandidaat.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.KANDIDAAT,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/aanvragen/:instanceId/actie/:actionId",
      name: "Actie",
      component: () =>
        import(/* webpackChunkName: "aanvragen" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.ACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/aanvragen/:instanceId/tab/:tab/bulkactie/:actionId",
      name: "Bulkactie",
      component: () =>
        import(/* webpackChunkName: "aanvragen" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.BULKACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/aanvragen/:instanceId/tab/:tab",
      name: "AanvraagTab",
      component: () =>
        import(/* webpackChunkName: "aanvragen" */ "@/views/Aanvraag.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.AANVRAAG,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/aanvragen/:instanceId",
      name: "Aanvraag",
      component: () =>
        import(/* webpackChunkName: "aanvragen" */ "@/views/Aanvraag.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.AANVRAAG,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/aanvragen",
      name: "Aanvragen",
      component: () =>
        import(/* webpackChunkName: "aanvragen" */ "@/views/Aanvragen.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.MIJN_AANVRAGEN,
        breadcrumb: Breadcrumbs.AANVRAGEN,
      },
    },
    /* [END]: Mijn Werving & Selectie  -> Mijn Aanvragen */

    /* [START]: Mijn Werving & Selectie  -> Mijn Acties */
    {
      path: "/dashboard/wervingselectie/acties/:instanceId/kandidaat/:candidateId/actie/:actionId",
      name: "ActiesAanvraagKandidaatActie",
      component: () =>
        import(/* webpackChunkName: "acties" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.ACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/acties/:instanceId/kandidaat/:candidateId/:tab",
      name: "ActiesAanvraagKandidaatActies",
      component: () =>
        import(/* webpackChunkName: "acties" */ "@/views/Kandidaat.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.KANDIDAAT,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/acties/:instanceId/kandidaat/:candidateId",
      name: "ActiesAanvraagKandidaat",
      component: () =>
        import(/* webpackChunkName: "acties" */ "@/views/Kandidaat.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.KANDIDAAT,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/acties/:instanceId/bulkactie/:actionId",
      name: "ActiesAanvraagBulkactie",
      component: () =>
        import(/* webpackChunkName: "acties" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.BULKACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/acties/:instanceId/actie/:actionId",
      name: "ActiesAanvraagActie",
      component: () =>
        import(/* webpackChunkName: "acties" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.ACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/acties/:instanceId/tab/:tab",
      name: "ActiesAanvraagTab",
      component: () =>
        import(/* webpackChunkName: "acties" */ "@/views/Aanvraag.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.AANVRAAG,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/acties/:instanceId",
      name: "ActiesAanvraag",
      component: () =>
        import(/* webpackChunkName: "acties" */ "@/views/Aanvraag.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.AANVRAAG,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/wervingselectie/acties",
      name: "Acties",
      component: () =>
        import(/* webpackChunkName: "acties" */ "@/views/Acties.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.MIJN_ACTIES,
        breadcrumb: Breadcrumbs.MIJN_ACTIES,
      },
    },
    /* [END]: Mijn Werving & Selectie  -> Mijn Acties */

    {
      path: "/dashboard/wervingselectie/nieuweaanvraag",
      name: "NieuweAanvraag",
      component: () =>
        import(
          /* webpackChunkName: "nieuweaanvraag" */ "@/views/NieuweAanvraag.vue"
        ),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.NIEUWE_AANVRAAG,
        breadcrumb: Breadcrumbs.NIEUWE_AANVRAAG,
      },
    },
    {
      path: "/dashboard/wervingselectie",
      name: "WervingSelectie",
      component: () =>
        import(
          /* webpackChunkName: "WervingSelectie" */ "@/views/WervingSelectie.vue"
        ),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.MIJN_WS,
        breadcrumb: Breadcrumbs.MIJN_WS,
      },
    },
    /* [END]: Mijn Werving & Selectie */

    /* [START]: Profiel */
    {
      path: "/dashboard/profiel/:instanceId/actie/:actionId/source/:source",
      name: "ProfielActie",
      component: () =>
        import(/* webpackChunkName: "profiel" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.PROFIEL_ACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/profiel/jobalert",
      name: "JobAlert",
      component: () =>
        import(/* webpackChunkName: "profiel" */ "@/views/JobAlert.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.JOB_ALERTS,
        breadcrumb: Breadcrumbs.JOB_ALERTS,
      },
    },
    {
      path: "/dashboard/profiel",
      name: "Profiel",
      component: () =>
        import(/* webpackChunkName: "profiel" */ "@/views/Profiel.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.MIJN_PROFIEL,
        breadcrumb: Breadcrumbs.MIJN_PROFIEL,
      },
    },
    {
      path: "/dashboard/profiel/vacatures",
      name: "Recommendations",
      component: () =>
        import(/* webpackChunkName: "profiel" */ "@/views/Recommendations.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.AANBEVOLEN_VACATURES,
        breadcrumb: Breadcrumbs.AANBEVOLEN_VACATURES,
      },
    },
    /* [END]: Profiel */

    /* [START]: Recruitment */
    {
      path: "/dashboard/recruitment/aanvragen/:instanceId/kandidaat/:candidateId/actie/:actionId",
      name: "RecruitmentKandidaatActie",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.RECRUITMENT_KANDIDAAT_ACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/recruitment/aanvragen/:instanceId/kandidaat/:candidateId/:tab",
      name: "RecruitmentKandidaatActies",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Kandidaat.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.RECRUITMENT_KANDIDAAT,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/recruitment/aanvragen/:instanceId/kandidaat/:candidateId",
      name: "RecruitmentKandidaat",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Kandidaat.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.RECRUITMENT_KANDIDAAT,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/recruitment/aanvragen/:instanceId/actie/:actionId",
      name: "RecruitmentAanvraagActie",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.RECRUITMENT_AANVRAAG_ACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/recruitment/aanvragen/:instanceId/tab/:tab/bulkactie/:actionId",
      name: "RecruitmentAanvraagBulkactie",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.RECRUITMENT_BULKACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/recruitment/aanvragen/:instanceId/tab/:tab",
      name: "RecruitmentAanvraagTab",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Aanvraag.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.RECRUITMENT_AANVRAAG,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/recruitment/aanvragen/:instanceId",
      name: "RecruitmentAanvraag",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Aanvraag.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.RECRUITMENT_AANVRAAG,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/recruitment/aanvragen",
      name: "RecruitmentAanvragen",
      component: () =>
        import(
          /* webpackChunkName: "recruitment" */ "@/views/RecruitmentAanvragen.vue"
        ),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.RECRUITMENT_AANVRAGEN,
        breadcrumb: Breadcrumbs.AANVRAGEN,
      },
    },
    {
      path: "/dashboard/recruitment/nieuwelossevacature",
      name: "NieuweLosseVacature",
      component: () =>
        import(
          /* webpackChunkName: "recruitment" */ "@/views/NieuweAanvraag.vue"
        ),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.NIEUWE_LOSSE_VACATURE,
        breadcrumb: Breadcrumbs.NIEUWE_LOSSE_VACATURE,
      },
    },
    {
      path: "/dashboard/recruitment/lossevacatures/:instanceId/actie/:actionId",
      name: "LosseVacatureActie",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LOSSE_VACATURE_ACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/recruitment/lossevacatures/:instanceId/tab/:tab",
      name: "LosseVacatureTab",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Aanvraag.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LOSSE_VACATURE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/recruitment/lossevacatures/:instanceId",
      name: "LosseVacature",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Aanvraag.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LOSSE_VACATURE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/recruitment/lossevacatures",
      name: "LosseVacatures",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Aanvragen.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LOSSE_VACATURES,
        breadcrumb: Breadcrumbs.LOSSE_VACATURES,
      },
    },
    {
      path: "/dashboard/recruitment",
      name: "Recruitment",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "@/views/Recruitment.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.MIJN_RECRUITMENT,
        breadcrumb: Breadcrumbs.MIJN_RECRUITMENT,
      },
    },
    /* [END]: Recruitment */

    /* [START]: Loopbaanadvies */
    {
      path: "/dashboard/loopbaanadvies/vacatures/:instanceId/kandidaat/:candidateId/actie/:actionId",
      name: "LBAVacatureKandidaatActie",
      component: () =>
        import(/* webpackChunkName: "vacatures" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LBA_KANDIDAAT_ACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/loopbaanadvies/vacatures/:instanceId/kandidaat/:candidateId/:tab",
      name: "LBAVacatureKandidaatActies",
      component: () =>
        import(/* webpackChunkName: "vacatures" */ "@/views/Kandidaat.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LBA_KANDIDAAT,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/loopbaanadvies/vacatures/:instanceId/kandidaat/:candidateId",
      name: "LBAVacatureKandidaat",
      component: () =>
        import(/* webpackChunkName: "vacatures" */ "@/views/Kandidaat.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LBA_KANDIDAAT,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/loopbaanadvies/vacatures/:instanceId/actie/:actionId",
      name: "LBAVacatureActie",
      component: () =>
        import(/* webpackChunkName: "vacatures" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LBA_ACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/loopbaanadvies/vacatures/:instanceId/tab/:tab/bulkactie/:actionId",
      name: "LBAVacatureBulkactie",
      component: () =>
        import(/* webpackChunkName: "vacatures" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LBA_BULKACTIE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/loopbaanadvies/vacatures/:instanceId/tab/:tab",
      name: "LBAVacatureTab",
      component: () =>
        import(/* webpackChunkName: "vacatures" */ "@/views/Aanvraag.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LBA_VACATURE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/loopbaanadvies/vacatures/:instanceId",
      name: "LBAVacature",
      component: () =>
        import(/* webpackChunkName: "vacatures" */ "@/views/Aanvraag.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LBA_VACATURE,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/loopbaanadvies/vacatures",
      name: "Vacatures",
      component: () =>
        import(
          /* webpackChunkName: "vacatures" */ "@/views/LoopbaanadviesVacatures.vue"
        ),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LBA_VACATURES,
        breadcrumb: Breadcrumbs.VACATURES,
      },
    },
    {
      path: "/dashboard/loopbaanadvies/profielen/:instanceId/actie/:actionId",
      name: "LBAProfielActie",
      component: () =>
        import(/* webpackChunkName: "profielen" */ "@/views/Actie.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.PROFIEL_ACTIE_LBA,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/loopbaanadvies/profielen/:instanceId",
      name: "LBAProfiel",
      component: () =>
        import(/* webpackChunkName: "profielen" */ "@/views/Profiel.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LBA_PROFIEL,
        breadcrumb: Breadcrumbs.DYNAMIC,
      },
    },
    {
      path: "/dashboard/loopbaanadvies/profielen",
      name: "Profielen",
      component: () =>
        import(
          /* webpackChunkName: "profielen" */ "@/views/LoopbaanadviesProfielen.vue"
        ),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.LBA_PROFIELEN,
        breadcrumb: Breadcrumbs.PROFIELEN,
      },
    },
    {
      path: "/dashboard/loopbaanadvies",
      name: "Loopbaanadvies",
      component: () =>
        import(
          /* webpackChunkName: "loopbaanadvies" */ "@/views/Loopbaanadvies.vue"
        ),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.MIJN_LBA,
        breadcrumb: Breadcrumbs.MIJN_LBA,
      },
    },
    /* [END]: Loopbaanadvies */

    {
      path: "/dashboard/database",
      name: "Database",
      component: () =>
        import(/* webpackChunkName: "loopbaanadvies" */ "@/views/Matchbox.vue"),
      beforeEnter: userGuard,
      meta: {
        title: RoutesTitles.DATABASE,
        breadcrumb: Breadcrumbs.DATABASE,
      },
    },

    /* [START]: Error */
    {
      path: "/:code",
      name: "Error",
      component: () =>
        import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
      meta: { title: "Fout" },
    },
    {
      path: "*",
      redirect: "/404",
    },
    /* [END]: Error */
  ],
});
