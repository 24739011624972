import axios from "axios";
import authentication from "@/authentication";
import {
  validateApiOkResponse,
  validateApiSearchResponse,
} from "@/utils/ApiResponseValidator";
import IdUtils from "@/utils/IdUtils";
import { ServiceException } from "@/utils/ErrorHandler";

const INSTANCE_IDENTIFICATION = "?instance_identification=";
const ACTIVITY_INPUT_DATA = "/activity/inputdata";
const ACTIVITY_DEFINITIONS = "/activity-definitions";
const GENERIC_INSTANCES = "/generic/instances";
const CONCEPT_RESOURCE = "/generic/instance-concepts";
const FINALISE_CONCEPT = "/finalise";
const INSTANCES = "/instances";
const CONTEXT = "/context";
const STATUS = "/status";
const FLOW = "/flow";
const V2 = "/v2/";

export class GenericInstanceServices {
  /**
   * Constructs axios
   * @param organisationId: The unique identification of the organisation
   */
  constructor(organisationId) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisationId) {
      this.setOrganisation(organisationId);
    }
  }

  /**
   * Sets the organisation identification
   * @param organisationId: The unique identification of the organisation
   */
  setOrganisation(organisationId) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisationId;
  }

  /**
   * Gets the instance data using provided instance ID
   * @url {base}/generic/instances/{identification}
   * @param identificationId: The unique identification of the instance.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getGenericInstance(identificationId) {
    return this.OMNIMAP.get(GENERIC_INSTANCES + "/" + identificationId, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiSearchResponse(response));
  }

  /**
   * Creates a new instance
   * @url {base}/generic/instances
   * @param newInstanceData
   * @returns {Promise<AxiosResponse<any>>}
   */
  async postGenericInstance(newInstanceData) {
    return this.OMNIMAP.post(GENERIC_INSTANCES, newInstanceData, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  async createConcept(concept) {
    return this.OMNIMAP.post(CONCEPT_RESOURCE + V2, concept, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async finaliseConcept(conceptId) {
    return this.OMNIMAP.post(
      CONCEPT_RESOURCE + "/" + conceptId + FINALISE_CONCEPT,
      {},
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Updates an instance identified with provided instance ID
   * @url {base}/generic/instances/{identification}
   * @param identificationId: The unique identification of the instance.
   * @param updatedInstanceData
   * @returns {Promise<AxiosResponse<any>>}
   */
  async patchGenericInstance(updatedInstanceData) {
    return this.OMNIMAP.patch(
      GENERIC_INSTANCES + "/" + updatedInstanceData.content.identification,
      updatedInstanceData.content,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => {
      validateApiOkResponse(response);
    });
  }

  /**
   * Sets the status of a given instance
   * @param identificationId
   * @param input
   * @returns {Promise<*>}
   */
  async setStatus(identificationId, input) {
    return this.OMNIMAP.post(
      GENERIC_INSTANCES + "/" + identificationId + STATUS,
      input,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Retrieves the list of contexts from the given instance.
   * @param instanceIdentification
   * @param pageNumber
   * @returns {Promise<*>}
   */
  async getInstanceContextPage(instanceIdentification, pageNumber) {
    if (!IdUtils.isUUID(instanceIdentification)) {
      throw new ServiceException(
        "Invalid instance identification to retrieve context"
      );
    }

    return this.OMNIMAP.get(
      GENERIC_INSTANCES +
        V2 +
        instanceIdentification +
        CONTEXT +
        "?page-size=" +
        process.env.VUE_APP_CONTEXT_PAGE_SIZE +
        "&page-number=" +
        pageNumber,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Gets the context of a given instance
   * @param instanceIdentification
   * @returns {Promise<*[]>}
   */
  async getInstanceContext(instanceIdentification) {
    let result = [];
    let meta = { current_page: 0 };

    do {
      meta.current_page += 1;
      const response = await this.getInstanceContextPage(
        instanceIdentification,
        meta.current_page
      );
      meta = response.meta;
      if (response && response.data) {
        result = result.concat(response.data);
      }
    } while (meta.total_pages && meta.total_pages > meta.current_page);

    return result;
  }

  /**
   * Get the schema of an activity
   * @param activityDefinitionId
   * @returns {Promise<void>}
   */
  async getActivitySchema(instanceId, activityDefinitionId) {
    return this.OMNIMAP.get(
      "/instances/" +
        instanceId +
        FLOW +
        ACTIVITY_DEFINITIONS +
        "/" +
        activityDefinitionId,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Gets bulk actions available for supplied instances
   * @param instanceIds (identifications of instances on which you want to perform the bulk action)
   * @returns {Promise<void>}
   */
  async getBulkActions(instanceIds) {
    return this.OMNIMAP.get(
      INSTANCES + FLOW + INSTANCE_IDENTIFICATION + instanceIds.join(","),
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Prepares a bulk action for the execution
   * @param stageId (action.definition.identification)
   * @param instanceIds (identifications of instances on which you perform the bulk action)
   * @param body (JSONData of the action)
   * @returns {Promise<*>}
   */
  async prepareBulkAction(stageId, instanceIds, body) {
    return this.OMNIMAP.post(
      INSTANCES +
        FLOW +
        "/" +
        stageId +
        ACTIVITY_INPUT_DATA +
        INSTANCE_IDENTIFICATION +
        instanceIds.join(","),
      body,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Executes a bulk action
   * @param stageId
   * @param activityType
   * @param instanceIds
   * @param body
   * @returns {Promise<*>}
   */
  async executeBulkAction(stageId, activityType, instanceIds, body) {
    return this.OMNIMAP.post(
      INSTANCES +
        FLOW +
        "/" +
        stageId +
        "/" +
        activityType +
        INSTANCE_IDENTIFICATION +
        instanceIds.join(","),
      body,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }
}
