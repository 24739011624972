import Vue from "vue";
import { SearchServices } from "@/services/SearchServices";
import { StoreException } from "@/utils/ErrorHandler";
import { decideAction } from "@/utils/Utils/CapacityRequestUtils";
import { Identifications } from "@/utils/GeneralConstants";
import { MyRequestsTabs } from "@/utils/GeneralConst/CapacityRequest";
import { MyRequestsQueries } from "@/utils/GeneralConst/Queries";

const state = {
  searchServices: null,
  loading: false,
  timeout: null,
  pagination: {
    page_size: 10,
    page_number: 1,
  },
  query: {},

  myRequests: {},
  lastRetrieved: null,
  meta: null,
  metaFiltered: null,
  totalActions: 0,

  isTypeLosse: undefined,
  losseVacatures: null,
  metaLosseVacatures: null,
};

const getters = {
  searchServices: (state) => state.searchServices,
  loading: (state) => state.loading,
  timeout: (state) => state.timeout,
  pagination: (state) => state.pagination,
  pageSize: (state) => state.pagination.page_size,
  pageNumber: (state) => state.pagination.page_number,
  query: (state) => state.query,

  myRequests: (state) => state.myRequests,
  lastRetrieved: (state) => state.lastRetrieved,
  meta: (state) => state.meta,
  metaFiltered: (state) => state.metaFiltered,
  totalActions: (state) => (state.totalActions ? state.totalActions : 0),

  isTypeLosse: (state) => state.isTypeLosse,
  losseVacatures: (state) => state.losseVacatures,
  metaLosseVacatures: (state) => state.metaLosseVacatures,
};

const actions = {
  /**
   * Internal action for initializing and returning the SearchServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.searchServices|(function(*): (null|getters.searchServices|(function(*))))|null>}
   */
  getSearchServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.searchServices) {
        commit(
          "SET_SEARCH_SERVICES",
          new SearchServices(rootGetters.organisationId)
        );
      }

      return getters.searchServices;
    } catch (error) {
      throw new StoreException("getSearchServices: " + error.message);
    }
  },

  /**
   * Sets the type of request (is in an individual request – "losse vacancy"?)
   * @param commit
   * @param isLosseVacature
   */
  setTypeOfRequest: ({ commit }, isLosseVacature) => {
    commit("SET_TYPE_REQUEST", isLosseVacature);
  },

  /**
   * Triggers a function to retrieve correct type of capacity requests (regular or "losse")
   * @param getters
   * @param dispatch
   * @returns {Promise<void>}
   */
  updateRequests: async ({ getters, dispatch }) => {
    if (getters.isTypeLosse) {
      await dispatch("retrieveLosseVacatures");
    } else {
      await dispatch("retrieveMyRequests", true);
    }
  },

  /**
   * Resets the values of page number and all elements of the query
   * @param commit
   * @param dispatch
   */
  resetPageAndQuery: ({ commit, dispatch }) => {
    commit("SET_PAGE_NUMBER", 1);
    dispatch("setQuery");
  },

  /**
   * Sets the page number selected by the user and retrieves correct data to show
   * @param commit
   * @param dispatch
   * @param pageNumber
   * @returns {Promise<void>}
   */
  setPage: async ({ commit, dispatch }, pageNumber) => {
    try {
      commit("SET_PAGE_NUMBER", pageNumber);

      await dispatch("updateRequests", true);
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Sets the sorting settings based on the user's choice
   * @param commit
   * @param dispatch
   * @param direction
   * @returns {Promise<void>}
   */
  setSorting: async ({ commit, dispatch }, direction) => {
    try {
      commit("SET_QUERY_SORTING", [
        { sort_type: "timestamp_created", direction: direction },
      ]);

      await dispatch("updateRequests", true);
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Sets the text search based on the user's text search input
   * @param commit
   * @param text
   * @returns {Promise<void>}
   */
  setTextSearch: async ({ commit }, text) => {
    try {
      if (text) {
        const textQuery = {
          text_query: text,
          search_fields: ["NAME", "CONTENT", "IDENTIFIER", "STATUS"],
          fuzzy: true,
          and_or: "OR",
        };

        commit("SET_QUERY_TEXT_SEARCH", [textQuery]);
      } else {
        commit("SET_QUERY_TEXT_SEARCH", null);
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Sets the status of the data items selected by the user
   * @param commit
   * @param dispatch
   * @param status
   * @returns {Promise<void>}
   */
  setStatus: async ({ commit, dispatch }, status) => {
    try {
      const statusList = [];
      if (status?.length) {
        status.forEach((item) => {
          statusList.push({
            status: item,
            and_or: "OR",
          });
        });
      }
      commit("SET_QUERY_STATUSES", statusList);

      await dispatch("updateRequests", true);
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Retrieves actions for each capacity request
   * @param getters
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  retrieveAllActions: async ({ getters, dispatch, commit }) => {
    // Clear the old value
    commit("SET_AMOUNT_ACTIONS", 0);
    try {
      if (!getters.isTypeLosse) {
        for (const identification in getters.myRequests) {
          if (getters.myRequests.hasOwnProperty(identification)) {
            await dispatch("retrieveActionsForRequest", identification);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Retrieves actions for a specific capacity request determined by ID
   * @param getters
   * @param dispatch
   * @param commit
   * @param id
   * @returns {Promise<void>}
   */
  retrieveActionsForRequest: async ({ getters, dispatch, commit }, id) => {
    try {
      const request = await decideAction(getters.myRequests[id]);

      if (!request?.actions?.actions) {
        // Retrieve actions of the instance
        const [all, special, other] = await dispatch(
          "instanceFlow/getActions",
          id,
          { root: true }
        );

        request.actions = {
          flow: all,
          specialActions: special,
          actions: other,
        };
      }

      const hasActions = { id: id, hasActions: request.actions.actions.length };

      if (getters.myRequests[id]) {
        commit("SET_REQUEST_ACTIONS", { id: id, payload: request.actions });
        commit("SET_HAS_ACTIONS", hasActions);
      }
      if (request.actions.actions.length) {
        // Amount of actions is the number of instances where actions are required
        commit("SET_AMOUNT_ACTIONS", getters.totalActions + 1);
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Retrieves the requests related to the logged user and starts a timer to retrieve them every 15000ms for a maximum of 2.5 minutes.
   * @param getters
   * @param commit
   * @param dispatch
   * @returns {Promise<void>}
   */
  init: async ({ getters, commit, dispatch }) => {
    await dispatch("retrieveMyRequests");

    if (!getters.timeout) {
      for (let i = 0; i < 10; i++) {
        await new Promise((resolve) => {
          const timeout = setTimeout(resolve, 15000);
          commit("SET_TIME_OUT", timeout);
        });

        await dispatch("retrieveMyRequests");
      }

      commit("SET_TIME_OUT", null);
    }
  },

  /**
   * Retrieves the amount of capacity requests related to the logged user
   * @param rootGetters
   * @param dispatch
   * @param getters
   * @param commit
   * @returns {Promise<void>}
   */
  retrieveAmountOfRequests: async ({ rootGetters, dispatch, commit }) => {
    try {
      const searchServices = await dispatch("getSearchServices");
      const query = { ...MyRequestsQueries.MANAGER };

      query.context.instance_identifiers = [rootGetters.userId];

      const { meta } = await searchServices.postInstanceQuery(1, 1, query);

      commit("SET_AMOUNT_REQUESTS", meta);
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Retrieves capacity requests based on selected query
   * It only fetches them if: forceUpdate is true, they have not been updated in 15 minutes, or the amount of instances has updated.
   * @param getters
   * @param dispatch
   * @param commit
   * @param forceUpdate
   * @returns {Promise<void>}
   */
  retrieveMyRequests: async (
    { getters, dispatch, commit },
    forceUpdate = false
  ) => {
    try {
      // Copy the meta for comparing later on
      const metaBefore = { ...getters.meta };

      // Update the meta data to determine if new instances have been added
      await dispatch("retrieveAmountOfRequests");

      // Only do function when forceUpdate is set, after a 15 min have passed or if the amount of instances has changed
      if (
        !forceUpdate &&
        getters.lastRetrieved &&
        getters.lastRetrieved.getTime() + 900000 > new Date().getTime() &&
        getters.myRequests &&
        getters.meta.total_found === metaBefore.total_found &&
        getters.pagination.page_number === getters.metaFiltered.current_page
      ) {
        commit("SET_LOADING", false);
        return;
      } else {
        commit("SET_LOADING", true);
      }

      const searchServices = await dispatch("getSearchServices");

      // Retrieve the instances based on the active query and page
      const { data, meta } = await searchServices.postInstanceQuery(
        getters.pageSize,
        getters.pageNumber,
        getters.query
      );

      // If there are instances, update the state accordingly. If no instances found, clear the store.
      if (data) {
        const mapped = data.map((item) => ({ [item.identification]: item }));
        const mappedData = Object.assign({}, ...mapped);
        commit("SET_MY_REQUESTS", mappedData);
      } else {
        commit("SET_MY_REQUESTS", {});
      }

      // Update the meta data of filtered requests
      commit("SET_AMOUNT_FILTERED_REQUESTS", meta);

      // Update timestamp to validate if 15 minutes have passed.
      commit("SET_LAST_RETRIEVED", new Date());

      // Retrieve actions and chat for each instance
      await dispatch("retrieveAllActions");
      await dispatch("retrieveAllChats");
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  /**
   * Sets the query based on the active tab (each tab in My Requests view corresponds with a specific query)
   * @param commit
   * @param activeTab
   * @returns {Promise<void>}
   */
  setQuery: async ({ commit }, activeTab) => {
    // Set the query depending on the active tab
    let query = {};
    switch (activeTab) {
      case MyRequestsTabs.LOPENDE:
        query = { ...MyRequestsQueries.LOPENDE };
        break;
      case MyRequestsTabs.ALLE:
        query = { ...MyRequestsQueries.ALLE };
        break;
      case MyRequestsTabs.VERLENGINGEN:
        query = { ...MyRequestsQueries.VERLENGINGEN };
        break;
      default:
        query = { ...MyRequestsQueries.ALLE };
        query.content = [];
        break;
    }
    commit("SET_QUERY", query);
  },

  /**
   * Retrieves chat messages for all instances
   * @param getters
   * @param dispatch
   * @returns {Promise<void>}
   */
  retrieveAllChats: async ({ getters, dispatch }) => {
    try {
      for (const identification in getters.myRequests) {
        if (getters.myRequests.hasOwnProperty(identification)) {
          await dispatch("retrieveChatForRequest", identification);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Retrieves chat messages for a specific instance specified with ID
   * @param getters
   * @param dispatch
   * @param commit
   * @param identification
   * @returns {Promise<void>}
   */
  retrieveChatForRequest: async (
    { getters, dispatch, commit },
    identification
  ) => {
    try {
      // Retrieves chat messages of the instance
      const result = await dispatch("chat/retrieveChat", identification, {
        root: true,
      });

      // If instance is in the state (required because the state may have changed in the mean-time
      if (getters.myRequests[identification]) {
        commit("SET_REQUEST_CHAT", {
          id: identification,
          payload: result,
        });

        // Set boolean to make UI faster
        commit("SET_HAS_CHAT", {
          id: identification,
          hasChat: !!(result && result.length),
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Retrieves all individual vacancies
   * @param getters
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  retrieveLosseVacatures: async ({ getters, dispatch, commit }) => {
    const searchServices = await dispatch("getSearchServices");
    const query = { ...getters.query };
    query.definitions = [Identifications.LOSSE_VACATURE];

    // If there is text search present in the query, update the sorting to sort on the text_score
    if (
      Array.isArray(getters.query.text_search) &&
      getters.query.text_search.length > 0
    ) {
      query.sorting = {
        type: "text_score",
        direction: "DESCENDING",
      };
    }

    // Retrieve the instances based on the active query and page
    const { data, meta } = await searchServices.postInstanceQuery(
      getters.pageSize,
      getters.pageNumber,
      query
    );

    // If there are instances, update the state accordingly. If no instances found, clear the store.
    if (data) {
      const mapped = data.map((item) => ({ [item.identification]: item }));
      const mappedData = Object.assign({}, ...mapped);
      commit("SET_LOSSE_VACATURES", mappedData);
    } else {
      commit("SET_LOSSE_VACATURES", {});
    }

    // Update the meta data of losse vacatures
    commit("SET_META_LOSSE_VACATURES", meta);

    // Retrieve actions for each instance
    await dispatch("retrieveAllActions");
  },
};

const mutations = {
  SET_SEARCH_SERVICES: (state, payload) =>
    Vue.set(state, "searchServices", payload),
  SET_LOADING: (state, payload) => Vue.set(state, "loading", payload),
  SET_TIME_OUT: (state, payload) => Vue.set(state, "timeout", payload),

  SET_PAGE_NUMBER: (state, payload) =>
    Vue.set(state.pagination, "page_number", payload),
  SET_QUERY: (state, payload) => Vue.set(state, "query", payload),
  SET_QUERY_SORTING: (state, payload) =>
    Vue.set(state.query, "sorting", payload),
  SET_QUERY_TEXT_SEARCH: (state, payload) =>
    Vue.set(state.query, "text_search", payload),
  SET_QUERY_STATUSES: (state, payload) =>
    Vue.set(state.query, "statuses", payload),

  SET_REQUEST_ACTIONS: (state, { id, payload }) =>
    Vue.set(state.myRequests[id], "actions", payload),
  SET_HAS_ACTIONS: (state, { id, hasActions }) =>
    Vue.set(state.myRequests[id], "hasActions", hasActions),

  SET_MY_REQUESTS: (state, payload) => Vue.set(state, "myRequests", payload),
  SET_AMOUNT_REQUESTS: (state, payload) => Vue.set(state, "meta", payload),
  SET_AMOUNT_FILTERED_REQUESTS: (state, payload) =>
    Vue.set(state, "metaFiltered", payload),
  SET_REQUEST_CHAT: (state, { id, payload }) =>
    Vue.set(state.myRequests[id], "chat", payload),
  SET_HAS_CHAT: (state, { id, hasChat }) =>
    Vue.set(state.myRequests[id], "hasChat", hasChat),
  SET_AMOUNT_ACTIONS: (state, payload) =>
    Vue.set(state, "totalActions", payload),
  SET_LAST_RETRIEVED: (state, payload) =>
    Vue.set(state, "lastRetrieved", payload),

  SET_LOSSE_VACATURES: (state, payload) =>
    Vue.set(state, "losseVacatures", payload),
  SET_META_LOSSE_VACATURES: (state, payload) =>
    Vue.set(state, "metaLosseVacatures", payload),
  SET_TYPE_REQUEST: (state, payload) => Vue.set(state, "isTypeLosse", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
