class Exception extends Error {
  constructor(type, error) {
    super(error);
    this.name = type;
  }

  get toString() {
    const errMessage = this.name + " - " + this.message;
    return () => errMessage;
  }
}

export class ServiceException extends Exception {
  constructor(error) {
    super("ServiceException", error);
  }
}

export class StoreException extends Exception {
  constructor(error) {
    super("StoreException", error);
  }
}

export class UtilException extends Exception {
  constructor(error) {
    super("UtilException", error);
  }
}

export class ClassException extends Exception {
  constructor(error) {
    super("ClassException", error);
  }
}
