import Vue from "vue";
import { MappingExecutionServices } from "@/services/MappingExecutionServices";
import { StoreException } from "@/utils/ErrorHandler";
import IdUtils from "@/utils/IdUtils";

const state = {
  mappingExecutionServices: null,
  isExecutingMapping: false,
};

const getters = {
  mappingExecutionServices: (state) => state.mappingExecutionServices,
  getIsExecutingMapping: (state) => state.isExecutingMapping,
};

const actions = {
  /**
   * Internal action for initializing and returning the mappingExecutionServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @returns {any}
   */
  getMappingExecutionServices: async ({ getters, rootGetters, commit }) => {
    if (null === getters.mappingExecutionServices) {
      if ("" === rootGetters.tenantId) {
        throw new StoreException("No tenant id known.");
      } else {
        commit(
          "SET_MAPPING_EXECUTION_SERVICES",
          new MappingExecutionServices(
            rootGetters.tenantId,
            rootGetters.organisationId
          )
        );
      }
    }

    return getters.mappingExecutionServices;
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise, the organisation id will be set on creation.
   * @param getters
   * @param rootGetters
   */
  updateMappingExecutionServices: ({ getters, rootGetters }) => {
    const mappingExecutionServices = getters.mappingExecutionServices;
    if (mappingExecutionServices) {
      mappingExecutionServices.setOrganisation(rootGetters.organisationId);
    }
  },

  async postMappingCalculation(
    { dispatch },
    { mapping_identification, mappingData }
  ) {
    if (!IdUtils.isUUID(mapping_identification) || !mappingData) {
      throw new StoreException("Invalid input for post mapping calculation");
    }
    dispatch("setIsExecutingMapping", true);
    try {
      const mappingExecutionServices = await dispatch(
        "getMappingExecutionServices"
      );
      const response = await mappingExecutionServices.getOutputDataUsingPost(
        mapping_identification,
        mappingData
      );
      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      throw new StoreException(
        "Something went wrong while trying to post the mapping execution: \n" +
          error.message
      );
    }
  },

  setIsExecutingMapping({ commit }, payload) {
    commit("SET_IS_EXECUTING_MAPPING", payload);
  },
};

const mutations = {
  SET_MAPPING_EXECUTION_SERVICES: (state, payload) =>
    Vue.set(state, "mappingExecutionServices", payload),
  SET_IS_EXECUTING_MAPPING: (state, payload) =>
    Vue.set(state, "isExecutingMapping", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
