export default {
  generateUUID: function b(a) {
    return a
      ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
      : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
  },

  isUUID: function (uuid) {
    if (uuid) {
      return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
        uuid
      );
    } else {
      return false;
    }
  },

  getUUIDFromLink: function (link) {
    if (link && typeof link === "string") {
      let startIndexOfId = link.lastIndexOf("/");
      startIndexOfId++;
      return link.slice(startIndexOfId);
    } else {
      return null;
    }
  },
};
