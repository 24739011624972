import Vue from "vue";
import "babel-polyfill";

/* Internationalization */
import { i18n } from "./plugins/i18n";

/* UI Framework */
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(Element, { i18n: (key, value) => i18n.t(key, value) });

import VueMq from "vue-mq";
Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    sm: 575,
    md: 1024,
    lg: Infinity,
  },
  defaultBreakpoint: "sm", // customize this for SSR
});

// Fabric UI
import "office-ui-fabric-core/dist/css/fabric.min.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import OmniComponents from "@omnimap2/ui-components";
import "@omnimap2/ui-components/dist/style.css";
Vue.use(OmniComponents, { store, locale: i18n.locale });

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
