export const AllUserRoles = Object.freeze({
  ADMIN: "5262039e-be6a-4d64-a8a7-1d8bc37048b0",
  ADMINISTRATOR: "d754ed82-fb46-4dca-a6fc-3e1b7665361e",
  BACKOFFICE_MEDEWERKER: "8861bf51-eafe-443d-bb46-ca41242364b2",
  LOOPBAAN_ADVISEUR: "9f3f1eb2-694d-4a0f-bfed-409c32643b33",
  MANAGER: "488b7325-7b92-4fde-920a-a6c008a7bd73",
  RECRUITER: "8ca64ae6-bcb1-40b0-9f5d-78750af175b0",
  STANDAARD_GEBRUIKER: "c6f2ad11-4dc6-49c3-bb70-9719f2f6a243",
});

// Roles allowed to see "Mijn recruitment" tile in the Dashboard
export const AccessMijnRecruitment = Object.freeze([
  AllUserRoles.ADMIN,
  AllUserRoles.ADMINISTRATOR,
  AllUserRoles.BACKOFFICE_MEDEWERKER,
  AllUserRoles.RECRUITER,
]);

// Roles allowed to see "Mijn werving en selectie" tile in the Dashboard
export const AccessMijnWervingEnSelectie = Object.freeze([
  AllUserRoles.ADMIN,
  AllUserRoles.ADMINISTRATOR,
  AllUserRoles.MANAGER,
  AllUserRoles.LOOPBAAN_ADVISEUR,
  AllUserRoles.BACKOFFICE_MEDEWERKER,
  AllUserRoles.STANDAARD_GEBRUIKER,
]);

// Roles allowed to see "Mijn profile" tile in the Dashboard
export const AccessMijnProfiel = AllUserRoles;

// Roles allowed to see "Mijn loopbaanadvies" tile in the Dashboard
export const AccessMijnLoopbaanadvies = Object.freeze([
  AllUserRoles.ADMIN,
  AllUserRoles.ADMINISTRATOR,
  AllUserRoles.LOOPBAAN_ADVISEUR,
]);

// Roles allowed to see "Mijn sollicitaties" tile in the Dashboard
export const AccessMijnSollicitaties = AllUserRoles;

// Roles allowed to see "Database Profielen" tile in the Dashboard
export const AccessDatabaseProfielen = Object.freeze([
  AllUserRoles.ADMIN,
  AllUserRoles.ADMINISTRATOR,
  AllUserRoles.BACKOFFICE_MEDEWERKER,
  AllUserRoles.RECRUITER,
  AllUserRoles.LOOPBAAN_ADVISEUR,
]);

// Roles allowed to see "Mijn talenten" item in the Header menu
export const AccessMijnTalenten = Object.freeze([
  AllUserRoles.ADMIN,
  AllUserRoles.ADMINISTRATOR,
  AllUserRoles.LOOPBAAN_ADVISEUR,
  AllUserRoles.MANAGER,
]);

// Roles allowed to see "Mijn acties" item in the Header menu
export const AccessMijnActies = Object.freeze([
  AllUserRoles.ADMIN,
  AllUserRoles.ADMINISTRATOR,
  AllUserRoles.LOOPBAAN_ADVISEUR,
  AllUserRoles.RECRUITER,
]);
