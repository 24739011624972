import Vue from "vue";
import { StoreException } from "@/utils/ErrorHandler";
import { GenericInstanceServices } from "@/services/GenericInstanceServices";
import IdUtils from "@/utils/IdUtils";

const state = {
  genericInstanceServices: null,
  contextServices: null,
};

const getters = {
  genericInstanceServices: (state) => state.genericInstanceServices,
  contextServices: (state) => state.contextServices,
};

const actions = {
  /**
   * Internal action for initializing and returning the GenericInstanceServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.tenantServices|(function(*): (null|getters.tenantServices|(function(*))))|null>}
   */
  getGenericInstanceServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.genericInstanceServices) {
        commit(
          "SET_GENERIC_INSTANCE_SERVICES",
          new GenericInstanceServices(rootGetters.organisationId)
        );
      }

      return getters.genericInstanceServices;
    } catch (error) {
      throw new StoreException("getGenericInstanceServices: " + error.message);
    }
  },

  /**
   * Fetches an instance based on the identification
   * @param dispatch
   * @param instanceId
   * @returns {Promise<*>}
   */
  getGenericInstance: async ({ dispatch }, instanceId) => {
    // Check if the provided instance ID is valid
    if (!IdUtils.isUUID(instanceId)) {
      console.error(
        "No valid instance identification found. Unable to retrieve instance."
      );
      return;
    }

    try {
      const genericInstanceServices = await dispatch(
        "getGenericInstanceServices"
      );
      return await genericInstanceServices.getGenericInstance(instanceId);
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Posts a new instance
   * @param dispatch
   * @param newInstanceData
   * @returns {Promise<*>}
   */
  postGenericInstance: async ({ dispatch }, newInstanceData) => {
    try {
      const genericInstanceServices = await dispatch(
        "getGenericInstanceServices"
      );
      return await genericInstanceServices.postGenericInstance(newInstanceData);
    } catch (error) {
      console.error(error);
    }
  },

  async createConcept({ dispatch }, concept) {
    if (!concept || !concept.hasOwnProperty("relationships")) {
      throw new StoreException("Invalid input");
    }
    try {
      const genericInstanceServices = await dispatch(
        "getGenericInstanceServices"
      );
      return await genericInstanceServices.createConcept(concept);
    } catch (error) {
      throw new StoreException(error.message);
    }
  },

  async finaliseConcept({ dispatch }, conceptId) {
    if (!conceptId || !IdUtils.isUUID(conceptId)) {
      throw new StoreException("Invalid input");
    }

    try {
      const getGenericInstanceServices = await dispatch(
        "getGenericInstanceServices"
      );
      await getGenericInstanceServices.finaliseConcept(conceptId);
    } catch (error) {
      throw new StoreException(error.message);
    }
  },

  /**
   * Patches (updates) an instance – function could be improved
   * @param dispatch
   * @param newInstanceData
   * @returns {Promise<*>}
   */
  patchGenericInstance: async ({ dispatch }, newInstanceData) => {
    try {
      const genericInstanceServices = await dispatch(
        "getGenericInstanceServices"
      );
      return await genericInstanceServices.patchGenericInstance(
        newInstanceData
      );
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Sets a status of an instance
   * @param dispatch
   * @param input
   * @returns {Promise<*>}
   */
  setStatus: async ({ dispatch }, instanceData) => {
    try {
      const genericInstanceServices = await dispatch(
        "getGenericInstanceServices"
      );
      const { identification } = instanceData;
      if (!IdUtils.isUUID(identification)) {
        console.error(
          "No valid instance identification found. Unable to proceed."
        );
        return;
      }

      delete instanceData.identification;

      return await genericInstanceServices.setStatus(
        identification,
        instanceData
      );
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Gets the context of an instance
   * @param dispatch
   * @param instanceId
   * @returns {Promise<*>}
   */
  getInstanceContext: async ({ dispatch }, instanceId) => {
    if (IdUtils.isUUID(instanceId)) {
      try {
        const contextServices = await dispatch("getGenericInstanceServices");

        return await contextServices.getInstanceContext(instanceId);
      } catch (e) {
        console.error(e);
      }
    } else {
      console.error(
        "No valid instance identification found. Unable to retrieve the context."
      );
    }
  },
};

const mutations = {
  SET_GENERIC_INSTANCE_SERVICES: (state, payload) =>
    Vue.set(state, "genericInstanceServices", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
