import { dateFormatOptions } from "@/utils/GeneralConstants";
const roepnaam = "algemeen-roepnaam";
const achternaam = "algemeen-achternaam";
export const setFullNameWithDot = function (firstName, lastName) {
  let fullName;
  if (firstName && lastName) {
    fullName = firstName[0] + ". " + lastName;
  } else if (!firstName && lastName) {
    fullName = lastName;
  } else if (firstName && !lastName) {
    fullName = firstName[0];
  } else {
    fullName = "Talent profiel";
  }
  return fullName;
};
export const setFullName = function (firstName, lastName) {
  let fullName;
  if (firstName && lastName) {
    fullName = firstName + " " + lastName;
  } else if (!firstName && lastName) {
    fullName = lastName;
  } else if (firstName && !lastName) {
    fullName = firstName;
  } else {
    fullName = "Talent profiel";
  }
  return fullName;
};
export const setTableFormatForLBAProfiles = function (array) {
  // Check if submitted data has a format of the array.
  if (!Array.isArray(array)) {
    console.error(
      "The function requires you to supply an array. Try again and be mindful this time."
    );
  } else {
    return array.map((el) => {
      const newEl = {
        identification: el.identification,
        firstName: el.content[roepnaam],
        lastName: el.content[achternaam],
        fullName: setFullNameWithDot(
          el.content[roepnaam],
          el.content[achternaam]
        ),
        type: el.content["algemeen-type"],
        category: el.content["zoekrichting-categorie"],
        scale: el.content["zoekrichting-huidigesalarisschaal"],
        maxHours: el.content["zoekrichting-beschikbaarheid-urenmaximaal"],
        careerAdvisor: el.content["data-loopbaanadviseur"],
        expirationDate: el.content?.data?.verloopdatum,
        status:
          el.status[0]?.status[0] + el.status[0]?.status.slice(1).toLowerCase(),
      };

      if (newEl.expirationDate) {
        newEl.expirationDate = new Date(
          newEl.expirationDate
        ).toLocaleDateString("nl-NL", dateFormatOptions);
      }

      return newEl;
    });
  }
};
export const mapToGetArrayOfIdsAndNames = function (object) {
  const arrayOfValues = Object.values(object);
  return arrayOfValues.map((el) => {
    return {
      id: el.identification,
      fullName: setFullName(el.content[roepnaam], el.content[achternaam]),
      fullNameWithDot: setFullNameWithDot(
        el.content[roepnaam],
        el.content[achternaam]
      ),
    };
  });
};
