import Vue from "vue";
import { StoreException } from "@/utils/ErrorHandler";
import IdUtils from "@/utils/IdUtils";
import { Identifications, SortDirections } from "@/utils/GeneralConstants";
import { SearchServices } from "@/services/SearchServices";
import { CandidateTabs, CandidateView } from "@/utils/GeneralConst/Candidate";
import { createCustomSortingFunction } from "@/utils/GeneralUtils";

const TEXT = {
  text_query: null,
  search_fields: ["NAME", "CONTENT"],
  fuzzy: false,
  and_or: "OR",
};

const CONTEXT = {
  role: "Interne Kandidaat",
  instance_identifiers: [],
  direction: "to",
  and_or: "OR",
  start: new Date(),
};

const SELECTION_COMMISSION_CONTENT_QUERY = [
  {
    values: ["nee"],
    key: "data-preselectie",
    and_or: "OR",
    datatype: "STRING",
  },
  {
    values: ["ja"],
    key: "data-preselectievoltooid",
    and_or: "OR",
    datatype: "STRING",
  },
];

const VOORRANG_STATUSES_QUERY_MANAGER = [
  {
    status: "beoordelen voordracht",
    and_or: "OR",
  },
  {
    status: "afwijzing na voordracht",
    and_or: "OR",
  },
  {
    status: "afgewezen",
    and_or: "OR",
  },
  {
    status: "inplannen plaatsingsgesprek",
    and_or: "OR",
  },
  {
    status: "arbeidsvoorwaardengesprek",
    and_or: "OR",
  },
  {
    status: "afwijzing na plaatsingsgesprek",
    and_or: "OR",
  },
  {
    status: "avw overeengekomen",
    and_or: "OR",
  },
];

const state = {
  searchServices: null,
  instanceId: null,

  allCandidates: [],
  candidates: {},
  selectedCandidate: null,
  selectedCandidateIndex: 0,
  bulkActionsCandidates: null,

  page_number: 1,

  query: {
    definitions: [Identifications.KANDIDAAT],
    sorting: [
      { sort_type: "text_score", direction: "DESCENDING" },
      { sort_type: "timestamp_created", direction: "DESCENDING" },
    ],
  },

  voorrangQuery: {
    definitions: [Identifications.VOORRANGSKANDIDAAT],
    sorting: [
      { sort_type: "text_score", direction: "DESCENDING" },
      { sort_type: "timestamp_created", direction: "DESCENDING" },
    ],
    statuses: [],
  },
  tabs: [],

  isRecentActionBeoordelen: null,
};

const getters = {
  searchServices: (state) => state.searchServices,
  instanceId: (state) => state.instanceId,

  getCandidates: (state) => state.allCandidates,
  getCandidate: (state) => (id) => state.candidates[id],
  getSelectedCandidate: (state) => state.selectedCandidate,
  getSelectedCandidateIndex: (state) => state.selectedCandidateIndex,
  bulkActionsCandidates: (state) => state.bulkActionsCandidates,

  getSearch: (state) => state.query?.text_search,
  getQuery: (state) => state.query,
  getVoorrangQuery: (state) => state.voorrangQuery,

  tabs: (state) => state.tabs,
  isRecentActionBeoordelen: (state) => state.isRecentActionBeoordelen,
};

const actions = {
  /**
   * Internal action for initializing and returning the SearchServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.searchServices|(function(*): (null|getters.searchServices|(function(*))))|null>}
   */
  getSearchServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.searchServices) {
        commit(
          "SET_SEARCH_SERVICES",
          new SearchServices(rootGetters.organisationId)
        );
      }

      return getters.searchServices;
    } catch (error) {
      throw new StoreException("getSearchServices: " + error.message);
    }
  },

  /**
   * Function to retrieve both kinds (regular and priority) candidates
   * IMPORTANT!
   * Different types of candidates are fetched separately (due to optional status and content
   * values that can be added to the query) and later merged into one array (allCandidates).
   * @param dispatch
   * @param getters
   * @param commit
   * @param instanceId
   * @param fetchActions
   * @param updateStore
   * @returns {Promise<*[]>}
   */
  retrieveCandidates: async (
    { dispatch, getters, commit },
    { instanceId, fetchActions = true, updateStore = true }
  ) => {
    // Input validation
    if (!instanceId && !getters.instanceId) {
      console.error("No instance id provided");
      return;
    }

    const capacityRequestId = instanceId ? instanceId : getters.instanceId;

    try {
      const searchServices = await dispatch("getSearchServices");

      await dispatch("updateCandidateQuery", capacityRequestId);
      await dispatch("updatePriorityCandidateQuery", capacityRequestId);

      let generalMeta = {
        current_page: 0,
      };
      let general = [];
      let voorrangMeta = {
        current_page: 0,
      };
      let voorrang = [];

      // Retrieve all general candidates
      do {
        generalMeta.current_page += 1;
        const generalResponse = await searchServices.postInstanceQuery(
          process.env.VUE_APP_GLOBAL_PAGE_SIZE,
          generalMeta.current_page,
          getters.getQuery
        );
        generalMeta = generalResponse.meta;
        if (generalResponse && generalResponse.data) {
          general = general.concat(generalResponse.data);
        }
      } while (
        generalMeta.total_pages &&
        generalMeta.total_pages > generalMeta.current_page
      );

      // Retrieve all priority candidates
      do {
        voorrangMeta.current_page += 1;
        const voorrangResponse = await searchServices.postInstanceQuery(
          process.env.VUE_APP_GLOBAL_PAGE_SIZE,
          voorrangMeta.current_page,
          getters.getVoorrangQuery
        );
        voorrangMeta = voorrangResponse.meta;
        if (voorrangResponse && voorrangResponse.data) {
          voorrang = voorrang.concat(voorrangResponse.data);
        }
      } while (
        voorrangMeta.total_pages &&
        voorrangMeta.total_pages > voorrangMeta.current_page
      );

      let candidates = [...general, ...voorrang];

      if (fetchActions) {
        candidates = await dispatch("fetchCandidatesActions", candidates);
      }
      if (updateStore) {
        commit("SET_CANDIDATES", candidates);
      } else {
        return candidates;
      }
    } catch (e) {
      console.error(e);
    }
  },

  /**
   * Function to set other available candidate actions
   * @param _context
   * @param actions
   * @returns {*[]}
   */
  setOtherActions: (_context, actions) => {
    let other = [];
    for (const action of actions) {
      if (
        actions.filter(
          (a) =>
            a.definition.identification === action.definition.identification
        ).length > 1
      ) {
        if (action.state === "STARTED") {
          other = [...other, action];
        }
      } else {
        other = [...other, action];
      }
    }
    return other;
  },

  /**
   * Function to fetch actions of each candidate in candidates array
   * @param dispatch
   * @param candidates
   * @returns {Promise<*>}
   */
  fetchCandidatesActions: async ({ dispatch }, candidates) => {
    try {
      for (const candidate of candidates) {
        const [_all, special, others] = await dispatch(
          "instanceFlow/getActions",
          candidate.identification,
          {
            root: true,
          }
        );

        if (others && special) {
          candidate.actions = dispatch("setOtherActions", others);
          candidate.specialActions = special;
        }
      }
    } catch (e) {
      console.error(e);
    }
    return candidates;
  },

  /**
   * Function to retrieve a candidate by ID
   * @param dispatch
   * @param getters
   * @param commit
   * @param candidateId
   * @return {Promise<function(*): *>}
   */
  retrieveCandidate: async ({ dispatch, commit }, candidateId) => {
    // Input validation
    if (!IdUtils.isUUID(candidateId)) {
      throw new StoreException("Invalid id to retrieveCandidate");
    }

    let candidateInstance = false;

    try {
      // Retrieve instance
      candidateInstance = await dispatch(
        "genericInstance/getGenericInstance",
        candidateId,
        { root: true }
      );
    } catch (error) {
      console.error(error);
    }

    // Validate if instance is a candidate
    if (
      !candidateInstance.data ||
      !candidateInstance.data.hasOwnProperty("relationships") ||
      (candidateInstance.data.relationships.definition.identification !==
        Identifications.KANDIDAAT &&
        candidateInstance.data.relationships.definition.identification !==
          Identifications.VOORRANGSKANDIDAAT)
    ) {
      candidateInstance = false;
    }

    if (!candidateInstance) {
      // No instance retrieved
      throw new StoreException("No candidate found in retrieveCandidate");
    } else {
      // Also retrieve the full context of the candidate
      candidateInstance.data.relationships.context = await dispatch(
        "genericInstance/getInstanceContext",
        candidateId,
        {
          root: true,
        }
      );

      // Store and return
      commit("ADD_CANDIDATE", candidateInstance.data);
    }
    return candidateInstance.data ? candidateInstance.data : undefined;
  },

  /**
   * Function to update the regular candidates query with relevant context and content
   * @param rootGetters
   * @param commit
   * @param capacityRequestId
   * @returns {Promise<void>}
   */
  updateCandidateQuery: async ({ rootGetters, commit }, capacityRequestId) => {
    // Setup internal and external context query params
    const internal = { ...CONTEXT };
    internal.role = "Interne Kandidaat";
    internal.instance_identifiers = [capacityRequestId];

    const external = { ...CONTEXT };
    external.role = "Externe Kandidaat";
    external.instance_identifiers = [capacityRequestId];

    commit("SET_QUERY_CONTEXT", [internal, external]);

    // If the user is not allowed to see all candidates, add the pre-selection content query filter
    if (
      !rootGetters.isUserAdmin &&
      !rootGetters.isUserRecruiter &&
      !rootGetters.isUserBackoffice
    ) {
      commit("SET_QUERY_CONTENT", SELECTION_COMMISSION_CONTENT_QUERY);
    } else {
      // If allowed, clear the content query filter from any previous applied fiters
      commit("SET_QUERY_CONTENT", []);
    }
  },

  /**
   * Function to update the priority candidates query with relevant context and status
   * @param rootGetters
   * @param commit
   * @param capacityRequestId
   * @returns {Promise<void>}
   */
  updatePriorityCandidateQuery: async (
    { rootGetters, commit },
    capacityRequestId
  ) => {
    // Setup basic Priority Candidate query context
    const context = { ...CONTEXT };
    context.role = "Voorrangskandidaat";
    context.instance_identifiers = [capacityRequestId];

    // If the user is not allowed to see all priority candidates, they should also be related as 'Manager'
    if (
      !rootGetters.isUserAdmin &&
      !rootGetters.isUserRecruiter &&
      !rootGetters.isUserBackoffice &&
      !rootGetters.isUserLBA
    ) {
      context.and_or = "AND";

      const managerContext = { ...CONTEXT };
      managerContext.role = "Manager";
      managerContext.and_or = "AND";
      managerContext.direction = "from";
      managerContext.instance_identifiers = [rootGetters.userId];

      commit("SET_VOORRANG_QUERY_CONTEXT", [context, managerContext]);
      commit("SET_VOORRANG_QUERY_STATUS", VOORRANG_STATUSES_QUERY_MANAGER);
    } else {
      commit("SET_VOORRANG_QUERY_CONTEXT", [context]);
    }
  },

  /**
   * Function to set search term in the regular candidates query
   * @param commit
   * @param text
   * @returns {Promise<void>}
   */
  setSearch: async ({ commit }, text) => {
    try {
      if (text) {
        const textQuery = TEXT;
        textQuery.text_query = text;

        commit("SET_QUERY_TEXT_SEARCH", [textQuery]);
      } else {
        commit("SET_QUERY_TEXT_SEARCH", []);
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Function to set status in the regular candidates query
   * @param commit
   * @param dispatch
   * @param getters
   * @param status
   * @returns {Promise<void>}
   */
  setStatus: async ({ commit, dispatch, getters }, status) => {
    try {
      if (status) {
        commit("SET_QUERY_STATUSES", [{ status: status, and_or: "OR" }]);
      } else {
        commit("SET_QUERY_STATUSES", []);
      }

      await dispatch("retrieveCandidates", { instanceId: getters.instanceId });
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Function to set selected candidate index in the store
   * @param commit
   * @param val
   * @returns {Promise<void>}
   */
  setSelectedCandidateIndex: async ({ commit }, val) => {
    commit("SET_CANDIDATE_INDEX", val);
  },

  /**
   * Function to set selected candidate object in the store
   * @param commit
   * @param val
   * @returns {Promise<void>}
   */
  setSelectedCandidate: async ({ commit }, val) => {
    commit("SET_SELECTED_CANDIDATE", val);
  },

  /**
   * Function to set instance (capacity request) ID in the store
   * @param commit
   * @param val
   * @returns {Promise<void>}
   */
  setInstanceId: async ({ commit }, val) => {
    commit("SET_INSTANCE_ID", val);
  },

  /**
   * Function to set available tabs for the candidate view
   * @param commit
   * @param routeName
   * @param isVoorrangskandidaat
   * @returns {Promise<void>}
   */
  setTabs: async ({ commit }, { routeName, isVoorrangskandidaat }) => {
    if (routeName && routeName === "SollicitatieDetails") {
      const tabs = [
        CandidateTabs.JOUWSOLLICITATIE,
        CandidateTabs.SOLLICITATIEVERLOOP,
        CandidateTabs.ACTIES,
        CandidateTabs.COMMUNICATIE,
      ];
      if (isVoorrangskandidaat) {
        tabs.splice(1, 1); // Delete Sollicitatieverloop
      }
      commit("SET_TABS", tabs);
    } else if (routeName && CandidateView.STANDARD.includes(routeName)) {
      commit("SET_TABS", [
        CandidateTabs.PROFIEL,
        CandidateTabs.ACTIES,
        CandidateTabs.SOLLICITATIEVERLOOP,
        CandidateTabs.COMMUNICATIE,
        CandidateTabs.BEOORDELINGEN,
      ]);
    }
  },

  /**
   * Function to reset the values in the store
   * @param commit
   * @returns {Promise<void>}
   */
  reset: async ({ commit }) => {
    commit("SET_CANDIDATES", null);
    commit("SET_INSTANCE_ID", null);
    commit("SET_CANDIDATE_INDEX", null);
    commit("SET_SELECTED_CANDIDATE", null);
  },

  /**
   * Function to set available bulk actions
   * @param commit
   * @param candidatesData
   */
  setBulkActionCandidates({ commit }, candidatesData) {
    commit(
      "SET_BULK_ACTIONS_CANDIDATES",
      candidatesData ? candidatesData : null
    );
  },

  /**
   * Function to confirm whetehr the last executed action was a review action
   * @param commit
   * @param isRecentActionBeoordelen
   */
  setIfRecentActionBeoordelen({ commit }, isRecentActionBeoordelen) {
    commit("SET_IF_RECENT_ACTION_BEOORDELEN", isRecentActionBeoordelen);
  },

  /**
   * Function to sort the candidates array by provided property and selected order
   * @param getters
   * @param commit
   * @param sorting
   */
  sortCandidates({ getters, commit }, sorting) {
    let sortedCandidates = getters.getCandidates;

    // If there's sorting order defined, sort the candidates array accordingly
    if (sorting.order) {
      // If status is the sorting prop, create an extra property "sortStatus" that takes the status value (string)
      if (sorting.prop === "status") {
        sortedCandidates.forEach((candidate) => {
          candidate.sortStatus = candidate.status[0].status;
        });
        sortedCandidates.sort(
          createCustomSortingFunction("sortStatus", null, sorting.order)
        );
      } else {
        sortedCandidates.sort(
          createCustomSortingFunction(sorting.prop, null, sorting.order)
        );
      }
    } else {
      // If there's no sort order provided, apply default sorting
      sortedCandidates.sort(
        createCustomSortingFunction(
          "timestamp_created",
          null,
          SortDirections.DESCENDING
        )
      );
    }
    commit("SET_CANDIDATES", sortedCandidates);
  },
};

const mutations = {
  SET_SEARCH_SERVICES: (state, payload) =>
    Vue.set(state, "searchServices", payload),

  SET_CANDIDATES: (state, payload) => Vue.set(state, "allCandidates", payload),

  SET_SELECTED_CANDIDATE: (state, payload) =>
    Vue.set(state, "selectedCandidate", payload),

  SET_CANDIDATE_INDEX: (state, payload) =>
    Vue.set(state, "selectedCandidateIndex", payload),

  SET_BULK_ACTIONS_CANDIDATES: (state, payload) =>
    Vue.set(state, "bulkActionsCandidates", payload),

  ADD_CANDIDATE: (state, payload) =>
    Vue.set(state.candidates, payload.identification, payload),

  SET_TABS: (state, payload) => Vue.set(state, "tabs", payload),

  SET_QUERY_CONTEXT: (state, payload) =>
    Vue.set(state.query, "context", payload),

  SET_VOORRANG_QUERY_CONTEXT: (state, payload) =>
    Vue.set(state.voorrangQuery, "context", payload),

  SET_QUERY_CONTENT: (state, payload) =>
    Vue.set(state.query, "content", payload),

  SET_VOORRANG_QUERY_STATUS: (state, payload) =>
    Vue.set(state.voorrangQuery, "statuses", payload),

  SET_QUERY_TEXT_SEARCH: (state, payload) => {
    Vue.set(state.query, "text_search", payload);
    Vue.set(state.voorrangQuery, "text_search", payload);
  },
  SET_QUERY_STATUSES: (state, payload) => {
    Vue.set(state.query, "statuses", payload);
    Vue.set(state.voorrangQuery, "statuses", payload);
  },
  SET_INSTANCE_ID: (state, payload) => Vue.set(state, "instanceId", payload),
  SET_IF_RECENT_ACTION_BEOORDELEN: (state, payload) =>
    Vue.set(state, "isRecentActionBeoordelen", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
