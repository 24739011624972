import axios from "axios";

import authentication from "../authentication";
import { ServiceException } from "@/utils/ErrorHandler";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";

const EMAILS_RESOURCE = "/definitions/email-templates";

export class EmailTemplateServices {
  /**
   * Construct axios
   * @param organisation
   */
  constructor(organisation) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisation) {
      this.setOrganisation(organisation);
    }
  }

  setOrganisation(organisation) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisation;
  }

  async getEmailTemplate(emailTemplateId) {
    return this.OMNIMAP.get(EMAILS_RESOURCE + "/" + emailTemplateId, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error);
      });
  }
}
