import ElementLanguage from "element-ui/lib/locale/lang/en";

const messages = {
  general: {
    welcome: "Welcome",
    select: "Select",
    process: "Process",
    scrollToLoad: "Scroll to load more",
    clickToLoad: "Click to load more",
    noMore: "No more results",
    loaded: "loaded",
    ownOrganisations: "Own organisations",
    genericError: "Sorry, a problem has occured. Please try again later.",
    cancel: "Cancel",
    sitemap: "Sitemap",
    home: "Home",
    homepage: "Homepage",
    vacancies: "Vacancies",
    carrier_education: "Carrier and education",
    newCapacityRequest: "Request new capacity",
    faq: "Frequently asked questions",
    contact: "Contact",
    privacy: "Privacy",
    cookies: "Cookies",
    accessibility: "Accessibility",
    exact: "Exact",
    submit: "Submit",
    losseVacature: "Losse vacature",
    aanvraag: "Aanvraag",
  },
  login: {
    selectLoginMethod: "Select login method",
    logout: "Log out",
  },
  omniselect: {
    loading: "Loading...",
    noQuery: "No query available",
    noPath: "No path available",
    noJsonpath: "No JSONPath available",
    noUrl: "No URL available",
    invalidPath: "Invalid path",
    initError: "Error on loading results",
    searchError: "Error on searching selection",
    invalidResult: "INVALID RESULT",
    path: "Path",
    result: "Result",
    noResult: "NO RESULT",
    noResults: "No results",
    validPaths: "Check valid paths",
    missingVariables: "",
    neededValues: "Needed values",
    invalidValues:
      "A variable has gotten an invalid value and the data can't be retrieved.",
    checkValues: "Check the values of",
  },
  organisationSelection: {
    selectOrganisation: "Select organisation",
  },
  actions: {
    download: "Download",
  },
  columns: {
    general: "General",
    instance: "Instance",
    title: "Title",
    name: "Name",
    documentName: "Document name",
    fileName: "File name",
    instancenceName: "Instance name",
    identification: "Identification",
    id: "ID",
    identifier: "ID",
    description: "Description",
    since: "Since",
    until: "Until",
    role: "Relation",
    operations: "Actions",
    path: "Path",
    type: "Type",
    created: "Created by",
    createdTime: "Created",
    updated: "Updated by",
    updatedTime: "Updated",
    organisation: "Organisation",
    workmethod: "Work method",
    actions: "Actions",
    timestamp_created: "Date created",
    timestamp_any_update: "Date last update",
    status: "Status",
    relevance: "Relevance",
    jobfunction: "Current job title",
    category: "Category",
    surname: "Surname",
    locations: "Location",
    rate: "Rate",
    details: "Details",
    jobs: "Jobs",
    candidates: "Candidates",
    label_from: "Relatie",
    related_instance_identification: "Related instance identification",
    related_instance_identifier: "Related instance ID",
    related_instance_name: "Related instance name",
    version: "Version",
    retentionPeriod: "Keep until",
    tags: "Tags",
  },
  terms: {
    lba: "Career advisor",
    endReview: "End review",
    applicationProgress: "Application progress",
    schedule: "Schedule",
    history: "History",
    actions: "Actions",
    profile: "Profile",
    reviews: "Beoordelingen",
    communication: "Communication",
    nomination: "Nomination",
    jobFunctions: "Jobfunctions",
    exampleText: "Example text",
    selectionCommission: "Selection commission",
    selectionCommissionMembers: "Selection commission members",
    publicationData: "Publicationdata",
    mantelPartijen: "Mantelpartijen",
    recruitmentArchive: "Recruitment archive",
    beoordelingen: "Beoordelingen",
  },
};

const omnischemaMessages = {
  // OmniSchema
  schema: {
    //General
    select: "Select",
    isRequired: "Field is required",
    open: "Open",
    createMapping: "Create mapping",
    checkAll: "Check all",
    identification: "identification",
    isExported: "Data is exported",
    parentExported: "Exported through parent",
    inline: "Inline",

    //JSONArray
    more: "more",
    loadMore: "Load more",
    addItem: "Add {item}",

    //JSONString
    selectTime: "Select time",
    selectDate: "Select date",
    selectDateTime: "Select date and time",
    navigateToInstance: "Navigate to instance",
    missingDestination: "Link is missing {type}",
    noTimeSet: "Selected time is 00:00",

    //CustomComponent
    customComponentPlaceholder:
      "This is a placeholder for the custom component ",
    slotName: "Slot name",
    returnedObject: "Returned object",

    //DateRange
    start: "Start",
    end: "End",

    //Table
    addValue: "Add value",
    addRow: "Add row",
    editRow: "Edit row",
    deleteRow: "Delete row",
    save: "Save",
    showList: "Show list",
    hideList: "Hide list",
    newRow: "New row",
    showAll: "Show all",
    total: "Total",

    //WYSIWYG
    history: "History",
    undo: "Undo",
    redo: "Redo",
    text: "Text",
    bold: "Bold",
    italic: "Italic",
    underline: "Underline",
    strike: "Strikethrough",
    header: "Header | Headers",
    lists: "Lists",
    orderedList: "Ordered list",
    bulletList: "Unordered list",
    table: "Table",
    tableFunctions: "Table functions",
    createTable: "Create table",
    deleteTable: "Delete table",
    addColumnBefore: "Add column before",
    addColumnAfter: "Add column after",
    deleteColumn: "Delete column",
    addRowAfter: "Add row after",
    addRowBefore: "Add row before",
    toggleCellMerge: "Toggle cell merge",
    link: "Link - to add a link select a word in the editor, then paste the link in the input field and hit the Enter key",
  },

  // Editor
  editor: {
    schema: "Schema",
    fullEditor: "Full editor",
    definition: "Definition",
    definitions: "Definitions",
    //Types
    reference: "Reference",
    grouping: "Grouping",
    group: "Group",
    object: "Object",
    list: "List",
    text: "Text",
    textarea: "Textarea",
    email: "Email address",
    url: "URL input",
    texteditor: "Text Editor",
    options: "Options",
    link: "Link",
    button: "Button",
    external: "External",
    internal: "Internal",
    dates: "Dates",
    time: "Time",
    date: "Date",
    dateTime: "Date & time",
    dateRange: "Date range",
    boolean: "Boolean",
    checkbox: "Checkbox",
    confirmCheckbox: "Confirm checkbox",
    numbers: "Numbers",
    number: "Number",
    integer: "Integer",
    rating: "Rating",
    slider: "Slider",
    sliderOptions: "Slider options",
    currency: "Currency",
    selection: "Selection",
    select: "Select",
    multipleSelect: "Multiple select",
    custom: "Custom",
    customComponent: "Custom component",
    noCustom: "No components found. Enter name manually.",
    customWrongType:
      "Incorrect type <b>{type}</b>. Expected data type: <b>{exptected_type}</b>",
    omniselect: "Omniselect",
    omniMultiSelect: "Multiple Omniselect",
    omnilookup: "Omnilookup",
    location: "Location",
    media: "Media",
    other: "Other",
    rrule: "R-Rule",
    validation: "Validation",
    advancedValidation: "Advanced validation",
    validationErrors: "Validation errors",
    page: "Page",
    pattern: "Pattern",
    errorMessage: "Error message",
    invalidRegex: "Invalid regex",
    delete: "Delete",
    duplicate: "Duplicate",
    compareText: "Compare text",
    compareInfo:
      "Displays the differences between two parts of text. " +
      "With a mapping text can placed in the old and new properties.",
    old: "Old",
    new: "New",

    addExportLocation: "Add export location",
    removeExportLocation: "Remove this export location",
    tableName: "Table name",
    columnName: "Column name",
    dataTypesLocked: "You must select a destination first",
    rowInserts: "Row inserts",

    //ADD Component
    addTo: "Add to",
    title: "Title",
    type: "Type",
    itemType: "Item type",
    selectType: "Select type",
    cancel: "Cancel",
    add: "Add",
    notAvailable: "Key is already in use",
    selectItemType: "Select list item type",
    noTitle: "Enter a title",
    invalidKey: "Invalid key",
    noItems: "No items yet in",
    noDefinitions: "No definitions",

    //Edit component
    edit: "Edit",
    path: "path",
    general: "General",
    settings: "Settings",
    advanced: "Advanced",
    export: "Export",
    security: "Security",
    rules: "Rules",
    conditionalRendering: "Conditional rendering",
    noConditionalRendering: "No conditional rendering added.",
    conditionalOptionsInfo:
      "Conditionally hide or disable options based on values in the schema.",
    info: "Info",
    deprecated: "Deprecated",
    displaySubtitle: "Show info as subtitle",
    required: "Required",
    showAtCreation: "Show at create instance",
    readOnly: "Read Only",
    hidden: "Hidden",
    indexElastic: "Index for selection screen",
    privacySensitive: "Privacy sensitive data",
    executeMapping: "Execute mapping",
    mappedValue: "Mapped value",
    exportSettings: "Data export settings",
    destination: "Destination",
    dataType: "Datatype",
    table: "Table",
    tableRow: "Table row",
    column: "Column",
    checkPresence: "Check Presence",
    create: "Create",
    createRenderRules: "Create render rules for",
    revertChanges: "Revert Changes",
    requiredRules: "Required rules",
    textTextarea: "Text/Textarea",
    textTextareaInfo:
      "For text, required makes the property required in the data, but it can " +
      "still have an empty value. If a string must have a value, set it's Min Length to 1 or higher.",
    requiredChild: "Required Child",
    requiredChildInfo:
      "If the parent object of the required property is not set to required, " +
      "validation will not trigger unless the parent is present in the data. To make a " +
      "property required even when the parent is not existant, make the parent required as well.",
    requiredCheckbox:
      "A Boolean which is required can be BOTH checked and unchecked, " +
      "if you want only checked values to be valid, please use a BooleanConfirm.",
    optional: "Optional",
    optionalInfo:
      "An optional field is not required, but displayed when only required fields are shown.",
    requiredBoolean: "A required boolean has it's default set to false.",
    requiredBooleanConfirm: "This checkbox is required",
    mappingIdentification: "Mapping identification",
    placeholder: "Placeholder",
    tableFooter: "Table footer key",
    tableFooterPlaceholder: "Add a unique key",

    //JSONString
    format: "Format",
    formatInfo:
      "Use these characters to display the date as desired. On the right is an example" +
      "of what the charachters represent. " +
      "Other characters can be used to separate the values. (-, /, [space]).",
    dateCharactersOnly:
      'Type "Date" can only include Date characters, such as dd-MM-yyyy',
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",
    second: "Second",
    includeTime: "Include time",
    linkText: "Link text",
    test: "Test",
    minLength: "Min length",
    maxLength: "Max length",
    minItems: "Min items",
    maxItems: "Max items",
    minMax: "Min and Max values",
    minMaxLength: "Min and Max length",
    minMaxItems: "Min and Max items",
    default: "Default",
    defaultValue: "Default value",
    tabs: "Tabs",
    wizard: "Wizard",
    next: "Next",
    previous: "Previous",
    rows: "Rows",
    selectTime: "Select time",
    selectDate: "Select date",
    selectDateTime: "Select date & time",
    start: "Start",
    end: "End",
    clickable: "Clickable",
    noPagination: "No pagination",

    //JSONArray
    cantAdd: "Items cannot be added",
    disallowAdd: "Disallow add",
    cantDelete: "Items cannot be deleted",
    disallowDelete: "Disallow delete",
    tableView: "Table view",
    collapse: "Collapsible | Collapsed",

    //JSONEnum
    checkboxGroup: "Checkbox group",
    allowCreate: "Allow create",
    radioDisplay: "Radio display",
    noDefault: "No default",
    newOption: "New option",
    uniqueOption: "Options must be unique",

    //JSONNumber
    minimum: "Minimum",
    maximum: "Maximum",
    separator: "Separator",
    steps: "Steps",
    notDividable: "Not dividable by",
    showStops: "Show stops",

    //JSONObject
    update: "Update",

    //Location
    selectMapConfiguration: "Select map configuration",
    noMapConfigurations: "No map configurations available",

    //Media
    selectMediaDefinition: "Select media definition",
    noMediaDefinition: "No media definitions available",
    infoMediaPath:
      "In all cases the path should end with '/'. If the path is nested, input all folder names separated by '/'. Example: 'folder/subFolder/'. If left empty, file will be added to the root folder.",

    //Omniselect
    flexible: "Flexible",
    searchIncludes: "Search includes",
    searchOptions: "Search options",
    getPathInfo:
      "Select an array of data from the response of the url using a json path.",
    getFilterInfo: "Filter the data of the Get response using a json path.",
    display: "Display",
    infoDisplay:
      "Enter the jsonpath to property that should be displayed " +
      "in the results (usually $.name).",
    queryLength: "Query length",
    queryLengthInfo:
      "How many characters must be entered before the search call is done?\n" +
      "This reduces the amount of API calls, because the search is called less often.",
    save: "Save",
    infoSave:
      "Enter the jsonpath to property that should be saved (usually $.identification).",
    populateFields: "Populate other fields",
    contextDefinitionIdentification: "Context definition identification",
    infoContextDefinitionIdentification:
      "Selected instances will be added to the context of the current instance based on the given context definition identification",
    viewInstance: "Show button to view instance",
    navigateInstance: "Show button to navigate to instance",
    field: "Field | Fields",
    property: "Property",
    variable: "Variable | Variables",
    infoVariables:
      "The {type} field allows variables that will be replaced with values from the form. " +
      "To add a variable to the {type} place a term between double curly brackets, " +
      "like this: ",
    omniLookupVariable:
      "The default query variable needs to be added to the url",
    queryField: "Omnilookup query field",
    infoVariablesJSON:
      "The quotations around the variable are required, without the query is not valid.",
    variableName: "variable_name",
    referSelf: "Can't refer to own path",
    omniconnectPlaceholder: "Placeholder",
    omniconnectPlaceholderInfo:
      "Before a result is selected there is a standard placeholder text " +
      "in the selection box, determine that placeholder with this input. Leave blank for the " +
      "default value: 'Select'",
    omniconnectSelect: "Select path",
    omniconnectSelectInfo:
      "With the value from this path a single result from the list of data " +
      "will be selected. When your list of resulst comes from 'api-url/list-of-results'," +
      "the value of this selection path will be put at the end of the url to fetch a single result " +
      "'api-url/list-of-results/select-value'. This is often $.identification or $.index",
    omniconnectSearch: "Search",
    omniconnectSearchInfo:
      "Initially the list of results is not loaded. The user needs to " +
      "input a search term and results will be displayed based an that search. This input " +
      "defines the property in the results that the user can search on, " +
      "for instance enter 'name' to allow the user to search for names.",

    //Conditional Rendering
    action: "Action | Actions",
    newAction: "New action",
    selectAction: "Select action",
    condition: "Condition | Conditions",
    addCondition: "Add condition",
    conditionsIncomplete: "Conditions are incomplete",
    show: "Show",
    hide: "Hide",
    disable: "Disable",
    makeRequired: "Required",
    description: "Description",
    source: "Source",
    comparator: "Comparator",
    eq: "is equal to",
    ne: "is not equal to",
    gt: "is greater than",
    ge: "is greater than or equal to",
    lt: "is less than",
    le: "is less than or equal to",
    valueType: "Value Type",
    value: "Value",
    selectComparator: "Select comparator",
    selectPath: "Select path",
    selectPathCompare: "Select path to compare with",
    selectedOwnPath: "Can't compare with self, select a differen path",
    enterValue: "Enter value to compare with",
    addConditions: "Add at least {n} condition | Add at least {n} conditions",
    relative: "Relative",
    specific: "Specific",
    difference: "Difference",
    differenceInfo: "Enter a negative number to look at dates in the past.",
    leaveDateEmpty:
      "Leave empty to use the current date when the instance is viewed.",
    compareDate: "Date to compare with",
    businessDays: "Only count business days",
    dateCompareInfo:
      "When comparing dates, we are checking the difference " +
      "between 2 dates against a provided difference.<br/>" +
      "<b>Source 1</b> is a variable date provided in the data of the schema.<br/>" +
      "<b>Source 2</b>b> is either the current date, or a provided static date.<br/><br/>" +
      "<b>Examples</b><br/>" +
      "Check if the date is today.<br/>" +
      "Source 1 == Difference: 0<br/><br/>" +
      "Check if the date is in the future.<br/>" +
      "Source 1 > Difference: 0<br/><br/>" +
      "Check if the date is 2 or more days ago<br/>" +
      "Source 1 <= Difference: -2",

    noFutureDates: "After",
    noPastDates: "Before",
    dateLimits: "Date limitations",
    limitDateInfo:
      "Limit the date range a user can select. You will have to specify an orientation (past or future dates) and a reference date which can be today (dynamic), another date item in the schema or a custom date.",
    limitDate: "Set date picker limitations",
    today: "Today",
    customDate: "Custom date",
    orientation: "Time orientation",
    limitOrientation: "Orientation",
    dateReference: "Date reference",
    pickDate: "Pick a date",

    checkPresenceHelp1:
      "By selecting this, the exported value will be a boolean that is true if data is present and false otherwise.",
    checkPresenceHelp2:
      "It can be used when certain data properties want to be hidden from the export destination still informing whether they are present or not.",

    deselectCheckPresence:
      "Deselect Check presence to be able to edit data type",

    updateRate: "Update rate",

    updateRates: {
      everyMinute: "Every minute",
      fiveMinutes: "Every 5 minutes",
      fifteenMinutes: "Every 15 minutes",
      thirtyMinutes: "Every 30 minutes",
      hourly: "Every hour",
      fourHours: "Every 4 hours",
      eightHours: "Every 8 hours",
      daily: "Daily",
    },

    exportDestinations: "Export destinations",
    exportLocations: "Export locations",
    exportFullContent: "Export full content",
  },

  //Security
  security: {
    path: "path",
    copy: "Copy read rules | Copy own organisation context | Copy mandate organisation context",
    read: "Read",
    write: "Write",
    select: "Select",
    context: "Context",
    application: "Application",
    own: "Own",
    mandate: "Mandate organisations",
    roleSet: "Role set",
    inherited: "Security settings inherited from the parent",
    selectRoles: "Select roles",
    allowAll: "Allow all",
    name: "Name",
    rules: "Rules",
    rule: "Rule",
    allow: "ALLOW",
    deny: "DENY",
    actions: "Actions",
    view: "View",
    start: "Start",
    cancel: "Cancel",
    finish: "Finish",
    create: "Create",
    all: "All",
    personalContext: "Personal context",
    organisationContext: "Organisation context",
    applicationContext: "Application context",

    securityRule: "Security rule",
    copyRule: "Copy the rule and paste it as the last one",
    copyType: "Copy all the rules from {action1} to {action2}",
    orderMatters:
      "Start with the most detailed rule and end with the most general one",
    noInstanceSecurity: "No instance security rules added yet",
    noInstanceSecurityTooltip:
      "When there are no instance security rules specified, all users can {action} the instance. Content and process security rules remain independent",
    saveAlert:
      "Make sure to set all types of security context in order to create a valid rule",
    allAllowed: "All allowed",

    noContentSecurity: "No content security rules added yet",
    noContentSecurityTooltip:
      "When there are no content security rules specified, all users can {action} it. Instance and process security rules remain independent",
  },

  //RRules
  rrule: {
    frequency: "Frequency",
    count: "Count",
    yearly: "Yearly",
    monthly: "Monthly",
    weekly: "Weekly",
    daily: "Daily",
    hourly: "Hourly",
    minutely: "Minutely",
    secondly: "Secondly",
    interval: "Interval",
    delay: "Delay",
    inSeconds: "in seconds",
    restriction: "Restriction",
    onlyWeekDays: "Only on week days",
    onlyWeekends: "Only on weekends",
    after: "after",
    every: "Every",
    for: "For",
    times: "Times",
    and: "And",
    execute: "Execute",
    seconds: "seconds",
    minutes: "minutes",
    hours: "hours",
    second: "second | {n} seconds",
    minute: "minute | {n} minutes",
    hour: "hour | {n} hours",
    day: "day | {n} days",
    week: "week | {n} weeks",
    month: "month | {n} months",
    year: "year | {n} years",
  },

  //securityToText Util
  securityToText: {
    all: "All",

    view: "View",
    start: "Start",
    cancel: "Cancel",
    finish: "Finish",
    create: "Create",
    select: "Select",
    read: "Read",
    get: "Get",

    actions: "Actions",

    own: "own",
    mandate: "mandate",

    personalContext: "Personal context",
    organisationContext: "Organisation context",
    applicationContext: "Application context",

    denySingular: "is denied for ",
    denyPlural: "are denied for ",
    allowSingular: "is allowed for ",
    allowPlural: "are allowed for ",

    theFollowing: "the following ",
    in: "in ",
    and: "and",
    or: " or ",

    role: "Role | Roles",

    nobody: "Nobody",
    everybody: "Everybody",

    unknown: "unknown role",

    users: "users ",
    applicationRole: "with application role ",
    whichAreRelated: "which are related ",
    withTheir: "with their ",
    as: "as ",
    ownOrganisation: "own organisation ",
    mandate_context_roles: "mandate organisation ",
    user_context_roles: "user context ",

    toTheInstance: " to the instance in question",
  },
  newSecurityToText: {
    allAllowed: "Every user can {action} an instance.",
    noneAllowed: "No user can {action} an instance.",
    someAllowed:
      "User can {action} an instance if they meet all following criteria: ",
    specify: "Specify all remaining criteria to create a valid rule.",
    application_roles: "they have application role of",
    mandate_context_roles:
      "their mandate organisation is related to the instance as",
    own_context_roles: "their own organisation is related to the instance as",
    user_context_roles: "they are related to the instance as",
  },
};

export default Object.assign(
  Object.assign(messages, ElementLanguage),
  omnischemaMessages
);
