/**
 * Services to create and manipulate activities.
 *
 * @file   This files defines the ActivityServices.
 * @author Omnimap
 * @since  2020.12.30
 * @version 1
 */

import axios from "axios";
import authentication from "@/authentication";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";

// Base resource
const ACTIVITY = "/activities";
const DEFINITION = "/definition";

// Input data definition resources
const INPUT_DATA = "/inputdata";
const INPUT_MAPPING = "/inputmapping";
const INPUT_MEDIA = "/inputmedia";
const OUTPUT_DATA = "/outputdata";
const OUTPUT_MAPPING = "/outputmapping";
const OUTPUT_MEDIA = "/outputmedia";

// Mapping data resources
const MAP_INPUT = "/mapinput";
const MAP_OUTPUT = "/mapoutput";

// Action resources
const REFRESH = "/refresh";
const SNOOZE = "/snooze";
const WAKE = "/wake";

const START = "/start";
const VALIDATE = "/validate";
const AWAIT_VALIDATION = "/await-validation";
const NOTIFY = "/notify";

const DETAILS = "/details";

export class ActivityServices {
  /**
   * Construct axios
   * @param organisationId: The unique identification of the organisation
   */
  constructor(organisationId) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisationId) {
      this.setOrganisation(organisationId);
    }
  }

  /**
   * Set the organisation identification
   * @param organisationId: The unique identification of the organisation
   */
  setOrganisation(organisationId) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisationId;
  }

  /**
   * The plain registration of an activity without trying to start it.
   * No validation will be performed.
   * All available information can be provided, but this is not necessary because it is also possible to do this with individual calls to the API.
   * @url {base}/activities/{activity}
   * @param definition
   * @returns {Promise<AxiosResponse<any>>}
   */
  async postActivity(definition) {
    return this.OMNIMAP.post(ACTIVITY, definition, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get activity based on the activity identification.
   * @url {base}/activities/{activity}
   * @param activityId: The unique identification of the activity
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getActivity(activityId) {
    return this.OMNIMAP.get(ACTIVITY + "/" + activityId, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get the definition of an activity.
   * @url {base}/activities/{activity}/definition
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getActivityDefinition(activityId) {
    return this.OMNIMAP.get(ACTIVITY + "/" + activityId + DEFINITION, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get the input data definition of an activity.
   * @url {base}/activities/{activity}/definition/inputdata
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getInputDataDefinition(activityId) {
    return this.OMNIMAP.get(
      ACTIVITY + "/" + activityId + DEFINITION + INPUT_DATA,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Set the input data definition of an activity.
   * @url {base}/activities/{activity}/definition/inputdata
   * @param activityId: The unique identification of the activity.
   * @param structureDefinition: The definition of the input data of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async setInputDataDefinition(activityId, structureDefinition) {
    return this.OMNIMAP.post(
      ACTIVITY + "/" + activityId + DEFINITION + INPUT_DATA,
      structureDefinition,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get the input mapping definition of an activity.
   * @url {base}/activities/{activity}/definition/inputmapping
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getInputMappingDefinition(activityId) {
    return this.OMNIMAP.get(
      ACTIVITY + "/" + activityId + DEFINITION + INPUT_MAPPING,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Set the input mapping definition of an activity
   * @url {base}/activities/{activity}/definition/inputmapping
   * @param activityId: The unique identification of the activity.
   * @param mapping: Define the input mapping definition.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async setInputMappingDefinition(activityId, mapping) {
    return this.OMNIMAP.post(
      ACTIVITY + "/" + activityId + DEFINITION + INPUT_MAPPING,
      mapping,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get the input media definition of an activity.
   * @url {base}/activities/{activity}/definition/inputmedia
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getInputMediaDefinition(activityId) {
    return this.OMNIMAP.get(
      ACTIVITY + "/" + activityId + DEFINITION + INPUT_MEDIA,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Set the input media definition of an activity.
   * @url {base}/activities/{activity}/definition/inputmedia
   * @param activityId: The unique identification of the activity.
   * @param mediaDefinition: The definition of the input media to add to the definition of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async setInputMediaDefinition(activityId, mediaDefinition) {
    return this.OMNIMAP.post(
      ACTIVITY + "/" + activityId + DEFINITION + INPUT_MEDIA,
      mediaDefinition,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get the output data definition of an activity.
   * @url {base}/activities/{activity}/definition/outputdata
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getOutputDataDefinition(activityId) {
    return this.OMNIMAP.get(
      ACTIVITY + "/" + activityId + DEFINITION + OUTPUT_DATA,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Set the output data definition of an activity.
   * @url {base}/activities/{activity}/definition/outputdata
   * @param activityId: The unique identification of the activity.
   * @param structureDefinition: The definition of the output data of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async setOutputDataDefinition(activityId, structureDefinition) {
    return this.OMNIMAP.post(
      ACTIVITY + "/" + activityId + DEFINITION + OUTPUT_DATA,
      structureDefinition,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get the output mapping definition of an activity.
   * @url {base}/activities/{activity}/definition/outputmapping
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getOutputMappingDefinition(activityId) {
    return this.OMNIMAP.get(
      ACTIVITY + "/" + activityId + DEFINITION + OUTPUT_MAPPING,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Set the output mapping definition of an activity.
   * @url {base}/activities/{activity}/definition/outputmapping
   * @param activityId: The unique identification of the activity.
   * @param mapping: The definition of the output mapping of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async setOutputMappingDefinition(activityId, mapping) {
    return this.OMNIMAP.post(
      ACTIVITY + "/" + activityId + DEFINITION + OUTPUT_MAPPING,
      mapping,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get the output media definition of an activity.
   * @url {base}/activities/{activity}/definition/outputmedia
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getOutputMediaDefinition(activityId) {
    return this.OMNIMAP.get(
      ACTIVITY + "/" + activityId + DEFINITION + OUTPUT_MEDIA,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Set the output media definition of an activity.
   * @url {base}/activities/{activity}/definition/outputmedia
   * @param activityId: The unique identification of the activity.
   * @param mediaDefinition: The definition of the output media to add to the definition of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async setOutputMediaDefinition(activityId, mediaDefinition) {
    return this.OMNIMAP.post(
      ACTIVITY + "/" + activityId + DEFINITION + OUTPUT_MEDIA,
      mediaDefinition,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get the available input data of an activity to be used by the mapping (if applicable).
   * @url {base}/activities/{activity}/inputdata
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getInputData(activityId) {
    return this.OMNIMAP.get(ACTIVITY + "/" + activityId + INPUT_DATA, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Set the input data to be used by the mapping (if applicable) and to used by the implementation.
   * Optional because it should be possible to execute an implementation without any input data.
   * Setting input is a ‘merge’ functionality.
   * It merges the data provided with the data available in the instance.
   * @url {base}/activities/{activity}/inputdata
   * @param activityId: The unique identification of the activity.
   * @param mediaDefinition: The definition of the output media to add to the definition of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async setInputData(activityId, data) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + INPUT_DATA, data, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get all available input media of an activity.
   * @url {base}/activities/{activity}/inputmedia
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getInputMedia(activityId) {
    return this.OMNIMAP.get(ACTIVITY + "/" + activityId + INPUT_MEDIA, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Set the input media of an activity.
   * @url {base}/activities/{activity}/inputmedia
   * @param activityId: The unique identification of the activity.
   * @param content
   * @param contentName
   * @param contentRole
   * @param contentType
   * @param format
   * @param mimeType
   * @returns {Promise<AxiosResponse<any>>}
   */
  async setInputMedium(
    activityId,
    content,
    contentName,
    contentRole,
    contentType,
    format,
    mimeType
  ) {
    return this.OMNIMAP.post(
      ACTIVITY + "/" + activityId + INPUT_MEDIA,
      {
        content,
        contentName,
        contentRole,
        contentType,
        format,
        mimeType,
      },
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get a specific input media of an activity.
   * @url {base}/activities/{activity}/inputmedia/{medium}
   * @param activityId: The unique identification of the activity.
   * @param mediumId: The unique identification of the medium.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getInputMedium(activityId, mediumId) {
    return this.OMNIMAP.get(
      ACTIVITY + "/" + activityId + INPUT_MEDIA + "/" + mediumId,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Delete a specific input medium belonging to an activity.
   * @url {base}/activities/{activity}/inputmedia/{medium}
   * @param activityId: The unique identification of the activity.
   * @param mediumId: The unique identification of the medium.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async deleteInputMedium(activityId, mediumId) {
    return this.OMNIMAP.delete(
      ACTIVITY + "/" + activityId + INPUT_MEDIA + "/" + mediumId,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Transforming the input data based on the provided input mapping.
   * Only executed if input data AND input mapping is available.
   * The result of the mapping is saved with the activity.
   * @url {base}/activities/{activity}/mapinput
   * @param activityId: The unique identification of the activity.
   * @param structureDefinition: The definition of the input data of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async mapInput(activityId) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + MAP_INPUT, null, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Transforming the response of the implementation to format as needed by the instance.
   * Only possible if an output mapping is available and if the processing of the implementation is 'OK’.
   * The result of the mapping is saved with the activity.
   * @url {base}/activities/{activity}/mapoutput
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async mapOutput(activityId) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + MAP_OUTPUT, null, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Transforming the response of the implementation to format as needed by the instance.
   * Only possible if an output mapping is available and if the processing of the implementation is 'OK’.
   * The result of the mapping is saved with the activity.
   * @url {base}/activities/{activity}/outputdata
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getOutputData(activityId) {
    return this.OMNIMAP.get(ACTIVITY + "/" + activityId + OUTPUT_DATA, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Set the output data of an activity
   * @url {base}/activities/{activity}/outputdata
   * @param activityId: The unique identification of the activity.
   * @param data: The data of the activity to be created.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async setOutputData(activityId, data) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + OUTPUT_DATA, data, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get all available output media of an activity.
   * @url {base}/activities/{activity}/outputmedia
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getOutputMedia(activityId) {
    return this.OMNIMAP.get(ACTIVITY + "/" + activityId + OUTPUT_MEDIA, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Set the output media of an activity.
   * @url {base}/activities/{activity}/outputmedia
   * @param activityId: The unique identification of the activity.
   * @param content
   * @param contentRole
   * @param contentType
   * @returns {Promise<AxiosResponse<any>>}
   */
  async setOutputMedium(activityId, content, contentRole, contentType) {
    return this.OMNIMAP.post(
      ACTIVITY + "/" + activityId + OUTPUT_MEDIA,
      {
        content,
        contentRole,
        contentType,
      },
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get a specific output media of an activity.
   * @url {base}/activities/{activity}/outputmedia/{medium}
   * @param activityId: The unique identification of the activity.
   * @param mediumId: The unique identification of the medium.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getOutputMedium(activityId, mediumId) {
    return this.OMNIMAP.get(
      ACTIVITY + "/" + activityId + OUTPUT_MEDIA + "/" + mediumId,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Delete a specific output medium belonging to an activity.
   * * @url {base}/activities/{activity}/outputmedia/{medium}
   * @param activityId: The unique identification of the activity.
   * @param mediumId: The unique identification of the medium.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async deleteOutputMedia(activityId, mediumId) {
    return this.OMNIMAP.delete(
      ACTIVITY + "/" + activityId + OUTPUT_MEDIA + mediumId,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Refresh the activity as if starting over
   * @url {base}/activities/{activity}/refresh
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async refresh(activityId) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + REFRESH, null, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Get detailed information.
   * @url {base}/activities/{activity}/snooze
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getSnoozedActivity(activityId) {
    return this.OMNIMAP.get(ACTIVITY + "/" + activityId + SNOOZE, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Start an activity using the available data.
   * @url {base}/activities/{activity}/start
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async startActivity(activityId) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + START, null, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Attempt to validate the activity
   * @url {base}/activities/{activity}/validate
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async validateActivity(activityId) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + VALIDATE, null, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Snoozing a activity will the deadlines and warnings from reports.
   * @url {base}/activities/{activity}/snooze
   * @param activityId: The unique identification of the activity.
   * @param until: is a ISO-8601 (UTC) format, for example 2016-03-10T11:24:59Z
   * @returns {Promise<AxiosResponse<any>>}
   */
  async snoozeActivity(activityId, until) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + SNOOZE, until, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Wake a activity from a snooze / sleep.
   * @url {base}/activities/{id}/wake
   * @param activityId: Unique identification of the activity to be waked.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async wakeActivity(activityId) {
    return this.OMNIMAP.post(ACTIVITY + "/" + activityId + WAKE, null, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Attempt to validate activity. If no auto-start or validation passes, return activity. Otherwise return error.
   * @url {base}/activities/{activity}/await-validation
   * @param activityId: The unique identification of the activity.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getActivityAfterValidation(activityId) {
    return this.OMNIMAP.get(ACTIVITY + "/" + activityId + AWAIT_VALIDATION, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Notify the process to update
   * @url {base}/activities/notify
   * @param notification
   */
  async notify(notification) {
    return this.OMNIMAP.post(ACTIVITY + NOTIFY, notification, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /***
   * Get details of an activity
   * @param activityId
   * @returns {Promise<*>}
   */
  async getActivityDetails(activityId) {
    return this.OMNIMAP.get(ACTIVITY + "/" + activityId + DETAILS, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }
}
