import axios from "axios";
import authentication from "../authentication";
import { ServiceException } from "@/utils/ErrorHandler";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";
import IdUtils from "../utils/IdUtils";

const INSTANCE_RESOURCE = "/instances/";
const FLOW_RESOURCE = "/flow/";
const DEFINITION_RESOURCE = "definitions/";
const STAGES_RESOURCE = "stages/";
const START_AND_EXECUTE_RESOURCE = "/start-and-execute";

export class ProcessServices {
  constructor(organisation) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisation) {
      this.setOrganisation(organisation);
    }
  }

  setOrganisation(organisation) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisation;
  }

  /**
   * Function to get the current FLOW state of the given instance process.
   *
   * @param instanceId
   * @return {Promise<AxiosResponse<T> | never>}
   */
  async getInstanceProcessStatus(instanceId) {
    if (!IdUtils.isUUID(instanceId)) {
      throw new ServiceException("No instance id given");
    }

    return this.OMNIMAP.get(INSTANCE_RESOURCE + instanceId + FLOW_RESOURCE, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    }).then((response) => validateApiOkResponse(response));
  }

  /**
   * Gets a specific instance stage.
   *
   * @param instanceId
   * @param stageId
   * @return {Promise<AxiosResponse<T> | never>}
   */
  async getStage(instanceId, stageId) {
    return this.OMNIMAP.get(
      INSTANCE_RESOURCE +
        instanceId +
        FLOW_RESOURCE +
        STAGES_RESOURCE +
        stageId,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }

  /**
   * Automatically start & execute the provided instance stage.
   *
   * @param instanceId
   * @param stageDefinitionId
   * @param inputData
   * @return {Promise<AxiosResponse<T> | never>}
   */
  async startAndExecuteStage(instanceId, stageDefinitionId, inputData = {}) {
    const data = { activity_input_strategy: "INPUT" };
    if (inputData && inputData !== {} && Object.keys(inputData)?.length) {
      data.activity_input_data = inputData;
    }

    return this.OMNIMAP.post(
      INSTANCE_RESOURCE +
        instanceId +
        FLOW_RESOURCE +
        DEFINITION_RESOURCE +
        stageDefinitionId +
        START_AND_EXECUTE_RESOURCE,
      data,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
          "Content-Type": "application/json",
        },
      }
    ).then((response) => validateApiOkResponse(response));
  }
}
