import axios from "axios";
import { ServiceException } from "@/utils/ErrorHandler";
export class MatchboxServices {
  constructor(bearer) {
    this.MATCHBOX = axios.create({
      baseURL: process.env.VUE_APP_MATCHBOX_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearer,
      },
    });
  }

  async getCandidates(options, filters, pageSize, pageNumber) {
    options.language = "DUT";
    const from = pageSize * pageNumber - pageSize;
    const data = {
      matchProfile: options,
      from: from,
      size: pageSize,
      queryParams: {
        scoreType: "MUTUAL_FORWARD_SCORE",
      },
      userLanguage: "DUT",
    };

    if (Object.keys(filters).length) {
      data.matchFilter = filters;
    }
    const url = "hrmatching/match2candidates/amsterdam-candidates";

    return this.MATCHBOX.post(url, JSON.stringify(data))
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new ServiceException("Unable to retrieve candidates");
        }
      })
      .catch((error) => {
        throw new ServiceException(error);
      });
  }

  async getJobs(payload, filter, pageSize, pageNumber, minScore = 0.0) {
    payload.language = "DUT";
    const from = pageSize * pageNumber - pageSize;

    const data = {
      matchProfile: payload,
      matchFilter: filter,
      minScore: minScore,
      from: from,
      size: pageSize,
      queryParams: {
        scoreType: "MUTUAL_FORWARD_SCORE",
      },
      userLanguage: "DUT",
    };

    const url = "/hrmatching/match2jobs/amsterdam-jobs";

    return this.MATCHBOX.post(url, JSON.stringify(data))
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new ServiceException("Unable to retrieve jobs");
        }
      })
      .catch((error) => {
        throw new ServiceException(error);
      });
  }

  async getMatchProfileFromJob(jobIdentification) {
    const url = "/hrmatching/jobs_index/amsterdam-jobs/doc/";

    return this.MATCHBOX.get(url + jobIdentification + "?language=DUT")
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else if (response.status === 404) {
          return response.status;
        } else {
          throw new ServiceException(
            "Unable to retrieve match profile from job"
          );
        }
      })
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async getJobFromCandidate(candidateId) {
    const url = "/hrmatching/candidates_index/amsterdam-candidates/doc/";

    return this.MATCHBOX.get(url + candidateId + "?language=DUT")
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else if (response.status === 404) {
          return response.status;
        } else {
          throw new ServiceException(
            "Unable to retrieve match profile from candidate"
          );
        }
      })
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async findPrefix(payload) {
    const url = "ontology/findPrefix?filterOnIndex=false";
    return this.MATCHBOX.post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new ServiceException("Unable to search for prefix");
        }
      })
      .catch((error) => {
        throw new ServiceException(error);
      });
  }
}
