import axios from "axios";
import authentication from "../authentication";
import { ServiceException } from "@/utils/ErrorHandler";
import {
  validateApiOkResponse,
  validateApiSearchResponse,
} from "@/utils/ApiResponseValidator";

/**
 * Declare all resources
 */
const INSTANCE_RESOURCE = "/generic/instances/";
const CHAT_RESOURCE_END = "/chat";

export class ChatServices {
  /**
   * Construct axios
   * @param organisationId
   */
  constructor(organisationId) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisationId) {
      this.setOrganisation(organisationId);
    }
  }

  setOrganisation(organisation) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisation;
  }

  /**
   * Get chat by id
   *
   * @param chatId
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  async retrieveChat(instanceId) {
    return this.OMNIMAP.get(
      INSTANCE_RESOURCE + instanceId + CHAT_RESOURCE_END,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Get chat by id
   *
   * @param chatId
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  async retrieveChatByPage(payload) {
    const url =
      INSTANCE_RESOURCE +
      payload.instanceId +
      CHAT_RESOURCE_END +
      "?page-size=" +
      payload.pageSize +
      "&page-number=" +
      payload.pageNumber;

    return this.OMNIMAP.get(url, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Create chat for an instance
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  async createChat(instanceId, message) {
    return this.OMNIMAP.post(
      INSTANCE_RESOURCE + instanceId + CHAT_RESOURCE_END,
      { message: message },
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Update a chat by id
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  async updateChat(payload) {
    return this.OMNIMAP.put(
      INSTANCE_RESOURCE +
        payload.instanceId +
        CHAT_RESOURCE_END +
        "/" +
        payload.messageId,
      { message: payload.message },
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   *  Remove a chat by id
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  async removeChat(instanceId, messageId) {
    return this.OMNIMAP.delete(
      INSTANCE_RESOURCE + instanceId + CHAT_RESOURCE_END + "/" + messageId,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }
}
