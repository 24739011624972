import Oidc from "oidc-client";

import Vue from "vue";

/**
 * Reference for oidc-client used in this class https://github.com/IdentityModel/oidc-client-js/wiki
 */

let globalUserManager = null;
let globalSilentRenewalInterval = null;

export default {
  userManager: null,
  initialized: false,
  redirected: false,
  signingOut: false,

  async initialize() {
    if (!this.initialized) {
      this.userManager = getUserManager();
      this.initialized = true;
    }
  },

  async signIn(timeoutId, url) {
    getDomain(url);
    getUserManager()
      .signinRedirect()
      .then(() => clearTimeout(timeoutId))
      .catch((err) => console.error(err));
  },

  signOut() {
    this.signingOut = true;
    localStorage.removeItem("identityProviderId");
    localStorage.removeItem("identityProvider");

    if (globalSilentRenewalInterval) {
      clearInterval(globalSilentRenewalInterval);
      globalSilentRenewalInterval = null;
    }

    this.userManager
      .removeUser()
      .catch((e) => console.warn("Could not remove user"));

    this.userManager.signoutRedirect().catch((err) => {
      Vue.$router.push({
        name: "GoTo",
        params: { tenantId: process.env.VUE_APP_TENANT_IDENTIFICATION },
      });
      console.error(err);
    });
  },

  async acquireOmnimapToken() {
    let user;
    let id_token;
    try {
      user = await this.userManager.getUser();
      if (user) {
        id_token = user.id_token;
      }
    } catch (e) {
      console.error("Could not determine auth user", e);
    }

    if (!id_token && !this.redirected && !this.signingOut) {
      // No valid id token, redirecting to idp
      this.redirected = true;

      if (!localStorage.getItem("identityProvider")) {
        this.signOut();
      } else {
        this.userManager.signinRedirect().catch((err) => console.error(err));
      }
    }

    // Throw error here to avoid firing requests with no bearer token if no id token is found when
    // this function is called in the Services
    if (id_token) {
      return id_token;
    } else {
      throw new Error("No id token found");
    }
  },

  async completeSilentRenew() {
    return getUserManager().signinSilentCallback();
  },

  async completeAuthentication(url) {
    // We initialize the user manager with event handling and silent renewal when authentication is
    // is complete.
    getDomain(url);
    await this.initialize();
    this.setEventHandlers();

    await this.userManager.signinRedirectCallback();

    // Force a silent renewal every 5 minutes
    try {
      if (!globalSilentRenewalInterval) {
        console.debug("Setting forced silent renewal interval");
        globalSilentRenewalInterval = setInterval(
          this.forcedSilentRenewalUsingRefreshToken,
          process.env.VUE_APP_FORCED_SILENT_RENEWAL_MILLISECONDS
        );
      }
    } catch (e) {
      console.error("Error setting silent renewal interval", e);
    }
  },

  forcedSilentRenewalUsingRefreshToken() {
    try {
      getUserManager()
        .getUser()
        .then((user) => {
          if (user && user.refresh_token) {
            console.debug("Forcing silent renewal using refresh token");
            getUserManager()._useRefreshToken({
              refresh_token: user.refresh_token,
            });
          } else {
            console.warn("No refresh token, update oauth2 response type");
          }
        });
    } catch (e) {
      console.warn("Silent renewal attempt failed", e);
    }
  },

  // Set all event handlers for authentication events
  setEventHandlers() {
    // The expiry events will only fire if an access token has been requested
    this.userManager.events.addAccessTokenExpiring(
      this.handleAccessTokenExpiring
    );
    this.userManager.events.addAccessTokenExpired(
      this.handleAccessTokenExpired
    );
    this.userManager.events.addUserLoaded(this.handleUserLoaded);
    this.userManager.events.addSilentRenewError(this.handleSilentRenewalError);
  },

  handleUserLoaded() {
    // Not relevant yet
  },

  handleAccessTokenExpiring() {
    // The silent renewal should be automatically subscribed to this event
    console.debug("Access token expiring event");
  },

  handleAccessTokenExpired() {
    // If the access token has expired the user needs to be redirected to login page
    getUserManager()
      .signinRedirect()
      .catch((e) => console.error(e.message));
  },

  handleSilentRenewalError() {
    // If the silent renewal failed the user needs to be redirected to login page
    console.error("Silent renewal error event");
    getUserManager()
      .signinRedirect()
      .catch((e) => console.error(e.message));
  },

  // Check if id token is expired
  isIdTokenExpired(id_token) {
    // Use internal jwt utils
    const payload = this.userManager._joseUtil.parseJwt(id_token).payload;
    const now = parseInt(Date.now() / 1000);
    const adjustedExpiry =
      payload.exp - process.env.VUE_APP_BEFORE_EXPIRY_ID_TOKEN_SECONDS;
    return adjustedExpiry < now;
  },

  async getUser() {
    if (this.userManager) {
      return await this.userManager.getUser();
    }
  },

  async checkActiveSessionForIdentityProvider(url) {
    getDomain(url);
    const identityProviderId = localStorage.getItem("identityProviderId");
    if (identityProviderId) {
      const identityProvider = JSON.parse(
        localStorage.getItem("identityProvider")
      );
      const timeoutId = setTimeout(this.noResponse, 3000, identityProvider);
      await this.signIn(timeoutId, url);
      return true;
    } else {
      return false;
    }
  },

  resetUserManager() {
    globalUserManager = null;
    this.userManager = null;
  },
};

let __redirectURL = null;
let __silentRedirectURL = null;

function getDomain(url) {
  // Some weird concurrency happening, therefore sometimes the url might not be found in store,
  // so we take it from sessionStorage
  if (url) {
    __redirectURL = url + "landingPage";
    __silentRedirectURL = url + "auth/silentRenew";
  } else if (sessionStorage.getItem("vanityDomain")) {
    __redirectURL = sessionStorage.getItem("vanityDomain") + "landingPage";
    __silentRedirectURL =
      sessionStorage.getItem("vanityDomain") + "auth/silentRenew";
  }
}

// Static method to create a user manager instance
function getUserManager() {
  const identityProvider = _getIdentityProvider();

  if (globalUserManager) {
    return globalUserManager;
  }

  if (identityProvider) {
    const oidcSettings = _getSettings(identityProvider);
    const mgr = new Oidc.UserManager(oidcSettings);
    _setCustomJwtValidation(mgr);

    // Just to be sure, set logger here
    // Oidc.Log.logger = console;
    globalUserManager = mgr;
    return mgr;
  } else {
    console.error("Identity provider not found");
  }
}

// Return the identity provider stored in the session storage
function _getIdentityProvider() {
  return localStorage.getItem("identityProvider")
    ? JSON.parse(localStorage.getItem("identityProvider"))
    : null;
}

// Create OIDC settings
function _getSettings(identityProvider) {
  const oidcSettings = {
    userStore: new Oidc.WebStorageStateStore(),
    client_id: identityProvider.client_id,
    authority: identityProvider.authority,
    redirect_uri: __redirectURL
      ? __redirectURL
      : process.env.VUE_APP_REDIRECT_URI,
    response_type: identityProvider.supported_response_type,
    silent_redirect_uri: __silentRedirectURL
      ? __silentRedirectURL
      : process.env.VUE_APP_SILENT_REDIRECT_URI,
    silentRequestTimeout: process.env.VUE_APP_SILENT_RENEW_TIMEOUT,
    accessTokenExpiringNotificationTime:
      process.env.VUE_APP_BEFORE_EXPIRY_SECONDS,
    loadUserInfo: false,
    scope: "profile openid",
    revokeAccessTokenOnSignout: true,
    post_logout_redirect_uri: window.location.origin,
  };

  if (
    identityProvider &&
    identityProvider.hasOwnProperty("data") &&
    identityProvider.data.hasOwnProperty("additional_config")
  ) {
    for (const key in identityProvider.data.additional_config) {
      if (identityProvider.data.additional_config.hasOwnProperty(key)) {
        oidcSettings[key] = identityProvider.data.additional_config[key];
      }
    }
  }
  return oidcSettings;
}

// Validation workaround for AD multitenancy
function _setCustomJwtValidation(mgr) {
  mgr._joseUtil.validateJwtAttributes = async function (
    jwt,
    issuer,
    audience,
    clockSkew,
    now,
    timeInsensitive
  ) {
    if (!clockSkew) {
      clockSkew = 0;
    }

    if (!now) {
      now = parseInt(Date.now() / 1000);
    }

    const payload = this.parseJwt(jwt).payload;

    if (!payload.iss) {
      Oidc.Log.error("JoseUtil._validateJwt: issuer was not provided");
      return Promise.reject(new Error("issuer was not provided"));
    }

    // The presence of this placeholder indicates multitenancy AD issuer like:
    // https://login.microsoftonline.com/{tenantid}/v2.0
    const microsoftIssuerPresent = issuer.includes(
      process.env.VUE_APP_AZURE_ISSUER_TID_PLACEHOLDER
    );

    if (microsoftIssuerPresent) {
      // Replace tenant id placeholder with tid in token
      issuer = issuer.replace("{tenantid}", payload.tid);
    }

    if (payload.iss !== issuer) {
      Oidc.Log.error(
        "JoseUtil._validateJwt: Invalid issuer in token",
        payload.iss
      );
      return Promise.reject(
        new Error("Invalid issuer in token: " + payload.iss)
      );
    }

    if (!payload.aud) {
      Oidc.Log.error("JoseUtil._validateJwt: aud was not provided");
      return Promise.reject(new Error("aud was not provided"));
    }
    const validAudience =
      payload.aud === audience ||
      (Array.isArray(payload.aud) && payload.aud.indexOf(audience) >= 0);
    if (!validAudience) {
      Oidc.Log.error(
        "JoseUtil._validateJwt: Invalid audience in token",
        payload.aud
      );
      return Promise.reject(
        new Error("Invalid audience in token: " + payload.aud)
      );
    }
    if (payload.azp && payload.azp !== audience) {
      Oidc.Log.error(
        "JoseUtil._validateJwt: Invalid azp in token",
        payload.azp
      );
      return Promise.reject(new Error("Invalid azp in token: " + payload.azp));
    }

    if (!timeInsensitive) {
      const lowerNow = now + clockSkew;
      const upperNow = now - clockSkew;

      if (!payload.iat) {
        Oidc.Log.error("JoseUtil._validateJwt: iat was not provided");
        return Promise.reject(new Error("iat was not provided"));
      }
      if (lowerNow < payload.iat) {
        Oidc.Log.error(
          "JoseUtil._validateJwt: iat is in the future",
          payload.iat
        );
        return Promise.reject(
          new Error("iat is in the future: " + payload.iat)
        );
      }

      if (payload.nbf && lowerNow < payload.nbf) {
        Oidc.Log.error(
          "JoseUtil._validateJwt: nbf is in the future",
          payload.nbf
        );
        return Promise.reject(
          new Error("nbf is in the future: " + payload.nbf)
        );
      }

      if (!payload.exp) {
        Oidc.Log.error("JoseUtil._validateJwt: exp was not provided");
        return Promise.reject(new Error("exp was not provided"));
      }
      if (payload.exp < upperNow) {
        Oidc.Log.error(
          "JoseUtil._validateJwt: exp is in the past",
          payload.exp
        );
        return Promise.reject(new Error("exp is in the past:" + payload.exp));
      }
    }
    return Promise.resolve(payload);
  };
}
