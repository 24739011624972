import Vue from "vue";
import { SearchServices } from "@/services/SearchServices";
import { StoreException } from "@/utils/ErrorHandler";
import {
  Roles,
  Identifications,
  Statuses,
  searchFields,
} from "@/utils/GeneralConstants";
import {
  LBATabs,
  LBAGeneralActions,
  LBAVoorrangskandidatenActions,
  TypeKandidaat,
} from "@/utils/GeneralConst/Loopbaanadvies";
import {
  TalentProfileTypes,
  TalentProfileStatuses,
} from "@/utils/GeneralConst/TalentProfile";
import {
  setTableFormatForLBAProfiles,
  mapToGetArrayOfIdsAndNames,
} from "@/utils/Utils/LoopbaanadviesUtils";
import { AanvraagNonShowActions } from "@/utils/GeneralConst/CapacityRequest";

const state = {
  searchServices: null,
  activeTab: "",
  currentFilter: "",
  currentSearch: "",
  fuzzySearch: true,
  pageNumberCurrentProfilesTab: 1,

  profilesSearchQuery: {},
  LBAContext: [
    {
      role: Roles.LOOPBAAN_ADVISEUR,
      start: new Date().toISOString(),
      and_or: "OR",
      direction: "from",
      instance_identifiers: [],
    },
  ],
  VoorangskandidatenContent: {
    values: [
      "boventallig",
      "pre-boventallig",
      "re-integratie",
      "administratief geplaatst",
    ],
    key: "zoekrichting-categorie",
    and_or: "AND",
    datatype: "STRING",
  },

  nrMyProfiles: null,
  nrVacanciesInMobilityPhase: null,

  queryForVacanciesInMobilityPhase: {
    statuses: [
      {
        and_or: "OR",
        status: Statuses.MOBILITEITSFASE.label,
      },
    ],
    sorting: [
      {
        sort_type: "timestamp_created",
        direction: "DESCENDING",
      },
    ],
    definitions: [Identifications.AANVRAAG_CAPACITEIT],
  },
  dataVacanciesInMobilityPhase: null,
  metaVacanciesInMobilityPhase: null,

  dataSelectedProfiles: null,
  metaSelectedProfiles: null,

  pageNrAllProfiles: 1,
  dataAllProfiles: {},
  metaAllProfiles: {},
  mappedDataAllProfiles: null,

  pageNrVoorangskandidaten: 1,
  dataVoorangskandidaten: {},
  metaVoorangskandidaten: {},
  mappedDataVoorangskandidaten: null,

  pageNrMyCandidates: 1,
  dataMyCandidates: {},
  metaMyCandidates: {},
  mappedDataMyCandidates: null,
};

const getters = {
  searchServices: (state) => state.searchServices,
  activeTab: (state) => state.activeTab,
  currentFilter: (state) => state.currentFilter,
  currentSearch: (state) => state.currentSearch,
  fuzzySearch: (state) => state.fuzzySearch,
  pageNumberCurrentProfilesTab: (state) => state.pageNumberCurrentProfilesTab,

  profilesSearchQuery: (state) => state.profilesSearchQuery,
  LBAContext: (state) => state.LBAContext,
  VoorangskandidatenContent: (state) => state.VoorangskandidatenContent,

  nrMyProfiles: (state) => state.nrMyProfiles,
  nrVacanciesInMobilityPhase: (state) => state.nrVacanciesInMobilityPhase,

  queryForVacanciesInMobilityPhase: (state) =>
    state.queryForVacanciesInMobilityPhase,
  dataVacanciesInMobilityPhase: (state) => state.dataVacanciesInMobilityPhase,
  metaVacanciesInMobilityPhase: (state) => state.metaVacanciesInMobilityPhase,

  dataSelectedProfiles: (state) => state.dataSelectedProfiles,
  metaSelectedProfiles: (state) => state.metaSelectedProfiles,

  pageNrAllProfiles: (state) => state.pageNrAllProfiles,
  dataAllProfiles: (state) => state.dataAllProfiles,
  metaAllProfiles: (state) => state.metaAllProfiles,
  mappedDataAllProfiles: (state) => state.mappedDataAllProfiles,

  pageNrVoorangskandidaten: (state) => state.pageNrVoorangskandidaten,
  dataVoorangskandidaten: (state) => state.dataVoorangskandidaten,
  metaVoorangskandidaten: (state) => state.metaVoorangskandidaten,
  mappedDataVoorangskandidaten: (state) => state.mappedDataVoorangskandidaten,

  pageNrMyCandidates: (state) => state.pageNrMyCandidates,
  dataMyCandidates: (state) => state.dataMyCandidates,
  metaMyCandidates: (state) => state.metaMyCandidates,
  mappedDataMyCandidates: (state) => state.mappedDataMyCandidates,
};

const actions = {
  /**
   * Internal action for initializing and returning the SearchServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.searchServices|(function(*): (null|getters.searchServices|(function(*))))|null>}
   */
  getSearchServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.searchServices) {
        commit(
          "SET_SEARCH_SERVICES",
          new SearchServices(rootGetters.organisationId)
        );
      }

      return getters.searchServices;
    } catch (error) {
      throw new StoreException("getSearchServices: " + error.message);
    }
  },

  /* LOOPBAANADVIES: PROFIELEN */

  /**
   * Fetches the number of the talent profiles assigned to a logged loopbaanadviseur.
   * @param getters
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  getNrMyProfiles: async ({ getters, dispatch, commit }) => {
    try {
      // Determine which tab is currently active in order to set the right query
      commit("SET_ACTIVE_TAB", LBATabs.ALLE_PROFIELEN);
      await dispatch("setCorrectSearchQuery", { filter: null });

      const searchServices = await dispatch("getSearchServices");

      // Retrieve the instances based on the created query and page
      const { meta } = await searchServices.postInstanceQuery(
        process.env.VUE_APP_GLOBAL_PAGE_SIZE,
        process.env.VUE_APP_GLOBAL_PAGE_NUMBER,
        getters.profilesSearchQuery
      );
      commit("SET_NUMBER_MY_PROFILES", meta.total_found);
      commit("SET_META_MY_CANDIDATES", meta);
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Sets currently active tab in order to show the corresponding data.
   * @param commit
   * @param tab
   * @returns {Promise<void>}
   */
  setActiveTab: async ({ commit }, { tab = null }) => {
    try {
      if (!tab) {
        tab = LBATabs.ALLE_PROFIELEN;
      }
      // Checks if the submitted tab name is correct in reference to the constants file
      if (Object.values(LBATabs).indexOf(tab) < 0) {
        console.error("The name of the tab is incorrect. Try again.");
      }
      await commit("SET_ACTIVE_TAB", tab);
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Set correct search query to fetch the talent profiles' data meeting the criteria determined by the tab name and supplied by a user.
   * @param rootGetters
   * @param getters
   * @param commit
   * @param filter
   * @param searchTerm
   * @returns {Promise<void>}
   */
  setCorrectSearchQuery: async (
    { rootGetters, getters, commit },
    { filter, searchTerm }
  ) => {
    try {
      const query = {
        definitions: [Identifications.TALENT_PROFIEL],
        statuses: [
          { status: TalentProfileStatuses.ACTIEF, and_or: "OR" },
          { status: TalentProfileStatuses.TER_AKKOORD, and_or: "OR" },
          { status: TalentProfileStatuses.AANVULLING_NODIG, and_or: "OR" },
        ],
      };

      // Sets the query parameters (context, content and statuses) based on the current active tab
      switch (getters.activeTab) {
        case LBATabs.ALLE_PROFIELEN:
          delete query.context;
          delete query.content;
          break;
        case LBATabs.VOORANGSKANDIDATEN:
          query.content = [getters.VoorangskandidatenContent];
          delete query.context;
          break;
        case LBATabs.MIJN_KANDIDATEN:
          query.context = getters.LBAContext;
          query.context[0].instance_identifiers = [rootGetters.userId];
          delete query.statuses;
          delete query.content;
          break;
        default:
          break;
      }

      // Checks if the submitted filter is correct based on the list saved in the constants file
      if (filter && Object.values(TalentProfileTypes).indexOf(filter) < 0) {
        console.error("Submitted filter is incorrect. Try again.");
      }

      // Sets new value of the filter in the store
      if (filter) {
        commit("SET_CURRENT_FILTER", filter);
      } else {
        commit("SET_CURRENT_FILTER", null);
      }

      // Sets the content of the query depending on the active tab – allActions tab has also other required content in the query
      if (filter) {
        query.content = [
          {
            values: [filter],
            key: "algemeen-type",
            and_or: "AND",
            datatype: "STRING",
          },
        ];
      } else if (!filter) {
        delete query.content;
      }

      if (filter && getters.activeTab === LBATabs.VOORANGSKANDIDATEN) {
        query.content = [
          getters.VoorangskandidatenContent,
          {
            values: [filter],
            key: "algemeen-type",
            and_or: "AND",
            datatype: "STRING",
          },
        ];
      } else if (!filter && getters.activeTab === LBATabs.VOORANGSKANDIDATEN) {
        query.content = [getters.VoorangskandidatenContent];
      }

      // Checks if the submitted searchTerm is of the right type
      if (searchTerm && typeof searchTerm !== "string") {
        console.error("Submitted search term is incorrect. Try again.");
      }

      // Sets new value of the searchTerm in the store
      if (searchTerm) {
        query.search_query = searchTerm;
        commit("SET_CURRENT_SEARCH", searchTerm);
      } else {
        delete query.search_query;
        commit("SET_CURRENT_SEARCH", null);
      }

      // Sets new value of the search query in the store
      commit("SET_PROFILE_SEARCH_QUERY", query);
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Fetches the talent profiles' data based on the search query (set with the function above).
   * @param rootGetters
   * @param getters
   * @param dispatch
   * @param commit
   * @param filter
   * @param searchTerm
   * @param fuzzySearch
   * @returns {Promise<void>}
   */
  fetchTheData: async (
    { getters, dispatch, commit },
    { filter, searchTerm, fuzzySearch }
  ) => {
    try {
      // Checks if fuzzySearch variable is of the right type
      if (fuzzySearch && typeof fuzzySearch !== "boolean") {
        console.error("Submitted search type is incorrect. Try again.");
      }
      // Assigns the value to the current filter considering user's input and filter's state in the store
      const thisFilter = filter ? filter : getters.currentFilter;
      // Assigns the value to the current search term considering user's input and search term's state in the store
      const thisSearch = searchTerm ? searchTerm : getters.currentSearch;

      // Calls the function to set the correct query for the current data-fetching call
      await dispatch("setCorrectSearchQuery", {
        filter: thisFilter,
        searchTerm: thisSearch,
      });

      // Assigns the value to the current fuzzySearch considering user's input and fuzzySearch's state in the store
      const thisFuzzySearch = fuzzySearch ? fuzzySearch : getters.fuzzySearch;
      // Updates the value of the fuzzySearch in the store
      commit("SET_FUZZY_SEARCH", thisFuzzySearch);

      // Sets the number of the page to fetch depending on the user's input and active tab
      let pageNumber = 0;
      switch (getters.activeTab) {
        case LBATabs.ALLE_PROFIELEN:
          pageNumber = getters.pageNrAllProfiles;
          break;
        case LBATabs.VOORANGSKANDIDATEN:
          pageNumber = getters.pageNrVoorangskandidaten;
          break;
        case LBATabs.MIJN_KANDIDATEN:
          pageNumber = getters.pageNrMyCandidates;
          break;
        default:
          break;
      }

      // Gets the rest of the parameters from the store (including the newly-set query)
      const pageSize = process.env.VUE_APP_GLOBAL_PAGE_SIZE;
      const query = getters.profilesSearchQuery;

      const searchServices = await dispatch("getSearchServices");
      const { data, meta } = await searchServices.postInstanceQuery(
        pageSize,
        pageNumber,
        query,
        thisFuzzySearch,
        searchFields
      );

      // Sets the data object of the active tab with the fetched data
      let mappedProfilesData;
      if (data) {
        const mapped = data.map((item) => ({ [item.identification]: item }));
        mappedProfilesData = Object.assign({}, ...mapped);
      }
      await commit("SET_META_SELECTED_PROFILES", meta);
      switch (getters.activeTab) {
        case LBATabs.ALLE_PROFIELEN:
          await commit(
            "SET_DATA_ALL_PROFILES",
            mappedProfilesData ? mappedProfilesData : {}
          );
          await commit("SET_META_ALL_PROFILES", meta);
          break;
        case LBATabs.VOORANGSKANDIDATEN:
          await commit(
            "SET_DATA_VOORANGSKANDIDATEN",
            mappedProfilesData ? mappedProfilesData : {}
          );
          await commit("SET_META_VOORANGSKANDIDATEN", meta);
          break;
        case LBATabs.MIJN_KANDIDATEN:
          await commit(
            "SET_DATA_MY_CANDIDATES",
            mappedProfilesData ? mappedProfilesData : {}
          );
          await commit("SET_META_MY_CANDIDATES", meta);
          break;
        default:
          break;
      }

      // Calls the function to map the data so it can be displayed in the table
      await dispatch("mapTheData");

      // Calls the function to set the data of currently viewed tab (profiles segment)
      await dispatch("setDataSelectedProfiles");
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Maps the data object to turn it into an array formatted properly for el-table.
   * @param commit
   * @param getters
   * @returns {Promise<void>}
   */
  mapTheData: async ({ commit, getters }) => {
    try {
      let array;
      // Selects the right data object (based on the active tab) and turn it into an array of values

      switch (getters.activeTab) {
        case LBATabs.ALLE_PROFIELEN:
          array = Object.values(getters.dataAllProfiles);
          await commit(
            "SET_MAPPED_DATA_ALL_PROFILES",
            setTableFormatForLBAProfiles(array)
          );
          break;
        case LBATabs.VOORANGSKANDIDATEN:
          array = Object.values(getters.dataVoorangskandidaten);
          await commit(
            "SET_MAPPED_DATA_VOORANGSKANDIDATEN",
            setTableFormatForLBAProfiles(array)
          );
          break;
        case LBATabs.MIJN_KANDIDATEN:
          array = Object.values(getters.dataMyCandidates);
          await commit(
            "SET_MAPPED_DATA_MY_CANDIDATES",
            setTableFormatForLBAProfiles(array)
          );
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Cancels the filter currently applied on the data.
   * @param commit
   * @returns {Promise<void>}
   */
  cancelAppliedFilter: async ({ commit, dispatch }) => {
    try {
      // Cancels the current filter
      await commit("SET_CURRENT_FILTER", null);
      // Calls the search function to repeat the call with the updated parameters
      await dispatch("fetchTheData", { filter: null });
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Cancels the search currently applied on the data.
   * @param commit
   * @returns {Promise<void>}
   */
  cancelAppliedSearch: async ({ commit, dispatch, getters }) => {
    try {
      // Cancels the current searchTerm and sets the fuzzySearch to default (not exact)
      await commit("SET_CURRENT_SEARCH", null);
      await commit("SET_FUZZY_SEARCH", true);
      // Calls the search function to repeat the call with the updated parameters
      await dispatch("fetchTheData", { filter: getters.currentFilter });
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Sets the exactness of the search call (fuzzy is not exact).
   * @param commit
   * @param dispatch
   * @param getters
   * @returns {Promise<void>}
   */
  setFuzzySearch: async ({ commit, dispatch, getters }) => {
    try {
      // Sets the value of the fuzzySearch to the opposite value
      const currentFuzzy = getters.fuzzySearch;
      await commit("SET_FUZZY_SEARCH", !currentFuzzy);
      // Calls the search function to repeat the call with the updated parameters
      await dispatch("fetchTheData", { filter: null });
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Sets the page number based on user's input and fetches the data from a given page.
   * @param commit
   * @param dispatch
   * @param getters
   * @param pageNumber
   * @returns {Promise<void>}
   */
  setPageNumber: async ({ commit, dispatch, getters }, pageNumber) => {
    try {
      // Checks if the submitted pageNumber is of the correct type
      if (typeof pageNumber !== "number") {
        console.error("Submitted page number is incorrect. Try again.");
      }
      // Sets the value of the current page for a certain tab (currently active one)
      switch (getters.activeTab) {
        case LBATabs.ALLE_PROFIELEN:
          await commit("SET_PAGE_NR_ALL_PROFILES", pageNumber);
          break;
        case LBATabs.VOORANGSKANDIDATEN:
          await commit("SET_PAGE_NR_VOORANGSKANDIDATEN", pageNumber);
          break;
        case LBATabs.MIJN_KANDIDATEN:
          await commit("SET_PAGE_NR_MY_CANDIDATES", pageNumber);
          break;
        default:
          break;
      }
      // Sets the page number for the current tab in the Profiles Overview of LBA
      await commit("SET_PAGE_NR_CURRENT_PROFILES_TAB", pageNumber);
      // Calls the search function to repeat the call with the updated parameters
      await dispatch("fetchTheData", { filter: null });
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Cancels the filter and the search currently applied on the data.
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  resetTheView: async ({ dispatch, commit }) => {
    try {
      // Cancels the current filter, the searchTerm and sets the fuzzySearch to default (not exact)
      await commit("SET_CURRENT_FILTER", null);
      await commit("SET_CURRENT_SEARCH", null);
      await commit("SET_FUZZY_SEARCH", true);
      // Calls the search function to repeat the call with the updated parameters
      await dispatch("fetchTheData", { filter: null });
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Step 1: Sets the data of all the profiles based on the currently viewed tab and selected data manipulation.
   * Step 2: Formats the data to receive an object of IDs and full names of the profile owners.
   * @param commit
   * @param getters
   * @param dispatch
   * @returns {Promise<void>}
   */
  setDataSelectedProfiles: async ({ commit, getters, dispatch }) => {
    const pageSize = getters.metaSelectedProfiles.total_found;
    const pageNumber = process.env.VUE_APP_GLOBAL_PAGE_NUMBER;
    const query = getters.profilesSearchQuery;
    const thisFuzzySearch = getters.fuzzySearch;

    if (pageSize > 0) {
      // Fetches the data of all the profiles reusing the query that was just set for the fetchTheData() function
      // Only the page size has been replaced with the meta.total_found
      const searchServices = await dispatch("getSearchServices");
      const { data } = await searchServices.postInstanceQuery(
        pageSize,
        pageNumber,
        query,
        thisFuzzySearch,
        searchFields
      );
      // Sets the data of selected profiles with formatted data object (IDs and names)
      commit(
        "SET_DATA_SELECTED_PROFILES",
        data ? mapToGetArrayOfIdsAndNames(data) : null
      );
    } else {
      commit("SET_DATA_SELECTED_PROFILES", null);
    }
  },

  /* LOOPBAANADVIES VACATURES */

  /**
   * Fetches the data and meta of vacancies in the mobility phase.
   * @param rootGetters
   * @param dispatch
   * @param getters
   * @param commit
   * @returns {Promise<void>}
   */
  getVacanciesInMobilityPhase: async ({ dispatch, getters, commit }) => {
    try {
      const searchServices = await dispatch("getSearchServices");

      const query = getters.queryForVacanciesInMobilityPhase;
      const { meta, data } = await searchServices.postInstanceQuery(
        process.env.VUE_APP_GLOBAL_PAGE_SIZE,
        process.env.VUE_APP_GLOBAL_PAGE_NUMBER,
        query
      );

      if (data) {
        commit(
          "SET_DATA_VACANCIES_IN_MOBILITY_PHASE",
          data.reduce(
            (obj, cur) => ({
              ...obj,
              [cur.identification]: cur,
            }),
            {}
          )
        );
      }
      commit("SET_NUMBER_VACANCIES_IN_MOBILITY_PHASE", meta.total_found);
      commit("SET_META_VACANCIES_IN_MOBILITY_PHASE", meta);
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Gets the actions assigned to a certain vacancy.
   * @param dispatch
   * @param vacatureId
   * @returns {Promise<*>}
   */
  getVacancyActions: async ({ dispatch }, vacatureId) => {
    try {
      // Fetches the actions of one instance based on that instance's ID
      const [allActions, specialActions, otherActions] = await dispatch(
        "instanceFlow/getActions",
        vacatureId,
        {
          root: true,
        }
      );

      if (otherActions) {
        // Map the actions array so it meets the OmniTable requirements
        const mappedActions = otherActions.map((action) => {
          return {
            key: action.identification,
            label: action.definition.name,
            id: action.identification,
            definitionId: action.definition.identification,
          };
        });
        return mappedActions.filter(
          (action) =>
            !Object.values(AanvraagNonShowActions).includes(action.definitionId)
        );
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Fetches the actions assigned to a particular profile instance based on that instance's ID
   * @param dispatch
   * @param requestId
   * @returns {Promise<*>}
   */
  getProfileActions: async ({ dispatch }, profileId, profileType) => {
    try {
      // Fetches the actions of one profile instance based on that instance's ID.
      const [allActions, specialActions, otherActions] = await dispatch(
        "instanceFlow/getActions",
        profileId,
        {
          root: true,
        }
      );

      let actions;
      // Filters the actions to receive a list of actions available for a certain profile type.
      if (otherActions) {
        if (profileType === TypeKandidaat.VOORANGSKANDIDAAT) {
          actions = otherActions.filter((action) => {
            return Object.values(LBAVoorrangskandidatenActions).includes(
              action.definition.identification
            );
          });
        } else {
          actions = otherActions.filter((action) => {
            return Object.values(LBAGeneralActions).includes(
              action.definition.identification
            );
          });
        }
      }

      if (actions) {
        return actions.map((action) => {
          return {
            key: action.identification,
            label: action.definition.name,
            id: action.identification,
          };
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  SET_SEARCH_SERVICES: (state, payload) =>
    Vue.set(state, "searchServices", payload),
  SET_ACTIVE_TAB: (state, payload) => Vue.set(state, "activeTab", payload),
  SET_CURRENT_FILTER: (state, payload) =>
    Vue.set(state, "currentFilter", payload),
  SET_CURRENT_SEARCH: (state, payload) =>
    Vue.set(state, "currentSearch", payload),
  SET_FUZZY_SEARCH: (state, payload) => Vue.set(state, "fuzzySearch", payload),
  SET_PAGE_NR_CURRENT_PROFILES_TAB: (state, payload) =>
    Vue.set(state, "pageNumberCurrentProfilesTab", payload),

  SET_PROFILE_SEARCH_QUERY: (state, payload) =>
    Vue.set(state, "profilesSearchQuery", payload),

  SET_NUMBER_MY_PROFILES: (state, payload) =>
    Vue.set(state, "nrMyProfiles", payload),
  SET_NUMBER_VACANCIES_IN_MOBILITY_PHASE: (state, payload) =>
    Vue.set(state, "nrVacanciesInMobilityPhase", payload),

  SET_DATA_VACANCIES_IN_MOBILITY_PHASE: (state, payload) =>
    Vue.set(state, "dataVacanciesInMobilityPhase", payload),
  SET_META_VACANCIES_IN_MOBILITY_PHASE: (state, payload) =>
    Vue.set(state, "metaVacanciesInMobilityPhase", payload),

  SET_DATA_SELECTED_PROFILES: (state, payload) =>
    Vue.set(state, "dataSelectedProfiles", payload),
  SET_META_SELECTED_PROFILES: (state, payload) =>
    Vue.set(state, "metaSelectedProfiles", payload),

  SET_PAGE_NR_ALL_PROFILES: (state, payload) =>
    Vue.set(state, "pageNrAllProfiles", payload),
  SET_DATA_ALL_PROFILES: (state, payload) =>
    Vue.set(state, "dataAllProfiles", payload),
  SET_META_ALL_PROFILES: (state, payload) =>
    Vue.set(state, "metaAllProfiles", payload),
  SET_MAPPED_DATA_ALL_PROFILES: (state, payload) =>
    Vue.set(state, "mappedDataAllProfiles", payload),

  SET_PAGE_NR_VOORANGSKANDIDATEN: (state, payload) =>
    Vue.set(state, "pageNrVoorangskandidaten", payload),
  SET_DATA_VOORANGSKANDIDATEN: (state, payload) =>
    Vue.set(state, "dataVoorangskandidaten", payload),
  SET_META_VOORANGSKANDIDATEN: (state, payload) =>
    Vue.set(state, "metaVoorangskandidaten", payload),
  SET_MAPPED_DATA_VOORANGSKANDIDATEN: (state, payload) =>
    Vue.set(state, "mappedDataVoorangskandidaten", payload),

  SET_PAGE_NR_MY_CANDIDATES: (state, payload) =>
    Vue.set(state, "pageNrMyCandidates", payload),
  SET_DATA_MY_CANDIDATES: (state, payload) =>
    Vue.set(state, "dataMyCandidates", payload),
  SET_META_MY_CANDIDATES: (state, payload) =>
    Vue.set(state, "metaMyCandidates", payload),
  SET_MAPPED_DATA_MY_CANDIDATES: (state, payload) =>
    Vue.set(state, "mappedDataMyCandidates", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
