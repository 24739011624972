import axios from "axios";
import authentication from "../authentication";
import { ServiceException } from "@/utils/ErrorHandler";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";

const CONFIGURATION_RESOURCE = "/definitions/configuration/";
const CLIENT_RESOURCE = "client/" + process.env.VUE_APP_CLIENT + "/";
const CODE_RESOURCE = "code/";
const REFERENCE_RESOURCE = "reference/";

export class ConfigurationServices {
  /**
   * Construct axios
   * @param organisation
   */
  constructor(organisation) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisation) {
      this.setOrganisation(organisation);
    }
  }

  setOrganisation(organisation) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisation;
  }

  async getConfigByReference(payload) {
    if (
      !payload ||
      !payload.hasOwnProperty("code") ||
      !payload.hasOwnProperty("reference")
    ) {
      throw new ServiceException(
        "Missing required parameter to retrieve configuration by reference"
      );
    }
    return this.OMNIMAP.get(
      CONFIGURATION_RESOURCE +
        CLIENT_RESOURCE +
        CODE_RESOURCE +
        payload.code +
        "/" +
        REFERENCE_RESOURCE +
        payload.reference +
        "/",
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async createConfig(payload) {
    if (
      !payload ||
      !payload.hasOwnProperty("code") ||
      !payload.hasOwnProperty("description") ||
      !payload.hasOwnProperty("reference") ||
      !payload.hasOwnProperty("data")
    ) {
      throw new ServiceException(
        "Missing required parameter to create a configuration"
      );
    }

    payload["client_identification"] = process.env.VUE_APP_CLIENT;
    return this.OMNIMAP.post(CONFIGURATION_RESOURCE, payload, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async updateConfig(payload) {
    if (
      !payload ||
      !payload.hasOwnProperty("code") ||
      !payload.hasOwnProperty("description") ||
      !payload.hasOwnProperty("reference") ||
      !payload.hasOwnProperty("data")
    ) {
      throw new ServiceException(
        "Missing required parameter to update a configuration"
      );
    }

    payload["client_identification"] = process.env.VUE_APP_CLIENT;

    return this.OMNIMAP.put(
      CONFIGURATION_RESOURCE + payload.identification,
      payload,
      {
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }
}
