import { ServiceException } from "./ErrorHandler";
const invalid = "Invalid response status";

export function validateApiOkResponse(response) {
  // Check response status code
  if (response.status !== 200) {
    throw new ServiceException(invalid);
  }

  if (response.data instanceof Blob) {
    return response.data;
  }

  // Check for Omnimap response structure
  if (!("result" in response.data)) {
    return response.data;
  }

  // Check api response code
  if (
    response.data.result.status === "OK" ||
    response.data.result.status === "INFO"
  ) {
    return response.data.data;
  } else if (response.data.result.status === "WARNING" && response.data.data) {
    return response.data.data;
  } else if (response.data.result.status === "ERROR") {
    if (response.data.result.errors.length === 1) {
      throw new ServiceException(response.data.result.errors[0].detail);
    } else {
      throw new ServiceException("Multiple errors detected");
    }
  } else {
    throw new ServiceException(response.data.result.detail);
  }
}

export function validateApiAcceptedResponse(response) {
  // Check response status code
  if (response.status !== 202) {
    throw new ServiceException(invalid);
  }

  // No response object for accepted responses
  return true;
}

export function validateApiSearchResponse(response) {
  // Check response status code
  if (response.status !== 200) {
    throw new ServiceException(invalid);
  }

  // Check api response code
  if (
    response.data.result.status === "OK" ||
    response.data.result.status === "INFO"
  ) {
    return {
      meta: response.data.meta,
      data: response.data.data,
    };
  } else if (response.data.result.status === "ERROR") {
    if (response.data.result.errors.length === 1) {
      throw new ServiceException(response.data.result.errors[0].detail);
    } else {
      throw new ServiceException("Multiple errors detected");
    }
  } else {
    throw new ServiceException(response.data.result.detail);
  }
}
