import Vue from "vue";
import { EmailTemplateServices } from "@/services/EmailTemplateServices";
import { StoreException } from "@/utils/ErrorHandler";

const state = {
  emailTemplateServices: null,
};

const getters = {
  emailTemplateServices: (state) => state.emailTemplateServices,
};

const actions = {
  /**
   * Internal action for initializing and returning the EmailTemplateServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.mediaServices|(function(*): (null|getters.mediaServices|(function(*))))|null>}
   */
  getEmailTemplateServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.emailTemplateServices) {
        if ("" === rootGetters.tenantId) {
          throw new StoreException("No tenant id known.");
        } else {
          commit(
            "SET_EMAIL_TEMPLATE_SERVICES",
            new EmailTemplateServices(rootGetters.organisationId)
          );
        }
      }

      return getters.emailTemplateServices;
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise the organisation id will be set on creation.
   * @param dispatch
   * @param rootGetters
   * @return {Promise<void>}
   */
  updateEmailTemplateServices: ({ getters, rootGetters }) => {
    try {
      const emailTemplateServices = getters.emailTemplateServices;
      if (emailTemplateServices) {
        emailTemplateServices.setOrganisation(rootGetters.organisationId);
      }
    } catch (error) {
      console.error(error);
    }
  },

  retrieveEmailTemplate: async ({ dispatch }, templateId) => {
    const emailTemplateServices = await dispatch("getEmailTemplateServices");
    try {
      return await emailTemplateServices.getEmailTemplate(templateId);
    } catch (error) {
      throw new StoreException(
        "Something went wrong while retrieving file: \n" + error.message
      );
    }
  },
};

const mutations = {
  SET_EMAIL_TEMPLATE_SERVICES: (state, payload) =>
    Vue.set(state, "emailTemplateServices", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
