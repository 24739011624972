import axios from "axios";
import { ServiceException } from "@/utils/ErrorHandler";
import { validateApiOkResponse } from "@/utils/ApiResponseValidator";

const DOMAIN_RESOURCE = "security/tenants/domain";

export class DomainServices {
  constructor() {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
    });
  }

  async retrieveTenantByDomain(domain) {
    return this.OMNIMAP.post(DOMAIN_RESOURCE, domain)
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        console.error(error);
        throw new ServiceException(error.message);
      });
  }
}
