import { Identifications, Statuses } from "@/utils/GeneralConstants";

const ID = {
  label: "ID",
  value: "identifier",
  type: "STRING",
};
const publicatietitel = {
  value: "content.functie-publicatietitel",
  label: "Publicatietitel",
  type: "STRING",
};
const startontvangen = {
  value: "content.data-startontvangen",
  label: "Start ontvangen",
  type: "DATE-TIME",
};
const bomedewerker = {
  value: "content.data-bomedewerker",
  label: "Behandelaar",
  type: "STRING",
};
const vakgebied = {
  value: "content.functie-vakgebied",
  label: "Vakgebied",
  type: "STRING",
};
const directie = {
  value: "content.organisatiegegevens-directie",
  label: "Directie/stadsdeel",
  type: "STRING",
};
const status = {
  label: "Status",
  type: "STRING",
  value: "status",
};
const werktitel = {
  value: "content.functie-werktitel-display-value",
  label: "Werktitel",
  type: "STRING",
};
const typeaanvraag = {
  key: "soortaanvraag-typeaanvraag",
  and_or: "AND",
  datatype: "STRING",
};
const nieuweMedewerker = {
  ...typeaanvraag,
  values: ["nieuwe medewerker"],
};
const verlenging = {
  ...typeaanvraag,
  values: ["verlenging"],
};
const stagiairs = {
  ...typeaanvraag,
  values: ["stagiairs"],
};

const nieuweMedewerkerColumns = [
  ID,
  publicatietitel,
  startontvangen,
  {
    value: "content.soortaanvraag-typecapaciteit",
    label: "Welk type capaciteit vraag je aan?",
    type: "STRING",
  },
  status,
  bomedewerker,
  vakgebied,
  directie,
];
const stagesColumns = [
  ID,
  startontvangen,
  werktitel,
  {
    value: "content.soortaanvraag-typestage",
    label: "Welk type stage vraag je aan?",
    type: "STRING",
  },
  status,
  bomedewerker,
  directie,
  vakgebied,
];
const sortingStartOntvangen = [
  {
    sort_type: "content",
    direction: "DESCENDING",
    field: "data-startontvangen",
  },
];
export const RecruitmentQueries = Object.freeze({
  ALLES: {
    label: "Alles",
    query: {
      definitions: [Identifications.AANVRAAG_CAPACITEIT],
      content: [],
    },
    value: "5bba704e-edc1-490e-ace9-5ee09490c3be",
    columns: [
      ID,
      publicatietitel,
      werktitel,
      startontvangen,
      status,
      bomedewerker,
    ],
  },
  NIEUWE_MEDEWERKER_ALLES: {
    label: "Nieuwe medewerker (Alles)",
    query: {
      content: [nieuweMedewerker],
      context: [],
      statuses: [],
      definitions: [Identifications.AANVRAAG_CAPACITEIT],
      sorting: [],
    },
    value: "758ede30-2c24-423a-b0f1-d3a86c06839c",
    columns: nieuweMedewerkerColumns,
  },
  NIEUWE_MEDEWERKERS_LOPEND: {
    label: "Nieuwe medewerker (Lopend)",
    value: "4aea704e-edc1-490e-ace9-5ee09490c3be",
    query: {
      content: [nieuweMedewerker],
      context: [],
      statuses: [
        {
          status: "aanvullen wervingstekst",
          and_or: "OR",
        },
        {
          status: "voorlopig ingevuld",
          and_or: "OR",
        },
        {
          status: "accorderen wervingstekst",
          and_or: "OR",
        },
        {
          status: "aanvulling nodig",
          and_or: "OR",
        },
        {
          status: "gepubliceerd",
          and_or: "OR",
        },
        {
          status: "in behandeling",
          and_or: "OR",
        },
        {
          status: "mobiliteitsfase",
          and_or: "OR",
        },
        {
          status: "nieuw",
          and_or: "OR",
        },
        {
          status: "ter publicatie",
          and_or: "OR",
        },
        {
          status: "ter akkoord",
          and_or: "OR",
        },
      ],
      definitions: [Identifications.AANVRAAG_CAPACITEIT],
      sorting: [],
    },
    columns: nieuweMedewerkerColumns,
  },
  STAGES_ALLES: {
    label: "Stages (Alles)",
    value: "76c24c1b-f1e7-412f-896f-17d818346357",
    query: {
      content: [stagiairs],
      context: [],
      statuses: [],
      definitions: [Identifications.AANVRAAG_CAPACITEIT],
      sorting: sortingStartOntvangen,
    },
    columns: stagesColumns,
  },
  STAGES_LOPEND: {
    label: "Stages (Lopend)",
    value: "b770725c-cb7f-480c-8df6-99305e15077d",
    query: {
      content: [stagiairs],
      context: [],
      statuses: [
        {
          status: "aanvullen wervingstekst",
          and_or: "OR",
        },
        {
          status: "accorderen wervingstekst",
          and_or: "OR",
        },
        {
          status: "aanvulling nodig",
          and_or: "OR",
        },
        {
          status: "gepubliceerd",
          and_or: "OR",
        },
        {
          status: "in behandeling",
          and_or: "OR",
        },
        {
          status: "nieuw",
          and_or: "OR",
        },
        {
          status: "ter publicatie",
          and_or: "OR",
        },
        {
          status: "ter akkoord",
          and_or: "OR",
        },
        {
          status: "voorlopig ingevuld",
          and_or: "OR",
        },
      ],
      definitions: [Identifications.AANVRAAG_CAPACITEIT],
      sorting: sortingStartOntvangen,
    },
    columns: stagesColumns,
  },
  VERLENGINGEN_ALLES: {
    label: "Verlengingen (Alles)",
    query: {
      content: [verlenging],
      context: [],
      statuses: [],
      definitions: [Identifications.AANVRAAG_CAPACITEIT],
      sorting: sortingStartOntvangen,
    },
    value: "425cc3cf-f6a8-4045-bb78-0952d97115c8",
    columns: [
      ID,
      startontvangen,
      {
        value: "content.soortaanvraag-typeverlenging",
        label: "Voor welk soort capaciteit wil je een verlenging indienen?",
        type: "STRING",
      },
      {
        value: "content.contractgegevens-naamkandidaat",
        label: "Naam kandidaat",
        type: "STRING",
      },
      status,
      {
        value: "content.contractgegevens-startdatumverlenging",
        label: "Startdatum verlenging ",
        type: "DATE",
      },
      bomedewerker,
      vakgebied,
      directie,
    ],
  },
});

const LBAColumns = [
  ID,
  {
    value: "content.data-startbehandelenloopbaanadvies",
    label: "Start behandelen loopbaanadvies",
    type: "DATE-TIME",
  },
  publicatietitel,
  {
    value: "content.organisatiegegevens-cluster",
    label: "Cluster",
    type: "STRING",
  },
  directie,
  {
    value: "content.organisatiegegevens-afdeling",
    label: "Afdeling",
    type: "STRING",
  },
  {
    value: "content.soortaanvraag-typecapaciteit",
    label: "Welk type capaciteit vraag je aan?",
    type: "STRING",
  },
  {
    value: "content.data-functie-generiekefunctienaam",
    label: "Generieke functienaam",
    type: "STRING",
  },
  {
    value: "content.functie-schaal",
    label: "Schaal",
    type: "STRING",
  },
  {
    value: "content.wervingstekst-inhetkort-werkendenkniveau",
    label: "Werk- en denkniveau",
    type: "STRING",
  },
  {
    value: "content.wervingstekst-inhetkort-aantalfte",
    label: "Aantal fte",
    type: "NUMBER",
  },
  status,
  {
    value: "content.kandidaten-supermatchernaam",
    label: "Supermatcher naam",
    type: "STRING",
  },
];
const LBASorting = [
  {
    sort_type: "content",
    direction: "DESCENDING",
    field: "data-startbehandelenloopbaanadvies",
  },
];

export const LoopbaanadviesQueries = Object.freeze({
  ALLE_VACATURES: {
    label: "Alle vacatures",
    query: {
      content: [nieuweMedewerker],
      context: [],
      statuses: [],
      definitions: [Identifications.AANVRAAG_CAPACITEIT],
      sorting: LBASorting,
    },
    value: "932b4732-736b-4562-8e2d-e6908fd969f1",
    columns: LBAColumns,
  },
  VACATURES_IN_MOBILITEITFASE: {
    label: "Mobiliteitsfase",
    query: {
      content: [],
      context: [],
      statuses: [
        {
          status: Statuses.MOBILITEITSFASE.label,
          and_or: "OR",
        },
        {
          status: Statuses.VOORLOPIG_INGEVULD.label,
          and_or: "OR",
        },
      ],
      definitions: [Identifications.AANVRAAG_CAPACITEIT],
      sorting: LBASorting,
    },
    value: "7897026a-b3c9-40f6-8377-17cec195cd2a",
    columns: LBAColumns,
  },
});

export const MyRequestsQueries = Object.freeze({
  LOPENDE: {
    content: [
      {
        values: ["nieuwe medewerker", "stagiairs"],
        key: "soortaanvraag-typeaanvraag",
        and_or: "AND",
        datatype: "STRING",
      },
    ],
    context: [],
    statuses: [
      {
        status: "aanvullen wervingstekst",
        and_or: "OR",
      },
      {
        status: "aanvulling nodig",
        and_or: "OR",
      },
      {
        status: "accorderen wervingstekst",
        and_or: "OR",
      },
      {
        status: "concept",
        and_or: "OR",
      },
      {
        status: "gepubliceerd",
        and_or: "OR",
      },
      {
        status: "in behandeling",
        and_or: "OR",
      },
      {
        status: "mobiliteitsfase",
        and_or: "OR",
      },
      {
        status: "ter akkoord",
        and_or: "OR",
      },
      {
        status: "nieuw",
        and_or: "OR",
      },
      {
        status: "ter publicatie",
        and_or: "OR",
      },
      {
        status: "voorlopig ingevuld",
        and_or: "OR",
      },
    ],
    definitions: [Identifications.AANVRAAG_CAPACITEIT],
    sorting: [{ sort_type: "timestamp_created", direction: "DESCENDING" }],
  },
  ALLE: {
    content: [
      {
        values: ["nieuwe medewerker", "stagiairs"],
        key: "soortaanvraag-typeaanvraag",
        and_or: "AND",
        datatype: "STRING",
      },
    ],
    context: [],
    statuses: [],
    definitions: [Identifications.AANVRAAG_CAPACITEIT],
    sorting: [{ sort_type: "timestamp_created", direction: "DESCENDING" }],
  },
  VERLENGINGEN: {
    content: [
      {
        values: ["verlenging"],
        key: "soortaanvraag-typeaanvraag",
        and_or: "AND",
        datatype: "STRING",
      },
    ],
    context: [],
    statuses: [],
    definitions: [Identifications.AANVRAAG_CAPACITEIT],
    sorting: [{ sort_type: "timestamp_created", direction: "DESCENDING" }],
  },
  MANAGER: {
    definitions: [Identifications.AANVRAAG_CAPACITEIT],
    sorting: [{ sort_type: "timestamp_created", direction: "DESCENDING" }],
    context: [
      {
        role: "Manager",
        instance_identifiers: [],
        direction: "from",
        and_or: "OR",
        start: new Date().toISOString(),
      },
    ],
    text_search: null,
    statuses: [],
  },
  ALLE_LOSSE: {
    content: [],
    context: [],
    statuses: [],
    definitions: [Identifications.LOSSE_VACATURE],
    sorting: [{ sort_type: "timestamp_created", direction: "DESCENDING" }],
  },
});
