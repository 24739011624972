import axios from "axios";
import authentication from "@/authentication";
import {
  validateApiOkResponse,
  validateApiSearchResponse,
} from "@/utils/ApiResponseValidator";
import { ServiceException } from "@/utils/ErrorHandler";

const INSTANCE_RESOURCE = "/generic/instances/";
const INSTANCE_INCLUDE = "?include=";

export class OmniselectServices {
  constructor(organisation) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisation) {
      this.setOrganisation(organisation);
    }
  }

  setOrganisation(organisation) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisation;
  }

  async getFromUrl(url) {
    return this.OMNIMAP.get(url, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async getInstance({ instanceId, properties }) {
    let url;
    if (properties) {
      url = INSTANCE_RESOURCE + instanceId + INSTANCE_INCLUDE + properties;
    } else {
      url = INSTANCE_RESOURCE + instanceId;
    }

    return this.OMNIMAP.get(url, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiOkResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }
}
