import Vue from "vue";
import { SearchServices } from "@/services/SearchServices";
import { InstanceDefinitionServices } from "@/services/InstanceDefinitionServices";
import { ProcessServices } from "@/services/ProcessServices";
import { GenericInstanceServices } from "@/services/GenericInstanceServices";
import { Identifications } from "@/utils/GeneralConstants";
import { StoreException } from "@/utils/ErrorHandler";
import IdUtils from "@/utils/IdUtils";
import {
  searchProfileQuery,
  TalentProfileStatuses,
  TalentProfileActions,
} from "@/utils/GeneralConst/TalentProfile";

const state = {
  searchServices: null,
  instanceDefinitionServices: null,
  genericInstanceServices: null,
  processServices: null,

  isAbleToPostInstances: null,
  isAbleToCreateAProfile: null,

  profileIdentification: null,
  profileData: null,
  JSONSchemaTalentProfile: undefined,
  JSONDataTalentProfile: null,
};

const getters = {
  searchServices: (state) => state.searchServices,
  instanceDefinitionServices: (state) => state.instanceDefinitionServices,
  genericInstanceServices: (state) => state.genericInstanceServices,
  processServices: (state) => state.processServices,

  isAbleToPostInstances: (state) => state.isAbleToPostInstances,
  isAbleToCreateAProfile: (state) => state.isAbleToCreateAProfile,

  profileIdentification: (state) => state.profileIdentification,
  profileData: (state) => state.profileData,
  JSONSchemaTalentProfile: (state) => state.JSONSchemaTalentProfile,
  JSONDataTalentProfile: (state) => state.JSONDataTalentProfile,
};

const actions = {
  /**
   * Internal action for initializing and returning the ProcessServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.processServices|(function(*): (null|getters.processServices|(function(*))))|null>}
   */
  getProcessServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.processServices) {
        commit(
          "SET_PROCESS_SERVICES",
          new ProcessServices(rootGetters.organisationId)
        );
      }

      return getters.processServices;
    } catch (error) {
      throw new StoreException("getProcessServices: " + error.message);
    }
  },

  /**
   * Internal action for initializing and returning the SearchServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.searchServices|(function(*): (null|getters.searchServices|(function(*))))|null>}
   */
  getSearchServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.searchServices) {
        commit(
          "SET_SEARCH_SERVICES",
          new SearchServices(rootGetters.organisationId)
        );
      }
      return getters.searchServices;
    } catch (error) {
      throw new StoreException("getSearchServices: " + error.message);
    }
  },

  /**
   * Internal action for initializing and returning the GenericInstanceServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.tenantServices|(function(*): (null|getters.tenantServices|(function(*))))|null>}
   */
  getGenericInstanceServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.genericInstanceServices) {
        commit(
          "SET_GENERIC_INSTANCE_SERVICES",
          new GenericInstanceServices(rootGetters.organisationId)
        );
      }
      return getters.genericInstanceServices;
    } catch (error) {
      throw new StoreException("getGenericInstanceServices: " + error.message);
    }
  },

  /**
   * Finds the talent profile ID based on the user ID
   * @param commit
   * @param dispatch
   * @param getters
   * @param rootGetters
   * @param userId
   * @returns {Promise<void>}
   */
  findProfileId: async (
    { commit, dispatch, rootGetters },
    { userId = null }
  ) => {
    try {
      const searchServices = await dispatch("getSearchServices");

      // Determines user ID based on the supplied variable
      const Id = userId ? userId : rootGetters.userId;
      // Updates the searchProfileQuery with user ID
      const query = JSON.parse(JSON.stringify(searchProfileQuery));

      query.context[0].instance_identifiers = [Id];
      // sort so that the latest profile is on top. Just in case a user somehow has more than one profile
      query.sorting = [
        { sort_type: "timestamp_created", direction: "DESCENDING" },
      ];

      // Posts the instance query
      const result = await searchServices.postInstanceQuery(
        process.env.VUE_APP_GLOBAL_PAGE_SIZE,
        process.env.VUE_APP_GLOBAL_PAGE_NUMBER,
        query
      );
      // Sets profile ID based on the response
      if (result.data && result.data.length > 0) {
        commit("SET_PROFILE_ID", result.data[0].identification);
        return result.data[0].identification;
      } else {
        commit("SET_PROFILE_ID", null);
        commit("SET_PROFILE_DATA", null);
      }
    } catch (error) {
      throw new StoreException("findProfileId: " + error.message);
    }
  },

  /**
   * Retrieves the TalentProfile of the provided or logged-in user.
   * @param commit
   * @param dispatch
   * @param getters
   * @param profileId
   * @return {Promise<void>}
   */
  fetchProfileData: async ({ commit, dispatch, getters }, profileId = null) => {
    // Fetches profile ID in case no ID is supplied or available in the store
    try {
      if (!getters.profileIdentification && !profileId) {
        await dispatch("findProfileId");
      }

      // Determines profile ID based on the supplied variable
      const id = profileId ? profileId : getters.profileIdentification;
      // Sets the profile ID in the store (in case there was none)
      await commit("SET_PROFILE_ID", id);

      // Fetches profile data based on profile ID and updates the value in the store
      if (id) {
        if (!IdUtils.isUUID(id)) {
          console.error(
            "No valid instance identification found. Unable to retrieve instance."
          );
          return;
        }
        const genericInstanceServices = await dispatch(
          "getGenericInstanceServices"
        );
        const result = await genericInstanceServices.getGenericInstance(id);

        commit("SET_JSON_DATA_TALENT_PROFILE", result.data.content);
        commit("SET_PROFILE_DATA", result);
      }

      // Fetch the whole profile instance using the profile ID
      if (profileId) {
        const genericInstanceServices = getters.genericInstanceServices;
        const profileResult = await genericInstanceServices.getGenericInstance(
          profileId
        );
        commit("SET_JSON_DATA_TALENT_PROFILE", profileResult.data.content);
        commit("SET_PROFILE_DATA", profileResult);
        return profileResult;
      }
    } catch (error) {
      throw new StoreException("fetchProfileData: " + error.message);
    }
  },

  /**
   * Saves the new talent profile instance and displays the profile info on the page
   * @param commit
   * @param dispatch
   * @param newProfileData
   * @returns {Promise<*>}
   */
  createAndFetchNewProfile: async ({ commit, dispatch }, newProfileData) => {
    try {
      // Posts a new instance of the talent profile
      const genericInstanceServices = await dispatch(
        "getGenericInstanceServices"
      );
      const result = await genericInstanceServices.postGenericInstance(
        newProfileData
      );
      // Sets a profile ID of the new profile
      const identification = result.identification;
      commit("SET_PROFILE_ID", identification);
      commit("SET_PROFILE_DATA", result);
      // Retrieves the profile by calling the function
      return await dispatch("fetchProfileData");
    } catch (error) {
      throw new StoreException("createAndFetchNewProfile: " + error.message);
    }
  },

  startAndExecuteStatusAction: async ({ getters, dispatch }, status) => {
    if (!status) {
      throw new StoreException("Status niet gevonden");
    }
    if (
      !getters.profileData ||
      !getters.profileData.data ||
      !getters.profileData.data.identification
    ) {
      throw new StoreException("Geen profiel gevonden");
    }

    const { bewerkt: edited, goedgekeurd: approved } =
      getters.profileData.data.content?.data;

    let stageDefinitionIdentification = "";
    let payload = {};
    switch (status) {
      case TalentProfileStatuses.NIEUW:
        stageDefinitionIdentification = TalentProfileActions.INDIENENPROFIEL;
        payload = {
          indienen: true,
          actief: "Ja",
        };
        break;
      case TalentProfileStatuses.GEWIJZIGD:
      case TalentProfileStatuses.AANVULLING_NODIG:
        stageDefinitionIdentification =
          TalentProfileActions.INDIENENWIJZIGINGEN;
        payload = {
          indienen: true,
          actief: "Ja",
        };
        break;
      case TalentProfileStatuses.INACTIEF:
      case TalentProfileStatuses.VERLOPEN:
        stageDefinitionIdentification = TalentProfileActions.ACTIVERENPROFIEL;
        payload = {
          ditprofielkanwordengeactiveerd: true,
          status:
            edited === "Nee" && approved === "Ja" ? "ACTIEF" : "TER AKKOORD",
        };
        break;
      case TalentProfileStatuses.ACTIEF:
        stageDefinitionIdentification = TalentProfileActions.DEACTIVERENPROFIEL;
        payload = {
          deactiveren: true,
          status: "INACTIEF",
        };
        break;
      default:
        break;
    }

    const processServices = await dispatch("getProcessServices");
    try {
      await processServices.startAndExecuteStage(
        getters.profileData.data.identification,
        stageDefinitionIdentification,
        payload
      );
    } catch (error) {
      throw new StoreException(
        "Er gaat iets mis met het uitvoeren van deze actie"
      );
    }
  },

  /**
   * Saves job alert settings of given user
   * @param dispatch
   * @param userId
   * @param vakgebieden
   * @param frequency
   * @param werkendenkniveau
   * @param dienstverband_typevacature
   * @returns {Promise<void>}
   */
  startAndExecuteJobAlert: async (
    { dispatch },
    {
      userId,
      vakgebieden,
      frequency,
      werkendenkniveau,
      dienstverband_typevacature,
    }
  ) => {
    if (
      userId &&
      frequency &&
      (vakgebieden?.length ||
        werkendenkniveau?.length ||
        dienstverband_typevacature?.length)
    ) {
      const processServices = await dispatch("getProcessServices");
      try {
        await processServices.startAndExecuteStage(
          userId,
          Identifications.STAGE_DEFINITION_JOBALERT_BIJWERKEN,
          {
            vakgebieden,
            frequency,
            werkendenkniveau,
            dienstverband_typevacature,
          }
        );
      } catch (error) {
        throw new StoreException(
          "Something went wrong while updating job alert settings"
        );
      }
    }
  },

  /**
   * Resets job alert settings of given user and unsubscribe them from job alerts
   * @param dispatch
   * @param userId
   * @returns {Promise<void>}
   */
  startAndExecuteUnsubscribe: async ({ dispatch }, { userId }) => {
    if (userId) {
      const processServices = await dispatch("getProcessServices");
      try {
        await processServices.startAndExecuteStage(
          userId,
          Identifications.STAGE_DEFINITION_JOBALERT_AFMELDEN
        );
      } catch (error) {
        throw new StoreException(
          "Something went wrong while unsubscribing from job alert"
        );
      }
    }
  },

  /**
   * Clears profile data and JSON profile data
   * @param getters
   * @param commit
   */
  clearProfile: ({ getters, commit }) => {
    if (getters.profileData) {
      commit("SET_PROFILE_DATA", null);
    }
    if (getters.JSONDataTalentProfile) {
      commit("SET_JSON_DATA_TALENT_PROFILE", null);
    }
  },
};

const mutations = {
  SET_SEARCH_SERVICES: (state, payload) =>
    Vue.set(state, "searchServices", payload),
  SET_INSTANCE_DEFINITION_SERVICES: (state, payload) =>
    Vue.set(state, "instanceDefinitionServices", payload),
  SET_GENERIC_INSTANCE_SERVICES: (state, payload) =>
    Vue.set(state, "genericInstanceServices", payload),
  SET_PROCESS_SERVICES: (state, payload) =>
    Vue.set(state, "processServices", payload),

  SET_IS_ABLE_TO_POST_INSTANCES: (state, payload) =>
    Vue.set(state, "isAbleToPostInstances", payload),
  SET_IS_ABLE_TO_CREATE_A_PROFILE: (state, payload) =>
    Vue.set(state, "isAbleToCreateAProfile", payload),
  SET_PROFILE_ID: (state, payload) =>
    Vue.set(state, "profileIdentification", payload),
  SET_PROFILE_DATA: (state, payload) => Vue.set(state, "profileData", payload),
  SET_JSON_SCHEMA_TALENT_PROFILE: (state, payload) =>
    Vue.set(state, "JSONSchemaTalentProfile", payload),
  SET_JSON_DATA_TALENT_PROFILE: (state, payload) =>
    Vue.set(state, "JSONDataTalentProfile", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
