import Vue from "vue";
import { MediaServices } from "@/services/MediaServices";
import { StoreException } from "@/utils/ErrorHandler";
import { FileExtentions } from "@/utils/GeneralConstants";
import IdUtils from "@/utils/IdUtils";
import { mediaDefinitions } from "@/utils/GeneralConst/CapacityRequest";

const state = {
  mediaServices: null,
  media: [],
  omnimedia: [],
  omnimediaIds: [],
  mediaToBeDeleted: [],
};

const getters = {
  mediaServices: (state) => state.mediaServices,
  media: (state) => state.media,
  omnimedia: (state) => state.omnimedia,
  omnimediaIds: (state) => state.omnimediaIds,
  mediaToBeDeleted: (state) => state.mediaToBeDeleted,
};

const actions = {
  /**
   * Internal action for initializing and returning the MediaServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.mediaServices|(function(*): (null|getters.mediaServices|(function(*))))|null>}
   */
  getMediaServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.mediaServices) {
        if ("" === rootGetters.tenantId) {
          throw new StoreException("No tenant id known.");
        } else {
          commit(
            "SET_MEDIA_SERVICES",
            new MediaServices(rootGetters.organisationId)
          );
        }
      }

      return getters.mediaServices;
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise the organisation id will be set on creation.
   *
   * @param dispatch
   * @param rootGetters
   * @return {Promise<void>}
   */
  updateMediaServices: ({ getters, rootGetters }) => {
    try {
      const mediaServices = getters.mediaServices;
      if (mediaServices) {
        mediaServices.setOrganisation(rootGetters.organisationId);
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Retrieve media by instance id
   *
   * @param commit
   * @param getters
   * @param dispatch
   * @param fileId
   * @returns media
   */
  retrieveMedia: async (
    { commit, dispatch },
    { instanceId, pageSize, pageNumber }
  ) => {
    const mediaServices = await dispatch("getMediaServices");
    try {
      const media = await mediaServices.getMedia(
        instanceId,
        pageSize,
        pageNumber
      );
      commit("SET_MEDIA", media);
      return media;
    } catch (error) {
      throw new StoreException(
        "Something went wrong while retrieving file: \n" + error.message
      );
    }
  },

  retrieveFile: async ({ getters, dispatch }, { fileId, pdf = true }) => {
    const mediaServices = await dispatch("getMediaServices");
    try {
      const selectedMedia = getters.media.data.find(
        (media) => media.identification === fileId
      );

      const hasPdf = !!(selectedMedia?.pdf_version_available && pdf);

      return await mediaServices.retrieveFile({ fileId: fileId, pdf: hasPdf });
    } catch (error) {
      throw new StoreException(
        "Something went wrong while retrieving file: \n" + error.message
      );
    }
  },

  deleteMedia: async ({ dispatch }, medium_identification) => {
    if (medium_identification) {
      try {
        const mediaServices = await dispatch("getMediaServices");
        await mediaServices.deleteMedia(medium_identification);
      } catch (error) {
        throw new StoreException(
          "Something went wrong while trying to delete media file: \n" +
            error.message
        );
      }
    }
  },

  createMedia: async ({ dispatch }, { instanceId, metadata, file }) => {
    const mediaServices = await dispatch("getMediaServices");

    if (!instanceId) {
      throw new StoreException("No instance id given.");
    } else if (!metadata.name) {
      throw new StoreException("No name given.");
      // } else if (!metadata.definition_code) {
    } else if (!metadata.relationships.definition) {
      throw new StoreException("No medium definition given.");
    } else if (!file) {
      throw new StoreException("No files given.");
    } else {
      // Check metadata (mimetype)
      if (!metadata.mime_type) {
        // Check for common file extensions not supported by upload component
        const fileExt = metadata.name.slice(metadata.name.lastIndexOf(".") + 1);
        if (FileExtentions[fileExt]) {
          metadata.mime_type = FileExtentions[fileExt];
        } else {
          throw new StoreException("Unsupported media type");
        }
      }

      try {
        // Post metadata
        metadata.path = metadata.path ? metadata.path : "";
        const newMedium = await mediaServices.createMediaMetadata(
          instanceId,
          metadata
        );

        // Create formdata for files payload
        const formData = new FormData();
        formData.append("content", file);
        formData.append("contentName", metadata.name);
        formData.append("contentType", metadata.mime_type);
        formData.append("path", metadata.path);

        // Post media binary using the new medium metadata object
        await mediaServices.createMediaBinary(
          newMedium.identification,
          formData
        );
      } catch (error) {
        throw new StoreException(error);
      }
    }
  },

  createMediaV3: async ({ dispatch }, body) => {
    try {
      if (!body) {
        throw new StoreException("Invalid input");
      }
      const mediaServices = await dispatch("getMediaServices");
      await mediaServices.createMediaV3(body);
    } catch (error) {
      throw new StoreException(error);
    }
  },

  linkToConcept: async ({ dispatch }, { mediumIdentification, metadata }) => {
    if (!mediumIdentification || !metadata?.identification) {
      throw new StoreException("Invalid input");
    }
    try {
      const payload = {
        link_target: mediumIdentification,
        medium_identification: metadata.identification,
        link_type: "CONCEPT",
        path: "/",
        medium_definition: mediaDefinitions.AANVRAAG_BIJLAGE,
        metadata: metadata,
      };

      const mediaServices = await dispatch("getMediaServices");
      await mediaServices.linkToConcept(payload);
    } catch (error) {
      throw new StoreException(error);
    }
  },

  retrieveMediumMetadata: async ({ commit, getters, dispatch }, { fileId }) => {
    if (!IdUtils.isUUID(fileId)) {
      throw new StoreException("Invalid input");
    }
    try {
      const mediaServices = await dispatch("getMediaServices");
      return mediaServices.retrieveMediumMetadata(fileId);
    } catch (error) {
      throw new StoreException(
        "Something went wrong while retrieving metadata: \n" + error.message
      );
    }
  },

  setOmnimedia({ commit, getters }, payload) {
    if (!Array.isArray(payload)) {
      const media = getters.omnimedia.slice();
      media.push(payload);
      commit("SET_OMNIMEDIA", media);
    } else {
      commit("SET_OMNIMEDIA", payload);
    }
  },
  setOmnimediaId({ commit, getters }, payload) {
    if (!Array.isArray(payload)) {
      const ids = getters.omnimediaIds.slice();
      ids.push(payload);
      commit("SET_OMNIMEDIA_IDS", ids);
    } else {
      commit("SET_OMNIMEDIA_IDS", payload);
    }
  },
  removeOmnimediaId({ commit, getters }, payload) {
    let ids = getters.omnimediaIds.slice();
    ids = ids.filter((id) => id.id !== payload);
    commit("SET_OMNIMEDIA_IDS", ids);
  },
  setMediaToBeDeleted({ commit, getters }, payload) {
    if (!Array.isArray(payload)) {
      const ids = getters.mediaToBeDeleted.slice();
      ids.push(payload);
      commit("SET_DELETE_MEDIA", ids);
    } else {
      commit("SET_DELETE_MEDIA", payload);
    }
  },
};
const mutations = {
  SET_MEDIA_SERVICES: (state, payload) =>
    Vue.set(state, "mediaServices", payload),
  SET_MEDIA: (state, payload) => Vue.set(state, "media", payload),
  SET_OMNIMEDIA: (state, payload) => Vue.set(state, "omnimedia", payload),
  SET_OMNIMEDIA_IDS: (state, payload) =>
    Vue.set(state, "omnimediaIds", payload),
  SET_DELETE_MEDIA: (state, payload) =>
    Vue.set(state, "mediaToBeDeleted", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
