<template>
  <div id="app" :class="$mq">
    <router-view :key="$route.path" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "App",
    computed: {
      ...mapGetters("tenantStore", ["redirectURL"]),

      pageTitle: function () {
        return this.$route.meta.title;
      },
    },
    mounted: async function () {
      // Needed in case there's a vanity domain and its route is not "/"
      if (this.redirectURL) {
        await this.$router.push({ name: "GoTo" });
      }
    },
    beforeMount() {
      require("@/assets/scss/element-variables.scss");
      require("@/assets/scss/element-overwrite.scss");
      require("@/assets/scss/omniSchema.scss");
      require("@/assets/scss/styles.scss");
      require("vue-tour/dist/vue-tour.css");
      this.$i18n.locale = "nl";
    },
    watch: {
      $route(to) {
        document.title = to.meta.title
          ? to.meta.title + " · MijnW&S"
          : "MijnW&S";
      },
    },
  };
</script>

<style>
  @font-face {
    font-family: amsterdam sans;
    font-style: normal;
    font-weight: 300;
    src: url("assets/fonts/amsterdam/AmsterdamSans-Light.woff");
  }
  @font-face {
    font-family: amsterdam sans;
    font-style: normal;
    font-weight: 400;
    src: url("assets/fonts/amsterdam/AmsterdamSans-Regular.woff");
  }
  @font-face {
    font-family: amsterdam sans;
    font-style: italic;
    font-weight: 400;
    src: url("assets/fonts/amsterdam/AmsterdamSans-Italic.woff");
  }
  @font-face {
    font-family: amsterdam sans;
    font-style: normal;
    font-weight: 700;
    src: url("assets/fonts/amsterdam/AmsterdamSans-Bold.woff");
  }
  @font-face {
    font-family: amsterdam sans;
    font-style: italic;
    font-weight: 700;
    src: url("assets/fonts/amsterdam/AmsterdamSans-BoldItalic.woff");
  }
  @font-face {
    font-family: amsterdam sans;
    font-style: normal;
    font-weight: 800;
    src: url("assets/fonts/amsterdam/AmsterdamSans-ExtraBold.woff");
  }

  body {
    font-family: amsterdam sans, arial, sans-serif;
  }
</style>

<style lang="scss">
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    justify-content: center;
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  body {
    background: $neutral-grey0;
    margin: 0;

    font-family: $font-family;
  }
</style>
