import Vue from "vue";
import VueI18n from "vue-i18n";
import nl from "../assets/languages/nl";
import en from "../assets/languages/en";

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: "nl",
  fallbackLocale: "nl",
  messages: {
    nl: nl,
    en: en,
  },
});
