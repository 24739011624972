import Vue from "vue";
import { SearchServices } from "@/services/SearchServices";
import { StoreException } from "@/utils/ErrorHandler";
import { AanvraagNonShowActions } from "@/utils/GeneralConst/CapacityRequest";

const state = {
  searchServices: null,
  requestsNr: null,
};

const getters = {
  searchServices: (state) => state.searchServices,
};

const actions = {
  /**
   * Internal action for initializing and returning the SearchServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.searchServices|(function(*): (null|getters.searchServices|(function(*))))|null>}
   */
  getSearchServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.searchServices) {
        commit(
          "SET_SEARCH_SERVICES",
          new SearchServices(rootGetters.organisationId)
        );
      }
      return getters.searchServices;
    } catch (error) {
      throw new StoreException("getSearchServices: " + error.message);
    }
  },

  /**
   * Fetches the actions assigned to a particular instance based on that instance's ID
   * @param dispatch
   * @param requestId
   * @returns {Promise<*>}
   */
  getRequestActions: async ({ dispatch }, requestId) => {
    try {
      // Fetches the actions of one instance based on that instance's ID
      const [allActions, specialActions, otherActions] = await dispatch(
        "instanceFlow/getActions",
        requestId,
        {
          root: true,
        }
      );

      if (otherActions) {
        // Map the actions array so it meets the OmniTable requirements
        const mappedActions = otherActions.map((action) => {
          return {
            key: action.identification,
            label: action.definition.name,
            id: action.identification,
            definitionId: action.definition.identification,
          };
        });
        return mappedActions.filter(
          (action) =>
            !Object.values(AanvraagNonShowActions).includes(action.definitionId)
        );
      }
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  SET_SEARCH_SERVICES: (state, payload) =>
    Vue.set(state, "searchServices", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
