import {
  AanvraagNonShowActions,
  EndStatuses,
} from "@/utils/GeneralConst/CapacityRequest";
import { Roles } from "@/utils/GeneralConstants";
import store from "@/store";

/**
 * Checks for candidates of a request.
 * @param request
 * @param placeholder
 * @returns {Promise<*>}
 */
export const decideAction = async (request, placeholder = "urgentAction") => {
  const candidates = await store.dispatch("candidates/retrieveCandidates", {
    instanceId: request ? request.identification : null,
    fetchActions: false,
    updateStore: false,
  });

  if (
    Array.isArray(candidates) &&
    candidates.some(
      (candidate) => !EndStatuses.includes(candidate.status[0].status)
    )
  ) {
    //  If available candidates are found, show "Beoordelen kandidaten"
    request[placeholder] = "Beoordelen kandidaten";
  } else {
    // Check how the user is related to the capacity request
    if (request && request.relationships && request.relationships.context) {
      const userRelations = request.relationships.context
        .filter(
          (relation) =>
            relation.relationships.related_instance.identification ===
              store.getters["userId"] && !relation.until
        )
        .map((relation) => relation.role);

      if (userRelations && userRelations.includes !== Roles.SELECTIECOMMISSIE) {
        // Search for an action for any other role than selectiecommissie
        request = await selectAction(request, placeholder);
      }
    }
  }
  return request;
};

/**
 * Checks for actions of a request
 * @param request
 * @param placeholder
 * @returns {Promise<*>}
 */
export const selectAction = async (request, placeholder) => {
  const [all, special, others] = await store.dispatch(
    "instanceFlow/getActions",
    request.identification
  );

  request.actions = {
    flow: all,
    specialActions: special,
  };

  // Filter out non-required and non-created/started actions
  request.actions.actions = others.filter(
    (stage) =>
      (stage.state === "CREATED" || stage.state === "STARTED") &&
      !Object.values(AanvraagNonShowActions).includes(
        stage.definition.identification
      )
  );

  if (request.actions.actions.length && request.actions.actions[0].definition) {
    // If actions are available, select first action
    request[placeholder] = request.actions.actions[0].definition.name;
  }

  return request;
};
