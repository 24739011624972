import axios from "axios";
import authentication from "../authentication";
import { validateApiSearchResponse } from "@/utils/ApiResponseValidator";
import { ServiceException } from "@/utils/ErrorHandler";
import { Query } from "@/classes/Search";

const SEARCH_RESOURCE = "/search/";
const EXPORT_RESOURCE = "/search/export";
const PROPERTY_RESOURCE = "search/definitions/properties/codes/";
const ROLES_FROM_RESOURCE = "search/definitions/context/from/roles/";
const ROLES_TO_RESOURCE = "search/definitions/context/to/roles/";
const STATUSES_RESOURCE = "search/definitions/statuses";

export class SearchServices {
  constructor(organisation) {
    this.OMNIMAP = axios.create({
      baseURL: process.env.VUE_APP_OMNIMAP_API_ROOT,
      headers: {
        tenant_identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      },
    });

    if (organisation) {
      this.setOrganisation(organisation);
    }
  }

  setOrganisation(organisation) {
    this.OMNIMAP.defaults.headers.common["organisation_identification"] =
      organisation;
  }

  async getPropertyCodes(definitionIds, query) {
    if (!query) {
      query = "";
    }

    if (typeof definitionIds === "string") {
      definitionIds = [definitionIds];
    }

    const body = {
      definitions: definitionIds ? definitionIds : [],
    };

    const bearer = await authentication.acquireOmnimapToken();
    return this.OMNIMAP.post(PROPERTY_RESOURCE + "?q=" + query, body, {
      headers: {
        Authorization: "Bearer " + bearer,
      },
    })
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async getToInstancesByRole(definitionIds, roleName, searchQuery) {
    if (typeof definitionIds === "string") {
      definitionIds = [definitionIds];
    }
    const body = {
      definitions: definitionIds ? definitionIds : [],
    };

    const bearer = await authentication.acquireOmnimapToken();

    return this.OMNIMAP.post(
      ROLES_TO_RESOURCE + roleName + "/?q=" + searchQuery + "&size=50",
      body,
      {
        headers: {
          Authorization: "Bearer " + bearer,
        },
      }
    )
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async getFromInstancesByRole(definitionIds, roleName, searchQuery) {
    if (typeof definitionIds === "string") {
      definitionIds = [definitionIds];
    }

    const body = {
      definitions: definitionIds ? definitionIds : [],
    };

    const bearer = await authentication.acquireOmnimapToken();

    return this.OMNIMAP.post(
      ROLES_FROM_RESOURCE + roleName + "/?q=" + searchQuery + "&size=50",
      body,
      {
        headers: {
          Authorization: "Bearer " + bearer,
        },
      }
    )
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async getContentKeys(definitionIds) {
    const bearer = await authentication.acquireOmnimapToken();

    if (typeof definitionIds === "string") {
      definitionIds = [definitionIds];
    }

    const body = {
      definitions: definitionIds ? definitionIds : [],
    };

    return this.OMNIMAP.post(
      SEARCH_RESOURCE + "definitions/content/keys/",
      body,
      {
        headers: {
          Authorization: "Bearer " + bearer,
        },
      }
    )
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async getContentValues(definitionIds, content, query) {
    const bearer = await authentication.acquireOmnimapToken();

    if (!query) {
      query = "";
    }

    if (typeof definitionIds === "string") {
      definitionIds = [definitionIds];
    }

    const body = {
      definitions: definitionIds ? definitionIds : [],
    };

    return this.OMNIMAP.post(
      SEARCH_RESOURCE +
        "definitions/content/keys/" +
        content +
        "/values?q=" +
        query,
      body,
      {
        headers: {
          Authorization: "Bearer " + bearer,
        },
      }
    )
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async getAllStatuses(definitionIds) {
    const bearer = await authentication.acquireOmnimapToken();

    if (typeof definitionIds === "string") {
      definitionIds = [definitionIds];
    }

    const body = {
      definitions: definitionIds ? definitionIds : [],
    };

    return this.OMNIMAP.post(STATUSES_RESOURCE, body, {
      headers: {
        Authorization: "Bearer " + bearer,
      },
    })
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async getRelevantStatuses(definitionId, query) {
    if (typeof definitionId === "string") {
      definitionId = [definitionId];
    }

    const body = {
      definitions: definitionId ? definitionId : [],
      query,
    };

    return this.OMNIMAP.post(STATUSES_RESOURCE, body, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async postInstanceQuery(
    pageSize,
    pageNumber,
    query,
    fuzzySearch,
    searchFields,
    searchIncludes
  ) {
    if (!query) {
      throw new ServiceException("Geen query ontvangen");
    }
    if (!pageNumber) {
      throw new ServiceException("Geen pageNumber ontvangen");
    }
    if (!pageSize) {
      throw new ServiceException("Geen pageSize ontvangen");
    }

    const textSearch =
      query &&
      query["search_query"] &&
      typeof query.search_query === "string" &&
      query.search_query.trim().length > 1;

    if (textSearch && !searchFields) {
      searchFields = ["NAME", "CONTENT", "IDENTIFIER"];
    }

    if (textSearch && typeof fuzzySearch !== "boolean") {
      throw new ServiceException("Geen geldige text searchquery.");
    }

    let includes;
    if (searchIncludes) {
      includes = searchIncludes.join(",");
    } else {
      includes = "PROPERTIES,MONITORING,CONTENT,CONTEXT";
      if (query.add_location) {
        includes += ",LOCATIONS";
      }
    }

    const body = JSON.parse(JSON.stringify(query));

    delete body.add_location;
    delete body.search_query;

    if (textSearch) {
      body["text_search"] = [
        {
          text_query: query.search_query,
          search_fields: searchFields,
          fuzzy: fuzzySearch,
          and_or: "OR",
        },
      ];
    }

    let sorting;
    if (body && body.sorting && body.sorting.length) {
      sorting = [...body["sorting"]];
    } else {
      sorting = [{ sort_type: "text_score", direction: "DESCENDING" }];
    }
    body["sorting"] = sorting;

    const url =
      SEARCH_RESOURCE +
      "?page-size=" +
      pageSize +
      "&page-number=" +
      pageNumber +
      "&includes=" +
      includes;

    return this.OMNIMAP.post(url, body, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async getSearchResult(pageSize, pageNumber, query) {
    if (!query || !(query instanceof Query)) {
      throw new ServiceException("Geen query ontvangen");
    }
    if (!pageNumber) {
      throw new ServiceException("Geen pageNumber ontvangen");
    }
    if (!pageSize) {
      throw new ServiceException("Geen pageSize ontvangen");
    }

    const url =
      SEARCH_RESOURCE +
      "?page-size=" +
      pageSize +
      "&page-number=" +
      pageNumber +
      "&includes=" +
      "CONTENT,CONTEXT";

    return this.OMNIMAP.post(url, query, {
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => validateApiSearchResponse(response))
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async export(payload, pageSize) {
    return this.OMNIMAP.post(
      EXPORT_RESOURCE + "?includes=PROPERTIES,CONTENT&page-size=" + pageSize,
      payload,
      {
        responseType: "blob",
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "omnimap_instances_export.xls");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  async exportWithSettings(payload, pageSize, settings) {
    let query = `?includes=PROPERTIES,CONTENT&page-size=${pageSize}`;

    if (settings.date_format) {
      query += `&date-time-format=${settings.date_format}`;
    }

    return this.OMNIMAP.post(EXPORT_RESOURCE + query, payload, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + (await authentication.acquireOmnimapToken()),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "omnimap_instances_export.xls");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }

  /**
   * Function to export an Excel file including results acquired with multiple queries
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  async exportMultiple(payload) {
    return this.OMNIMAP.post(
      EXPORT_RESOURCE + "-multiple?includes=CONTENT",
      payload,
      {
        responseType: "blob",
        headers: {
          Authorization:
            "Bearer " + (await authentication.acquireOmnimapToken()),
        },
      }
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "omnimap_instances_export.xls");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        throw new ServiceException(error.message);
      });
  }
}
