import Vue from "vue";
import { Breadcrumbs } from "@/utils/GeneralConst/Breadcrumbs";

const state = {
  instance: { id: undefined, name: undefined },
  candidate: { id: undefined, name: undefined },
  profile: { id: undefined, name: undefined },
  action: { id: undefined, name: undefined },
};

const getters = {
  instance: (state) => state.instance,
  candidate: (state) => state.candidate,
  profile: (state) => state.profile,
  action: (state) => state.action,
};

const actions = {
  setBreadcrumb: ({ commit }, { type, id, name }) => {
    switch (type) {
      case Breadcrumbs.INSTANCE:
        commit("SET_INSTANCE", { id: id, name: name });
        break;
      case Breadcrumbs.CANDIDATE:
        commit("SET_CANDIDATE", { id: id, name: name });
        break;
      case Breadcrumbs.PROFILE:
        commit("SET_PROFILE", { id: id, name: name });
        break;
      case Breadcrumbs.ACTION:
        commit("SET_ACTION", { id: id, name: name });
        break;
      default:
        // Do nothing
        break;
    }
  },
};

const mutations = {
  SET_INSTANCE: (state, payload) => Vue.set(state, "instance", payload),
  SET_CANDIDATE: (state, payload) => Vue.set(state, "candidate", payload),
  SET_PROFILE: (state, payload) => Vue.set(state, "profile", payload),
  SET_ACTION: (state, payload) => Vue.set(state, "action", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
