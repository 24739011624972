import Vue from "vue";
import { OmniselectServices } from "./Services";
import { StoreException } from "@/utils/ErrorHandler";
import { SearchServices } from "@/services/SearchServices";
import IdUtils from "@/utils/IdUtils";

const state = {
  omniselectServices: null,
  searchServices: null,
};

const getters = {
  omniselectServices: (state) => state.omniselectServices,
  searchServices: (state) => state.searchServices,
};

const actions = {
  /**
   * Internal action for initializing and returning the OmniselectServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.omniselectServices|(function(*): (null|getters.omniselectServices|(function(*))))|null>}
   */
  getOmniselectServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.omniselectServices) {
        commit(
          "SET_OMNISELECT_SERVICES",
          new OmniselectServices(rootGetters.organisationId)
        );
      }

      return getters.omniselectServices;
    } catch (error) {
      throw new StoreException(error);
    }
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise the organisation id will be set on creation.
   *
   * @param dispatch
   * @param rootGetters
   * @return {Promise<void>}
   */
  updateOmniselectServices: ({ getters, rootGetters }) => {
    const omniselectServices = getters.omniselectServices;
    if (omniselectServices) {
      omniselectServices.setOrganisation(rootGetters.organisationId);
    }
  },

  /**
   * Internal action for initializing and returning the SearchServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.searchServices|(function(*): (null|getters.searchServices|(function(*))))|null>}
   */
  getSearchServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.searchServices) {
        commit(
          "SET_SEARCH_SERVICES",
          new SearchServices(rootGetters.organisationId)
        );
      }

      return getters.searchServices;
    } catch (error) {
      throw new StoreException(error);
    }
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise the organisation id will be set on creation.
   *
   * @param dispatch
   * @param rootGetters
   * @return {Promise<void>}
   */
  updateSearchServices: ({ getters, rootGetters }) => {
    const searchServices = getters.searchServices;
    if (searchServices) {
      searchServices.setOrganisation(rootGetters.organisationId);
    }
  },

  /**
   * Performs a searchquery on the Omnimap Elasticsearch API.
   *
   * @param getters
   * @param dispatch
   * @param pageSize
   * @param pageNumber
   * @param query
   * @param searchExact
   * @param searchFields
   * @return {Promise<{instances: [], meta: *}|{instances: *, meta: *}>}
   */
  omniselectSearch: async (
    { dispatch },
    {
      pageSize = process.env.VUE_APP_GLOBAL_PAGE_SIZE,
      pageNumber = process.env.VUE_APP_GLOBAL_PAGE_NUMBER,
      query,
      searchExact = false,
      searchFields,
      searchIncludes,
    }
  ) => {
    // Input validation
    if (!pageSize || !pageNumber || !query || !searchFields) {
      throw new StoreException("Missing value");
    }
    const searchServices = await dispatch("getSearchServices");
    try {
      const response = await searchServices.postInstanceQuery(
        pageSize,
        pageNumber,
        query,
        searchExact,
        searchFields,
        searchIncludes
      );

      if (response && "meta" in response && response.meta) {
        return { meta: response.meta, instances: response.data };
      } else {
        return { meta: response.meta, instances: [] };
      }
    } catch (error) {
      throw new StoreException("Omniselect Search error: " + error);
    }
  },

  /**
   * Performs a get GET request to an omnimap endpoint.
   *
   * @param getters
   * @param dispatch
   * @param url
   * @return {Promise<any>}
   */
  omniselectGet: async ({ dispatch }, url) => {
    // Input validation
    if (!url) {
      throw new StoreException("Missing value");
    }

    const omniselectServices = await dispatch("getOmniselectServices");
    try {
      return await omniselectServices.getFromUrl(url);
    } catch (error) {
      throw new StoreException("Omniselect Get error: " + error);
    }
  },

  getInstance: async ({ commit, getters, dispatch }, instanceId) => {
    if (!IdUtils.isUUID(instanceId)) {
      console.error(
        "No valid instance identification found. Unable to retrieve instance."
      );
      return;
    }

    // Setup for retrieving instance from server
    const omniselectServices = await dispatch("getOmniselectServices");
    const properties = ["content"];
    let instance = null;

    try {
      // Get instance
      instance = await omniselectServices.getInstance({
        instanceId,
        properties,
      });
      return instance;
    } catch (error) {
      throw new StoreException(
        "Something went wrong while loading instance: \n" + error.message
      );
    }
  },
};

const mutations = {
  SET_OMNISELECT_SERVICES: (state, payload) =>
    Vue.set(state, "omniselectServices", payload),
  SET_SEARCH_SERVICES: (state, payload) =>
    Vue.set(state, "searchServices", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
