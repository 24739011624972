import Vue from "vue";
import { NotificationServices } from "@/services/NotificationServices";
import { StoreException } from "@/utils/ErrorHandler";
import IdUtils from "@/utils/IdUtils";
import { SearchServices } from "@/services/SearchServices";

const state = {
  notificationServices: null,
  searchServices: null,
  userNotifications: null,
  includeReadUserNotifications: false,
  userNotificationsMeta: { total_found: 0 },
  currentUserNotificationsPage: 1,
  currentUserNotificationsPageSize: 100,
};

const getters = {
  notificationServices: (state) => state.notificationServices,
  searchServices: (state) => state.searchServices,
  userNotifications: (state) =>
    state.userNotifications ? state.userNotifications : [],
  numberOfUserNotifications: (state) =>
    state.userNotificationsMeta ? state.userNotificationsMeta.total_found : 0,
  includeReadUserNotifications: (state) => state.includeReadUserNotifications,
  userNotificationsMeta: (state) => state.userNotificationsMeta,
  currentUserNotificationsPage: (state) => state.currentUserNotificationsPage,
  currentUserNotificationsPageSize: (state) =>
    state.currentUserNotificationsPageSize,
};

const actions = {
  /**
   * Internal action for initializing and returning the NotificationServices API object.
   *
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.notificationServices|(function(*): (null|getters.notificationServices|(function(*))))|null>}
   */
  getNotificationServices: async ({ getters, rootGetters, commit }) => {
    if (rootGetters.organisationId) {
      try {
        if (null === getters.notificationServices) {
          commit(
            "SET_NOTIFICATION_SERVICES",
            new NotificationServices(rootGetters.organisationId)
          );
        }

        return getters.notificationServices;
      } catch (error) {
        throw new StoreException("getNotificationServices: " + error.message);
      }
    }
  },

  /**
   * Internal action for initializing and returning the SearchServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.searchServices|(function(*): (null|getters.searchServices|(function(*))))|null>}
   */
  getSearchServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.searchServices) {
        commit(
          "SET_SEARCH_SERVICES",
          new SearchServices(rootGetters.organisationId)
        );
      }

      return getters.searchServices;
    } catch (error) {
      throw new StoreException("getSearchServices: " + error.message);
    }
  },

  /**
   * Toggles if the user notifications should include the read notification, and retrieves the user notifications again.
   *
   * @param commit
   * @param getters
   * @param dispatch
   * @return {Promise<*>}
   */
  toggleIncludeReadUserNotifications: async ({ commit, getters, dispatch }) => {
    commit(
      "SET_INCLUDE_READ_USER_NOTIFICATIONS",
      !getters.includeReadUserNotifications
    );
    return await dispatch("retrieveUserNotifications", {
      pageNumber: getters.currentUserNotificationsPage,
      pageSize: getters.currentUserNotificationsPageSize,
    });
  },

  /**
   * Retrieves a page of notifications for the user.
   *
   * @param dispatch
   * @param getters
   * @param commit
   * @param pageNumber
   * @param pageSize
   * @return {any}
   */
  retrieveUserNotifications: async (
    { dispatch, getters, commit },
    { pageNumber, pageSize }
  ) => {
    // Handle passed page params
    if (!pageNumber) {
      pageNumber = getters.currentUserNotificationsPage;
    } else {
      commit("SET_CURRENT_USER_NOTIFICATIONS_PAGE", pageNumber);
    }
    if (!pageSize) {
      pageSize = getters.currentUserNotificationsPageSize;
    } else {
      commit("SET_CURRENT_USER_NOTIFICATIONS_PAGE_SIZE", pageSize);
    }

    try {
      const notificationServices = await dispatch("getNotificationServices");
      const notifications =
        await notificationServices.retrieveUserNotifications(
          getters.includeReadUserNotifications,
          pageNumber,
          pageSize
        );

      if (
        notifications &&
        notifications.hasOwnProperty("data") &&
        Array.isArray(notifications.data)
      ) {
        commit("SET_USER_NOTIFICATIONS", notifications.data);
        commit("SET_USER_NOTIFICATIONS_META", notifications.meta);
        await dispatch("addWervingsTekstToNotifications");
      } else {
        commit("SET_USER_NOTIFICATIONS", []);
        commit("SET_USER_NOTIFICATIONS_META", { total_found: 0 });
      }

      return getters.userNotifications;
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * For the currently stored user notifications, add the indexed instance content and update the state.
   *
   * @param dispatch
   * @param getters
   * @param commit
   * @return {Promise<void>}
   */
  addWervingsTekstToNotifications: async ({ dispatch, getters, commit }) => {
    if (Array.isArray(getters.userNotifications)) {
      // Extract the set of instance ids for which the notifications are present
      const notificationInstanceIds = new Set(
        getters.userNotifications
          .filter((notification) =>
            IdUtils.isUUID(notification.instance?.identification)
          )
          .map((notification) => notification.instance.identification)
      );

      try {
        // Query the instances of the notifications
        const searchServices = await dispatch("getSearchServices");
        const searchQuery = {
          instance_identifications: notificationInstanceIds,
        };

        const searchResults = await searchServices.postInstanceQuery(
          notificationInstanceIds.size,
          1,
          searchQuery,
          false,
          null,
          ["CONTENT"]
        );

        // Add the instance data to the already known notifications, and update the state
        if (searchResults && searchResults.data) {
          const updatedNotifications = getters.userNotifications.map(
            (notification) => {
              const instanceData = searchResults.data.find(
                (dataItem) =>
                  dataItem.identification ===
                  notification?.instance?.identification
              );

              if (instanceData && Object.keys(instanceData).length) {
                notification.instance = instanceData;
              }
              return notification;
            }
          );
          commit("SET_USER_NOTIFICATIONS", updatedNotifications);
        }
      } catch (error) {
        console.error(error);
      }
    }
  },

  /**
   * Function which marks the passed list of notification ids as read, and refreshes the current list.
   *
   * @param dispatch
   * @param notificationIds
   * @return {Promise<*>}
   */
  markNotificationsAsRead: async ({ dispatch }, notificationIds) => {
    // Validate notificationIds as an array of ids
    if (
      !notificationIds ||
      !Array.isArray(notificationIds) ||
      notificationIds.some((id) => !IdUtils.isUUID(id))
    ) {
      throw new StoreException(
        "markNotificationsAsRead requires a list of identifications"
      );
    }

    try {
      // Put the notifications as read, and refresh the current list
      const notificationServices = await dispatch("getNotificationServices");
      await notificationServices.putUserNotifications(notificationIds);
      await dispatch("retrieveUserNotifications", {});
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  SET_NOTIFICATION_SERVICES: (state, payload) =>
    Vue.set(state, "notificationServices", payload),
  SET_SEARCH_SERVICES: (state, payload) =>
    Vue.set(state, "searchServices", payload),
  SET_USER_NOTIFICATIONS: (state, payload) =>
    Vue.set(state, "userNotifications", payload),
  SET_INCLUDE_READ_USER_NOTIFICATIONS: (state, payload) =>
    Vue.set(state, "includeReadUserNotifications", payload),
  SET_USER_NOTIFICATIONS_META: (state, payload) =>
    Vue.set(state, "userNotificationsMeta", payload),
  SET_CURRENT_USER_NOTIFICATIONS_PAGE: (state, payload) =>
    Vue.set(state, "currentUserNotificationsPage", payload),
  SET_CURRENT_USER_NOTIFICATIONS_PAGE_SIZE: (state, payload) =>
    Vue.set(state, "currentUserNotificationsPageSize", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
