import store from "../store";

/**
 * Checks if browser is a Internet Explorer, if it is redirect to error message
 * @returns {boolean}
 */
function isBrowserIE(next) {
  if (
    navigator &&
    navigator.userAgent &&
    typeof navigator.userAgent === "string"
  ) {
    const userAgentString = navigator.userAgent;

    const IExplorer =
      userAgentString.indexOf("MSIE") > -1 ||
      !(
        userAgentString.indexOf("Safari") > -1 ||
        userAgentString.indexOf("Chrome") > -1 ||
        userAgentString.indexOf("Firefox") > -1
      );

    if (IExplorer) {
      next({ name: "Error", params: { code: 404, internetExplorer: true } });
    }
    return IExplorer;
  }
}

/**
 * Validates if the tenant id parameter in the to contains a valid tenant id.
 *
 * @param to
 * @param from
 * @param next
 * @return {Promise<void>}
 */
export async function validTenantGuard(to, _from, next) {
  if (!isBrowserIE(next)) {
    try {
      if (to.params.invitationKey) {
        sessionStorage.setItem("invitationKey", to.params.invitationKey);
      }
      if (to.query) {
        for (const [key, value] of Object.entries(to.query)) {
          sessionStorage.setItem(key, value);
        }
      }

      await fetchDomain();
      next();
    } catch (error) {
      // On error of tenant validation, navigate to error page
      console.error(error);
      next("/");
    }
  }
}

/**
 *
 * @param to
 * @param from
 * @param next
 * @return {Promise<void>}
 */
export async function landingPageGuard(to, _from, next) {
  if (!isBrowserIE(next)) {
    // Check if a tenant id is known in localstorage, else show error page
    const tenantId = process.env.VUE_APP_TENANT_IDENTIFICATION;

    if (tenantId === "<TENANT_ID>") {
      await this.$router.push({
        name: "Error",
        params: {
          code: 400,
          errorMessage:
            "The <TENANT_ID> needs to be replaced \n by the tenant-id known to Omnimap.",
        },
      });
    } else if (tenantId) {
      if (to.query) {
        for (const [key, value] of Object.entries(to.query)) {
          sessionStorage.setItem(key, value);
        }
      }
      next();
    } else {
      await fetchDomain();
      next("/403");
    }
  }
}

export async function organisationGuard(_to, from, next) {
  if (!isBrowserIE(next)) {
    if (from.name === null) {
      next({ path: "/landingPage" });
    }

    // Check if a tenant id is known in localstorage, else show error page
    const tenantId = process.env.VUE_APP_TENANT_IDENTIFICATION;

    if (tenantId) {
      if (
        store &&
        Object.keys(store.getters["userOrganisations"]).length === 0
      ) {
        next({
          name: "GoTo",
        });
      } else {
        next();
      }
    } else {
      await fetchDomain();
      next("/");
    }
  }
}

export async function userGuard(to, from, next) {
  if (!isBrowserIE(next)) {
    if (from.name === null) {
      // Check for existing tenant id
      await fetchDomain();
      if (process.env.VUE_APP_TENANT_IDENTIFICATION) {
        if (to.query) {
          for (const [key, value] of Object.entries(to.query)) {
            sessionStorage.setItem(key, value);
          }
        }
        next({
          name: "GoTo",
        });
      } else {
        // If no tenant found, go to error
        await fetchDomain();
        next("/");
      }
    } else {
      next();
    }
  }
}

async function fetchDomain() {
  let currentOrigin = window.location.origin;
  if (currentOrigin.lastIndexOf("/") !== currentOrigin.length - 1) {
    currentOrigin += "/";
  }
  const storedDomain = sessionStorage.getItem("vanityDomain");

  // Only make call to fetch domains if we know it exists or origin isnt the same as host
  try {
    if (storedDomain) {
      await store._actions["tenantStore/fetchTenantDataByDomain"][0](
        storedDomain
      );
    } else if (currentOrigin !== process.env.VUE_APP_HOST) {
      await store._actions["tenantStore/fetchTenantDataByDomain"][0](
        currentOrigin
      );
    }
  } catch (e) {
    console.error("ERROR", e);
  }
}

export async function domainGuard(to, _from, next) {
  if (to.query) {
    for (const [key, value] of Object.entries(to.query)) {
      sessionStorage.setItem(key, value);
    }
  }
  next({ name: "GoTo" });
}
